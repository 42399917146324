import React from 'react'
import MUIDataTable from 'mui-datatables'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

const getMuiTheme = () =>
  createMuiTheme({
    typography: {
      useNextVariants: true
    },
    overrides: {
      MuiTableBody: {
        root: {
          backgroundColor: 'transparent'
        }
      },
      MUIDataTable: {
        root: {
          backgroundColor: 'transparent',
          maxHeight: '100%',
          height: '80vh'
        },
        headerCell: {
          backgroundColor: 'transparent'
        },
        paper: {
          boxShadow: 'none',
          background: 'transparent'
        },
        responsiveScroll: {
          maxHeight: 'none',
          background: 'transparent',
          borderRadius: '4px',
          boxShadow: 'none'
        }
      },
      MUIDataTableBodyRow: {
        responsiveStacked: {
          margin: '1em 0'
        }
      },
      MUIDataTableBodyCell: {
        stackedCommon: {
          whiteSpace: 'normal!important',
          height: 'auto!important'
        }
      },
      MUIDataTableSelectCell: {
        headerCell: {
          backgroundColor: 'transparent'
        },
        fixedHeaderCommon: {
          backgroundColor: 'transparent'
        }
      },
      MuiIconButton: {
        root: {
          color: 'white'
        }
      },
      MUIDataTableHeadCell: {
        backgroundColor: 'transparent',
        fixedHeader: {
          backgroundColor: 'transparent',
          zIndex: 9
        },
        fixedHeaderCommon: {
          backgroundColor: 'transparent'
        }
      }
    }
  })

function TableProvider(props) {
  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable filterType={'dropdown'} {...props} />
    </MuiThemeProvider>
  )
}

export default TableProvider
