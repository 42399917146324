import { createGlobalState } from 'react-hooks-global-state'
import { presistData } from './util'

let thisState
//lets load user data for reload
if (typeof localStorage !== 'undefined' && localStorage.cmoreApp) {
  thisState = JSON.parse(localStorage.cmoreApp)
}

const initialState = {
  color: {
    primary: '#e8621f',
    secondary: '#234A6D'
  },
  pageClass: 'login',
  clients: [],
  sectors: [],
  categories: [],
  questions: [],
  initiatives: [],
  targets: [],
  assessment: [],
  users: [],
  userHistory: [],
  paymentToken: false,
  userTypes: [],
  socket: null,
  remoteStated: false,
  editProfile: false,
  loggedIn: false,
  relations: [],
  purchases: [],
  setLoggedIn: null,
  viewOptions: {
    date: new Date(),
    displayType: 'week', // week||day
    viewType: 'global',
    showApproval: false,
    type: false,
    search: false
  },
  ...thisState
}

const { useGlobalState, setGlobalState, getGlobalState } = createGlobalState(
  initialState
)

const setStorageState = (type, data) => {
  presistData('cmoreApp', { ...thisState, [type]: data })
  setGlobalState(type, data)
}

export { useGlobalState, setGlobalState, getGlobalState, setStorageState }
