import styled, { css } from 'styled-components'
import { colors } from '../theme'
import tinycolor from 'tinycolor2'

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676'
  }
  if (props.isDragReject) {
    return '#ff1744'
  }
  if (props.isDragActive) {
    return '#2196f3'
  }
  return '#eeeeee'
}
export const QuestionTitle = styled.div`
  && {
    h1 {
      font-size: 1.5em;
      color: #fff;
      line-height: 1;
    }
    span {
      color: #fff;
      max-width: 50%;
      display: inline-block;
      margin-bottom: 1em;
    }
  }
`
export const QuestionWrapper = styled.div`
  && {
    position: absolute;
    top: 0;
    width: calc(100% - 80px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    .questionWrap {
      padding-top: 10vh;
      max-height: 100vh;
      width: 70%;
      @media screen and (max-width: 768px) {
        margin-top: 10vh;
        padding-top: 0;
        max-height: 85vh;
        width: 90%;
      }
    }
  }
`

export const CategoryLogo = styled.div`
  && {
    border-radius: 10px;
    &:after {
      content: ' ';
      width: 80px;
      height: 60px;
      display: block;
      ${(props) =>
        props.logo
          ? css`
              background-image: url(${props.logo});
              background-repeat: no-repeat;
              background-position: center, center;
              background-size: contain, cover;
            `
          : css``}
      @media screen and (max-width: 768px) {
        width: 60px;
        height: 30px;
      }
    }

    @media screen and (max-width: 768px) {
      left: 40px;
      top: 80px;
    }
  }
`

export const CategoryCard = styled.div`
  && {
    cursor: pointer;
    width: 100%;
    border: none;
    text-align: left;
    background: #fff;
    padding: 1em 0.5em;
    margin: 0 2% 2% 0;
    font-size: 1.2em;
    font-weight: bold;
    border-radius: 2em;
    /* background: ${colors.secondary[500]};
    color: ${colors.secondary[100]}; */
    /* min-height: 8em; */
    /* border: 1px solid ${colors.primary[200]}; */
    box-shadow: 0 2px 9px 0 ${colors.primary.transparent[20]};

    ${(props) =>
      props.color && props.percentage
        ? css`
            color: ${props.color};
            background-image: linear-gradient(
              120deg,
              ${tinycolor(props.color).lighten(40).toRgbString()} 0%,
              ${tinycolor(props.color).lighten(40).toRgbString()}
                ${props.percentage}%,
              rgba(0, 21, 25, 0) ${props.percentage}%
            );
          `
        : css``};
  }
`
export const SubCategoryCard = styled.div`
  && {
    display: none;
    cursor: pointer;
    width: 100%;
    border: none;
    text-align: left;
    padding: 1em;
    margin: 0 1% 1% 0;
    border-radius: 2em;
    /* min-height: 8em; */
    /* border: 1px solid ${colors.primary[200]}; */
    box-shadow: 0 2px 9px 0 ${colors.primary.transparent[20]};
    ${(props) =>
      props.color && props.percentage
        ? css`
            color: ${tinycolor(props.color).lighten(40).toRgbString()}};
            background: ${props.color};
          `
        : css`
            background: ${colors.primary[200]};
            color: {
              ${colors.primary[200]}
            }
          `};
  }
`
export const QuestionsCard = styled.div`
  && {
    cursor: pointer;
    width: 96%;
    border: none;
    text-align: left;
    padding: 1em 2em;
    margin: 2%;
    font-size: 1em;
    border-radius: 2em;
    /* min-height: 8em; */
    /* border: 1px solid ${colors.primary[200]}; */
    box-shadow: 0 2px 9px 0 ${colors.primary.transparent[20]};
    ${(props) => (props.somSelected ? `opacity: 0.5;` : '')};
    ${(props) => (props.isSelected ? `opacity: 1;` : '')};
    ${(props) =>
      props.color && props.completed
        ? css`
            color: ${tinycolor(props.color).lighten(20).toRgbString()}};
            background: ${tinycolor(props.color).lighten(40).toRgbString()};
          `
        : css`
            background: #fff;
          `};
  }
`

export const IndexedStep = styled.div`
  && {
    color: white;
    font-size: 0.8em;
    background-color: rgba(211, 211, 211, 0.8);
    border-radius: 2em;
    padding: 0.3em 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    ${(props) =>
      props.accomplished
        ? `background-color: white; color:${colors.primary[500]}`
        : ``}
  }
`
export const CompleteAssessment = styled.div`
  && {
    color: ${colors.primary[500]};
    font-size: 1.1em;
    background-color: white;
    border-radius: 2em;
    padding: 0.8em 1.4em;
    margin: 1em 0.5em 3em 0;
    display: inline-block;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: ${colors.secondary[500]};
      color: white;
    }
  }
`
export const CompleteAssessmentOutline = styled.div`
  && {
    color: white;
    font-size: 1.1em;
    border: 1px solid white;
    border-radius: 2em;
    padding: 0.8em 1.4em;
    margin: 1em 0.5em 3em 0;
    display: block;
    justify-content: center;
    text-align: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: ${colors.secondary[500]};
      color: white;
    }
  }
`
export const AssessmentNavigation = styled.div`
  && {
    color: white;
    font-size: 0.8em;
    background-color: white;
    cursor: pointer;
    border-radius: 2em;
    margin: 0 0.3em;
    padding: 0.3em 0.8em;
    display: flex;
    justify-content: center;
    color: ${colors.primary[500]};
    align-items: center;
    ${(props) =>
      props.active
        ? `background-color: ${colors.primary[500]}; color:white`
        : ``}
  }
`

export const DropContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`

export const Source = styled.div`
  && {
    font-weight: bold;
    color: rgba(255, 255, 255, 0.5);
  }
`
export const BoardSlider = styled.div`
  && {
    border: none;
    text-align: center;
    display: inline-block;
    background: #fff;
    padding: 0.8em 2.3em;
    font-size: 1em;
    margin: 0 1em 1em 0;
    border-radius: 2em;
    width: ${(props) => (props.full ? '100%' : '60%')};
    /* border: 1px solid ${colors.primary[200]}; */
    box-shadow: 0 2px 9px 0 ${colors.primary.transparent[30]};
    cursor: pointer;
  }
`
export const BoardOptions = styled.div`
  && {
    border: none;
    text-align: center;
    display: inline-block;
    background: #fff;
    padding: 0.8em 2.3em;
    font-size: 1em;
    margin: 0 1em 1em 0;
    border-radius: 2em;
    /* border: 1px solid ${colors.primary[200]}; */
    box-shadow: 0 2px 9px 0 ${colors.primary.transparent[30]};
    cursor: pointer;
    &:hover {
      background: ${colors.primary[500]};
      border: none;
      color: ${colors.primary[200]};
    }
    ${(props) =>
      props.active
        ? `
            background: ${colors.primary[500]};
            border: none;
            color: ${colors.primary[100]};
          `
        : ``}
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 0.5em 0;
    }
  }
`
export const BoardOptionsOverview = styled.div`
  && {
    border: none;
    text-align: center;
    display: inline-block;
    background: #fff;
    padding: 0.5em 1.3em;
    font-size: 0.8em;
    margin: 0 0.5em 0.5em 0;
    border-radius: 2em;
    /* border: 1px solid ${colors.primary[200]}; */
    box-shadow: 0 2px 9px 0 ${colors.primary.transparent[30]};
    cursor: pointer;
    &:hover {
      background: ${colors.primary[500]};
      border: none;
      color: ${colors.primary[200]};
    }
    ${(props) =>
      props.active
        ? `
            background: ${colors.primary[500]};
            border: none;
            color: ${colors.primary[100]};
          `
        : ``}
    @media screen and (max-width: 768px) {
      padding: 1.2em;
    }
  }
`
export const BoardNavigation = styled.div`
  && {
    border: none;
    text-align: center;
    display: inline-block;
    background: #fff;
    padding: 0.8em 2.3em;
    font-size: 0.8em;
    margin: 0 1em 1em 0;
    border-radius: 2em;
    color: ${colors.primary[500]};
    /* background: ${colors.primary[500]}; */
    border: 1px solid ${colors.primary[500]};
    /* box-shadow: 0 2px 9px 0 ${colors.primary.transparent[30]}; */
    cursor: pointer;
    &:hover {
      background: ${colors.primary[500]};
      border: none;
      color: ${colors.primary[200]};
    }
    @media screen and (max-width: 768px) {
      /* width: 47%; */
    }
  }
`
export const BoardIgnore = styled.div`
  && {
    border: none;
    text-align: center;
    display: inline-block;
    background: #fff;
    padding: 0.8em 2.3em;
    font-size: 0.8em;
    margin: 0 1em 1em 0;
    border-radius: 2em;
    color: ${colors.secondary[500]};
    background: white;
    /* background: ${colors.secondary[500]}; */
    border: 1px solid ${colors.secondary[500]};
    /* box-shadow: 0 2px 9px 0 ${colors.secondary.transparent[30]}; */
    cursor: pointer;
    &:hover {
      background: ${colors.secondary[500]};
      border: none;
      color: ${colors.secondary[200]};
    }
    @media screen and (max-width: 768px) {
      width: 47%;
    }
    ${(props) =>
      !props.active
        ? `
            background: ${colors.secondary[500]};
            border: none;
            color: ${colors.secondary[100]};
          `
        : ``}
  }
`
export const BoardNavigationNext = styled.div`
  && {
    border: none;
    text-align: center;
    display: inline-block;
    background: ${colors.primary[500]};
    padding: 0.8em 2.3em;
    font-size: 0.8em;
    margin: 0 1em 1em 0;
    border-radius: 2em;
    border: none;
    color: white;
    /* background: ${colors.primary[500]}; */
    border: 1px solid ${colors.primary[500]};
    /* box-shadow: 0 2px 9px 0 ${colors.primary.transparent[30]}; */
    cursor: pointer;
    &:hover {
      background: ${colors.secondary[500]};
      border: none;
      color: ${colors.primary[200]};
    }
    @media screen and (max-width: 768px) {
      /* width: 47%; */
      margin-right: 0;
    }
  }
`
export const Row = styled.div`
  && {
    display: flex;
    margin: 2em 0;
  }
`
export const RowDashPage = styled.div`
  && {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: -2em 0 0 0;
  }
`
