// import { toast } from 'react-toastify'
import { setGlobalState, setStorageState, getGlobalState } from './store'
import { getPresistData } from './util'
import { io } from 'socket.io-client'
import { createStandaloneToast } from '@chakra-ui/react'

export const setRemoteCall = (callType, data, message, cb) => {
  const toast = createStandaloneToast()
  const socket = getGlobalState('socket')
  if (!socket) {
    setTimeout(() => {
      setRemoteCall(callType, data, message, cb)
    }, 1000)
  } else {
    socket.emit(callType, data, (d) => cb && cb(d))
    if (message)
      toast({
        title: `${message}`,
        status: 'success',
        position: 'bottom',
      })
  }
}

export const socketListeners = (selfSocket) => {
  const socket = getGlobalState('socket')
  if (!socket) return null

  ///get initial data
  socket.emit('getInitData')

  socket.on('exprired', (received) => {
    if (received) {
      console.log('User token has expired')
      setGlobalState('loggedIn', null)
      setStorageState('loggedIn', null)
      socket.close()
      return (window.location = '/')
    }
  })
  socket.on('users', (received) => {
    setGlobalState('users', received || [])
    //let filter out available resources
    // if (received) {
    //   const availableUsers = received.filter(user => user.status === 1) || []
    //   setGlobalState('availableUsers', availableUsers)
    // }
    // toast(`Got ${received.length} users`)
  })
  socket.on('clients', (received) => {
    setGlobalState('clients', received || [])
    // toast(`Got ${received.length} users`)
  })
  socket.on('paymentToken', (received) => {
    setGlobalState('paymentToken', received || false)
    // toast(`Got ${received.length} users`)
  })
  socket.on('initiatives', (received) => {
    setGlobalState('initiatives', received || [])
    // toast(`Got ${received.length} users`)
  })
  socket.on('targets', (received) => {
    setGlobalState('targets', received || [])
    // toast(`Got ${received.length} users`)
  })
  socket.on('categories', (received) => {
    setGlobalState('categories', received || [])
    // toast(`Got ${received.length} users`)
  })
  socket.on('sectors', (received) => {
    setGlobalState('sectors', received || [])
    // toast(`Got ${received.length} users`)
  })
  socket.on('questions', (received) => {
    setGlobalState('questions', received || [])
    // toast(`Got ${received.length} users`)
  })
  socket.on('assessment', (received) => {
    setGlobalState('assessment', received || [])
    // toast(`Got ${received.length} users`)
  })

  socket.on('usersAvailable', (received) => {
    setGlobalState('availableUsers', received || [])
    // toast(`Got ${received.length} users`)
  })
  socket.on('userTypes', (received) => {
    setGlobalState('userTypes', received || [])
  })
  socket.on('userHistory', (received) => {
    setGlobalState('userHistory', received || [])
  })
  socket.on('projects', (received) => {
    setGlobalState('projects', received || [])
    // toast(`Got ${received.length} projects`)
  })
  socket.on('projectHistory', (received) => {
    setGlobalState('projectHistory', received || [])
  })
  socket.on('purchases', (received) => {
    setGlobalState('purchases', received || [])
  })
  socket.on('projectTypes', (received) => {
    setGlobalState('projectTypes', received || [])
  })
}
export const checkAuthRoute = (redirectIfLoggedin) => {
  const loggedIn = getGlobalState('loggedIn')
  if (
    !loggedIn &&
    !getPresistData('cmoreApp', 'loggedIn') &&
    window.location.pathname !== '/'
  ) {
    return (window.location = '/')
  } else if (
    redirectIfLoggedin &&
    loggedIn &&
    getPresistData('cmoreApp', 'loggedIn')
  ) {
    return (window.location = '/dashboard')
  }
  return true
}
export const checkAdminRoute = () => {
  const loggedIn = getGlobalState('loggedIn')
  if (
    (!loggedIn || !loggedIn.admin) &&
    !getPresistData('cmoreApp', 'loggedIn') &&
    window.location.pathname !== '/'
  ) {
    return (window.location = '/dashboard')
  } else if (!loggedIn || !loggedIn.admin) {
    return (window.location = '/dashboard')
  }
  return true
}

export const userLoggedIn = async () => {
  const loggedIn = getGlobalState('loggedIn')
  if (!loggedIn) return null

  try {
    const selfSocket = await io.connect(
      'https://balanceassessmentapi.cors.digital',
      {
        upgrade: true,
        query: `token=${loggedIn.token}`,
        extraHeaders: { Authorization: `Bearer ${loggedIn.token}` },
        // transports: ['websocket']
        // withCredentials: true,
        // upgrade: false,
        // query: `token=${loggedIn.token}`,
        // extraHeaders: { Authorization: `Bearer ${loggedIn.token}` },
        // transportOptions: {
        //   polling: {
        //     extraHeaders: {
        //       Authorization: `Bearer ${loggedIn.token}`
        //     }
        //   }
        // }
      }
    )

    selfSocket.on('unauthorized', async (error, callback) => {
      if (
        error.data.type === 'UnauthorizedError' ||
        error.data.code === 'invalid_token'
      ) {
        // redirect user to login page perhaps or execute callback:
        console.log('User token has expired')
        setGlobalState('loggedIn', null)
        setStorageState('loggedIn', null)
        await selfSocket.close()
        return (window.location = '/')
      }
    })

    // transports: ['websocket']

    //double cath when not working
    selfSocket.on('error', async (err) => {
      if (
        err.code === 'invalid_token' &&
        err.type === 'UnauthorizedError' &&
        err.message === 'jwt expired'
      ) {
        // Handle token expiration
        console.log('User token has expired')
        setGlobalState('loggedIn', null)
        setStorageState('loggedIn', null)
        await selfSocket.close()
        return (window.location = '/')
      }
    })

    setGlobalState('socket', selfSocket)
    socketListeners()
  } catch (error) {
    console.log(error)
  }
}
