import React from 'react'
import { motion } from 'framer-motion'
import { HeaderContainer, HeaderContainerWrap } from './styled'

const head = {
  visible1: { opacity: 1, y: 0 },
  hidden1: { opacity: 0, y: -100 }
}

const title = {
  visibleT: { opacity: 1, x: 0 },
  hiddenT: { opacity: 0, x: -100 }
}
const Header = props => {
  return (
    <HeaderContainer
      as={motion.div}
      initial="hidden1"
      animate="visible1"
      variants={head}
      transition={{ ease: 'easeOut', duration: 0.5 }}
      image={props.image}
      color={props.color}
      height={props.height}
      minHeight={props.height}
    >
      <HeaderContainerWrap
        as={motion.div}
        initial="hiddenT"
        animate="visibleT"
        transition={{ ease: 'easeOut', delay: 0.5 }}
        variants={title}
        height={props.height}
        minHeight={props.height}
      >
        {props.children}
      </HeaderContainerWrap>
    </HeaderContainer>
  )
}

export default Header
