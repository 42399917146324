export function initNotification() {
  let permission = Notification.permission
  if (permission === 'granted') {
    showNotification()
  } else if (permission === 'default') {
    requestAndShowPermission()
  } else {
    // alert('Use normal alert')
  }
}

export function showNotification(title, body) {
  if (document.visibilityState === 'visible') {
    return
  }
  let icon = '/images/logo.svg'
  var notification = new Notification(title, { body, icon })
  notification.onclick = () => {
    notification.close()
    window.parent.focus()
  }
}

export function requestAndShowPermission() {
  Notification.requestPermission(function(permission) {
    if (permission === 'granted') {
      showNotification()
    }
  })
}
