import styled, { css } from 'styled-components'
import { colors } from '../../theme'
import tinycolor from 'tinycolor2'

export const HeaderContainer = styled.div`
  && {
    display: block;
    min-height: ${props => `${props.height || 30}vh`};
    background-color: ${colors.primary[500]};
    ${props =>
      props.image && !props.color
        ? css`
            background-image: linear-gradient(
                to right,
                ${colors.primary.transparent[90]},
                ${colors.primary.transparent[90]}
              ),
              url(${props.image});
            background-repeat: no-repeat;
            background-position: center, center;
            background-size: contain, cover;
          `
        : css``}

    ${props =>
      props.image && props.color
        ? css`
            background-image: linear-gradient(
                to right,
                ${tinycolor(props.color)
                  .setAlpha(0.8)
                  .toRgbString()},
                ${tinycolor(props.color)
                  .setAlpha(0.8)
                  .toRgbString()}
              ),
              url(${props.image});
            background-repeat: no-repeat;
            background-position: center, center;
            background-size: contain, cover;
          `
        : css`
            background-color: ${props.color};
          `} 
          
          /* div {
      justify-items: center;
      align-items: center;
    } */
  }
`
export const HeaderContainerWrap = styled.div`
  && {
    display: flex;
    width: 80%;
    margin: 0 auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    min-height: ${props => `${props.height || 30}vh`};
  }
`
