import React, { useState } from 'react'
import {
  Button,
  Flex,
  InputGroup,
  Input,
  Textarea,
  Box,
  Switch,
  InputRightElement,
} from '@chakra-ui/react'

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'
import { setRemoteCall } from '../remote'
import { useGlobalState } from '../store'
import { BoardSmallClick } from './styled'

const EditDetails = (props) => {
  const { name, description, price, hide } = props.sector || {}
  const [sectorText, setSectorText] = useState(name)
  const [sectorDetails, setSectorDetails] = useState(description)
  const [sectorPrice, setSectorPrice] = useState(price)
  const [sectorHide, setSectorHide] = useState(hide)

  /**
   * Set Sector
   */
  const setSector = (id) => {
    setRemoteCall(
      'setSector',
      {
        _id: props.sector ? props.sector._id : null,
        name: sectorText,
        price: sectorPrice,
        description: sectorDetails,
        date: new Date(),
        attr: {},
        status: 1,
        hide: sectorHide,
      },
      null
    )
  }

  return (
    <div>
      <Input
        type={'text'}
        value={sectorText}
        onChange={(e) => setSectorText(e.target.value)}
        placeholder="Novo Produto"
      />
      <Textarea
        width={'100%'}
        padding={'0em'}
        margin={'1.5em 0'}
        minHeight={'15vh'}
        resize={'vertical'}
        borderRadius={'0em!important'}
        background={'transparent!important'}
        boxShadow={'none'}
        onChange={(event) => setSectorDetails(event.target.value)}
        placeholder={'Descrição'}
        fontSize={'1.4em'}
        type={'textarea'}
        value={sectorDetails}
      />{' '}
      <Box fontSize={'.8em'}>
        <Switch
          isChecked={sectorHide}
          onChange={() => setSectorHide(!sectorHide)}
        />{' '}
        Hidden
      </Box>
      <InputGroup size="lg">
        <Input
          type={'number'}
          value={sectorPrice}
          onChange={(e) => setSectorPrice(e.target.value)}
          placeholder="Preço"
        />
        <InputRightElement width="4.5rem">
          <Button
            size="sm"
            colorScheme="primary"
            marginTop={'.7rem'}
            onClick={() => {
              setSector()
            }}
          >
            Salvar +
          </Button>
        </InputRightElement>
      </InputGroup>
    </div>
  )
}

export default function EditSectors(props) {
  // const [initiatives, setInitiatives] = useGlobalState('initiatives')
  const [sectors, setSectors] = useGlobalState('sectors')

  const [id, setId] = useState(null)

  /**
   * Del Sector
   */
  const delSector = (sector) => {
    setRemoteCall('delSector', sector, null)
  }

  return (
    <Drawer
      isOpen={true}
      placement="right"
      isFullHeight={true}
      size={'md'}
      onClose={() => props.setOpenModal()}
      // finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">Editar Produto </DrawerHeader>
        <DrawerBody overflow={'auto'}>
          <BoardSmallClick active={id === null} onClick={() => setId(null)}>
            <Flex alignItems={'center'} justifyContent={'space-between'}>
              Adicionar novo Produto{' '}
              <Button size="sm" colorScheme="primary" variant="outline">
                +
              </Button>
            </Flex>
          </BoardSmallClick>
          {id === null && <EditDetails sector={null} />}
          {sectors &&
            sectors.map((init) => {
              return (
                <>
                  <BoardSmallClick
                    active={id === init._id}
                    onClick={() => setId(init._id)}
                  >
                    <Flex
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <div>
                        <span>{init.name}</span>
                      </div>
                      <div>
                        <strong>{init.price || 0} Euros</strong>{' '}
                        <Button
                          size="sm"
                          colorScheme="primary"
                          variant="outline"
                          onClick={() => delSector(init)}
                        >
                          -
                        </Button>
                      </div>
                    </Flex>
                    <Box fontSize={'.8em'}>{init.description}</Box>

                    {id === init._id && <EditDetails sector={init} />}
                  </BoardSmallClick>
                </>
              )
            })}
        </DrawerBody>

        <DrawerFooter borderTopWidth="1px">
          <Button
            variant="outline"
            colorScheme="primary"
            mr={3}
            onClick={() => props.setOpenModal()}
          >
            Fechar
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
