import React from 'react'
import { toast } from 'react-toastify'
import * as serviceWorker from './serviceWorker'
import { Button } from '@chakra-ui/react'

const WarningMessage = () => {
  return (
    <span>
      Atualize agora a versão que tem instalada para ter acesso a todos os novos
      desenvolvimentos. <br />
      <div style={{ textAlign: 'right' }}>
        <Button
          size={'sm'}
          variant="outline"
          onClick={() => window.location.reload()}
        >
          Atualizar
        </Button>
      </div>
    </span>
  )
}

export default function PWAUpdate(props) {
  serviceWorker.register({
    onSuccess: () =>
      setTimeout(() => toast.info('Aplicação instalada com sucesso'), 0),
    onUpdate: reg => {
      setTimeout(
        () =>
          toast.info(<WarningMessage />, {
            // this option will give you a func 'remove' as props
            autoClose: false
          }),
        0
      )
    }
  })

  return props.children
}
