import React, { useEffect, useState } from 'react'
import {
  Board,
  HistoryCard,
  ClientMetrics,
  ClientTargetOutline,
  Line,
  ClientMetricsHistory,
  AssessmentDetails,
  ClientMetricsOutline,
  ClientInitiativesOutline,
} from './styled'
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai'
import { setRemoteCall } from '../remote'
import { useGlobalState } from '../store'
import { SiWheniwork } from 'react-icons/si'
import { AiOutlineFileDone } from 'react-icons/ai'
import { Button, Box, Flex, Heading } from '@chakra-ui/react'
import moment from 'moment'
import { Link } from 'react-router-dom'

const ClientHistory = ({ assessment, client, noTitle, cats }) => {
  const [selected, setSelected] = useState(false)
  const [categories, setCategories] = useState([])
  const [deleteAction, setDeleteAction] = useState(false)
  const [deleted, setDeleted] = useState([])
  const [initiatives, setInitiatives] = useGlobalState('initiatives')
  const [targets, setTargets] = useGlobalState('targets')
  const catColor = [
    '#003B5B',
    '#1E78A8',
    '#EC1C2D',
    '#61B4E5',
    '#A9403B',
    '#AF96C7',
    '#8DC63F',
    '#F9B432',
    '#009CA7',
    '#60A944',
    '#FECE08',
    '#C49A6C',
    '#F26F22',
    '#324C8E',
  ]
  /**
   * Use effects
   */
  useEffect(() => {
    setRemoteCall('getCategories', {}, null, (ass) => {
      setCategories(ass)
    })
  }, [])

  return (
    <Box marginTop={'2em'}>
      <>
        {!noTitle && (
          <>
            <Heading as="h1" size="3xl">
              Relatório da Avaliação
            </Heading>
            <Heading as="h3" size="md">
              Visualização de dados
            </Heading>
          </>
        )}
        <AssessmentDetails>
          <Flex
            flexWrap={'wrap'}
            flexDirection={['column', 'column', 'row', 'row', 'row']}
          >
            {assessment.result &&
              Object.keys(assessment.result).map((r, i) => {
                const thisResult = assessment.result[r]
                const thisCat = categories.find((cat) => cat._id === r)
                // if (
                //   thisCat &&
                //   (!thisCat.percentage || Number(thisCat.percentage) === 0)
                // )
                //   return null
                const thisPercentage =
                  (
                    (thisResult.assessmentScore * 100) /
                    thisResult.score
                  ).toFixed(2) || false
                return (
                  <Board
                    percentage={
                      (thisResult.assessmentScore * 100) / thisResult.score
                    }
                    colorCat={
                      cats && cats[i] && cats[i].color !== 'undefined'
                        ? cats[i].color
                        : (catColor && catColor[i] && catColor[i]) || null
                    }
                    width={'auto'}
                    margin={'1em 1em 0 0 '}
                    key={i}
                  >
                    <Flex
                      justifyContent={'space-between'}
                      fontSize={'1.2em'}
                      padding={'.5em 0'}
                    >
                      <Box mr={'.8em'}>{thisCat && thisCat.name}</Box>
                      <Box fontWeight={'bold'}>
                        {!thisPercentage || isNaN(thisPercentage)
                          ? ''
                          : `${thisPercentage}%`}
                      </Box>
                      {/* <div>
                          questions: {thisResult.questions} answers:{' '}
                          {thisResult.answers}
                        </div> */}
                    </Flex>
                    {/* {thisResult.subCats &&
                      Object.keys(thisResult.subCats).map((subCat, a) => {
                        const thisSubCat = thisResult.subCats[subCat]
                        const thisSubCatDetails = categories.find(
                          cat => cat._id === subCat
                        )
                        const subCatinitiatives = []
                        const subCattargets = []
                        if (!thisSubCat) return null
                        return (
                          <div key={`a-${a}`}>
                            <ClientMetricsOutline>
                              <Flex
                                justifyContent={'space-between'}
                                fontSize={'1em'}
                                padding={'.2em 0'}
                              >
                                <div>
                                  {(thisSubCat.assessmentScore || 0).toFixed(2)}
                                  %{' - '}
                                  {thisSubCatDetails && thisSubCatDetails.name}
                                </div>
                            
                              </Flex>
                            </ClientMetricsOutline>

                            {thisSubCat.targets &&
                              thisSubCat.targets.length > 0 &&
                              thisSubCat.targets.map((target, x) => {
                                const foundTarget = targets.find(
                                  i => i._id === target
                                )

                                //lets not display duplicates
                                if (subCattargets.includes(foundTarget))
                                  return null
                                subCattargets.push(foundTarget)

                                if (foundTarget)
                                  return (
                                    <ClientTargetOutline key={`x-${x}`}>
                                      <AiOutlineFileDone size={'15px'} />{' '}
                                      {foundTarget.name}
                                    </ClientTargetOutline>
                                  )
                              })}

                            {thisSubCat.initiatives &&
                              thisSubCat.initiatives.length > 0 &&
                              thisSubCat.initiatives.map((init, y) => {
                                const foundInit = initiatives.find(
                                  i => i._id === init
                                )

                                //lets not display duplicates
                                if (subCatinitiatives.includes(foundInit))
                                  return null
                                subCatinitiatives.push(foundInit)

                                if (foundInit)
                                  return (
                                    <ClientInitiativesOutline key={`y-${y}`}>
                                      <SiWheniwork size={'15px'} />{' '}
                                      {foundInit.name}
                                    </ClientInitiativesOutline>
                                  )
                              })}
                          </div>
                        )
                      })} */}
                  </Board>
                )
              })}
          </Flex>
        </AssessmentDetails>
      </>
    </Box>
  )
}

export default ClientHistory
