import React, { useState } from 'react'

import { Heading, Box, Flex, Button } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { setRemoteCall } from '../remote'
import EditQuestion from './EditQuestion'
import { EditIcon, DragHandleIcon } from '@chakra-ui/icons'
import { useGlobalState } from '../store'
import { Board, QuestionsWrap, QuestionWrap } from './styled'
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
} from 'react-sortable-hoc'
import arrayMove from 'array-move'

export const tableQuestionCollumns = [
  { k: '_id', l: '_id' },
  { k: 'text', l: 'Perguntas', t: 'textarea' },
  // { k: 'percentage', l: 'Percentage', t: 'percentage' },
  { k: 'initiatives', l: 'Iniciativas', t: 'initiatives' },
  { k: 'targets', l: 'Targets', t: 'targets' },
  // { k: 'type', l: 'Quesiton Type', t: 'type' },
  { k: 'source', l: 'Fontes', t: 'source' },
  // { k: 'date', l: 'Start Data', t: 'date' },
  // { k: 'order', l: 'Order' },
  { k: 'status', l: 'Estado' },
  { k: 'notes', l: 'Notas', t: 'textarea' },
  { k: 'range', l: 'Alcance', t: 'range' },
  // { k: 'sector', l: 'Sector', t: 'sectors' },
  // { k: 'parent', l: 'Parent', t: 'category' },
  // { k: 'location', l: 'Localização', t: 'map' }
  // { k: 'attr', l: 'Atributos' },
]

export default function Questions(props) {
  const [questions, setQuestions] = useGlobalState('questions')
  const [categories, setCategories] = useGlobalState('categories')

  const [openModalQuestion, setOpenModalQuestion] = useState(false)

  const item = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: -100 },
  }
  /**
   * Sortable
   */
  const DragHandle = sortableHandle(() => <DragHandleIcon cursor={'move'} />)

  const SortableQuestionItem = SortableElement(({ value, index }) => {
    return (
      <Board
        as={motion.div}
        variants={item}
        active={value._id === openModalQuestion}
      >
        <Flex
          cursor={'pointer'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box width={'80%'} fontSize={'.8em'}>
            {/* <DragHandle />  */}
            {value.text}
          </Box>
          <Box
            color={'primary.500'}
            width={'10%'}
            fontSize={'.8em'}
            onClick={() => setOpenModalQuestion(value._id)}
          >
            {!props.loadedFromSector && <EditIcon />}
          </Box>
        </Flex>
      </Board>
    )
  })

  const SortableQuestionList = SortableContainer(({ items }) => {
    return (
      <div>
        {items &&
          items.map((value, index) => (
            <SortableQuestionItem
              key={`itemQ-${index}`}
              index={index}
              value={value}
              disabled={props.loadedFromSector}
            />
          ))}
      </div>
    )
  })

  /**
   * Set Questions
   */
  const setRemoteQuestion = (data, notice) => {
    let saveData = {
      ...data,
      date: new Date(),
      parent: props.subCategoryId,
      sector: props.sector,
      client: props.client,
    }
    console.log(saveData)
    setRemoteCall('setQuestion', saveData, notice, (cb) => {
      setQuestions(cb)
    })
  }
  /**
   * Set Questions Order
   */
  const setRemoteQuestionOrder = (questions) => {
    const questionOrder = questions.map((q, i) => {
      return { _id: q._id, order: i }
    })

    let data = {
      questions: questionOrder,
      category: props.subCategoryId,
    }
    if (props.client) {
      data.client = props.client
    } else {
      data.sector = props.sector
    }
    setRemoteCall('setQuestionOrder', data, null, (cb) => {
      // setQuestions(cb)
      console.log(cb)
    })
    setQuestions(questions)
  }

  const thisCat = categories.find((c) => c._id === props.subCategoryId)
  return (
    <>
      <QuestionsWrap>
        <Flex
          justifyContent={'space-between'}
          flexDirection={['column', 'column', 'row', 'row', 'row']}
        >
          <Box>
            <Heading>{thisCat?.name} </Heading>
          </Box>
          <Box>
            <Button
              as={motion.div}
              variants={item}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              colorScheme="primary"
              size="lg"
              value="Submit!"
              width={'100%'}
              _hover={{
                background: 'secondary.500',
              }}
              onClick={() => setOpenModalQuestion('cat')}
            >
              Nova Pergunta
            </Button>
          </Box>
        </Flex>
        <QuestionWrap>
          <SortableQuestionList
            items={questions}
            lockAxis={'y'}
            onSortEnd={({ oldIndex, newIndex }) => {
              if (oldIndex !== newIndex)
                setRemoteQuestionOrder(arrayMove(questions, oldIndex, newIndex))
            }}
          />
        </QuestionWrap>
      </QuestionsWrap>

      {openModalQuestion && (
        <EditQuestion
          data={questions.find((q) => q._id === openModalQuestion)}
          order={questions.length}
          form={tableQuestionCollumns}
          set={setRemoteQuestion}
          delQuestion={props.delQuestion}
          setOpenModal={setOpenModalQuestion}
        />
      )}
    </>
  )
}
