import React, { useEffect, useState } from 'react'
import {
  Button,
  Select,
  Box,
  InputLeftAddon,
  InputGroup,
  Input,
  Textarea,
  Text,
  Flex,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useToast,
} from '@chakra-ui/react'
import Dialog from '../components/Dialog/Dialog'
import { setRemoteCall } from '../remote'
import { colors } from '../theme'

import {
  CompleteAssessmentOutline,
  CategoryCard,
  QuestionsCard,
  SubCategoryCard,
  BoardOptionsOverview,
  BoardSlider,
  CategoryLogo,
  QuestionWrapper,
  QuestionTitle,
} from './styled'
import { FiSave, FiArrowRightCircle } from 'react-icons/fi'
import { RiChatOffLine } from 'react-icons/ri'
import { VscCloseAll } from 'react-icons/vsc'
import { BsArrowRight } from 'react-icons/bs'
import { useGlobalState } from '../store'

export default function Map(props) {
  const toast = useToast()
  const [loggedIn, setLoggedIn] = useGlobalState('loggedIn') //dont remove
  const [selCategory, setSelCategory] = useState(null)
  const [selSubCategory, setSelSubCategory] = useState(0)
  const [selQuestion, setSelQuestion] = useState(null)
  const [question, setQuestion] = useState(false)
  const [conclude, setConclude] = useState(false)
  const [questionId, setQuestionId] = useState(null)
  const [currAnswer, setCurrAnswer] = useState({})
  const [sliderVal, setSliderVal] = useState(currAnswer.value || 0)

  const catColor = [
    '#003B5B',
    '#1E78A8',
    '#EC1C2D',
    '#61B4E5',
    '#A9403B',
    '#AF96C7',
    '#8DC63F',
    '#F9B432',
    '#009CA7',
    '#60A944',
    '#FECE08',
    '#C49A6C',
    '#F26F22',
    '#324C8E',
  ]

  const SlideComponent = ({ sliderVal, currAnswer, setCurrAnswer }) => {
    const [sliderValInt, setSliderVal] = useState(currAnswer.value || 0)
    // useEffect(() => {
    //   setSliderVal(sliderVal)
    // }, [sliderVal])

    return (
      <BoardSlider full={true}>
        <Flex>
          <Slider
            aria-label="slider-ex-1"
            defaultValue={sliderValInt}
            onChange={(val) => setSliderVal(val)}
            onChangeEnd={(val) => {
              setCurrAnswer({
                ...currAnswer,
                value: val,
                score: val,
              })
            }}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb boxSize={5} background={colors.primary[500]} />
          </Slider>
          <Box marginLeft={'.8em'} fontWeight={'bold'}>
            {sliderValInt}%
          </Box>
        </Flex>
      </BoardSlider>
    )
  }
  /**
   * Set awswear
   */
  const saveAnswers = (data, notice) => {
    if (
      !currAnswer.value &&
      !currAnswer.score &&
      !currAnswer.comment &&
      !currAnswer.ignore
    ) {
      //no data lets not save
      setCurrAnswer({})
      setSliderVal(0)
    } else {
      try {
        const finalAnswer = {
          _id: currAnswer._id,
          question: questionId,
          value: currAnswer.value,
          date: new Date(),
          score: currAnswer.score,
          comment: currAnswer.comment,
          assessment: props.assessment._id,
          ignore: currAnswer.ignore,
          client: props.client,
          isName: question.text.toLowerCase() === 'nome do evento',
        }
        //set save answers
        setRemoteCall('setAnswer', finalAnswer, notice, (d) => {
          const newAssessment = { ...props.assessment }
          //lets update the accessment
          newAssessment.structure[selCategory].subCategories[
            selSubCategory
          ].questions[selQuestion].answer = d
          props.setAssessment(newAssessment)
          // //lets calculate
          props.getAssessmentResults(newAssessment.structure)
          props.saveProgress()

          if (
            props.category === selCategory &&
            props.subCategory === selSubCategory &&
            props.questionNumber === selQuestion
          ) {
            props.setCurrAnswer(d)
          }

          //update state
          toast({
            title: 'Answer Saved',
            status: 'success',
            position: 'bottom',
          })
          // setCurrAnswer({})
          // setSliderVal(0)
        })
      } catch (error) {
        toast({
          title: 'Answer Not saved',
          status: 'warning',
          position: 'bottom',
        })
      }
    }
  }

  /**
   *
   * for not applicable we neet o save the original answer
   * @param {*} id
   * @returns
  const saveNotApplicalbeCat = (id) => {
    if (!loggedIn?.admin) return null
    try {
      const newAssessment = { ...props.assessment }
      console.log(newAssessment)
      let overScore = 0
      let totalCats = 0
      //find and mutate value to 0 for category from structure
      newAssessment.structure
        .forEach((cat) => {
          if (cat._id === id) {
            overScore = overScore + cat.score
            return {
              ...cat,
              originalPercentage: cat.percentage,
              percentage: 0,
              subCategories: cat.subCategories.forEach((sub) => {
                sub.questions.forEach((question) => {
                  question.answer = {
                    value: 0,
                    score: 0,
                    comment: '',
                    ignore: false,
                  }
                })
              }),
            }
          }

          if (!cat.originalPercentage) {
            return { ...cat, originalPercentage: cat.percentage }
          }

          //lets hanlde other not applicable
          if (
            cat.originalPercentage &&
            cat.percentage === 0 &&
            cat.originalPercentage !== 0
          ) {
            overScore = overScore + cat.score
          }

          //let get Total cats with score bigger than 0
          if (cat.percentage > 0) {
            totalCats = totalCats + 1
          }

          return cat
        })
        .forEach((cat) => {
          if (cat.originalPercentage > 0 && cat.percentage !== 0) {
            cat.percentage = cat.originalPercentage + overScore / totalCats
          }
        })

      props.setAssessment(newAssessment)
      // // //lets calculate
      props.getAssessmentResults(newAssessment.structure)
      // props.saveProgress()
    } catch (error) {
      toast({
        title: 'Category Disabled',
        status: 'warning',
        position: 'bottom',
      })
    }
  }
  */

  return (
    <QuestionWrapper>
      <div className="questionWrap">
        <div>
          {props.assessmentDetails && (
            <QuestionTitle>
              <h1>{props.assessmentDetails.name}</h1>
              <span>{props.assessmentDetails.description}</span>
            </QuestionTitle>
          )}
        </div>

        {props.assessment &&
          props.assessment.structure &&
          props.assessment.structure.map((category, a) => {
            let thisResult =
              props.assessmentResult && props.assessmentResult[category._id]
                ? props.assessmentResult[category._id]
                : {}

            return (
              <>
                <CategoryCard
                  key={a}
                  color={
                    category.color === 'undefined'
                      ? catColor[a] || null
                      : category.color || catColor[a]
                  }
                  logo={category.logo || `/images/categories/cat${a + 1}.jpg`}
                  percentage={(thisResult.answers * 100) / thisResult.questions}
                  onClick={() => {
                    if (!selQuestion && selQuestion != 0) {
                      setCurrAnswer({})
                      setSelQuestion(null)
                      setSelCategory(selCategory === a ? null : a)
                      setSliderVal(0)
                      props.setCategoryImage(a + 1)
                      props.goToQuestion(selCategory, null, null)
                    } else {
                      toast({
                        title: 'Please save or close your previouse answer',
                        status: 'warning',
                        position: 'bottom',
                      })
                    }
                  }}
                >
                  <Flex alignItems={'center'} justifyContent={'space-between'}>
                    <Flex
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      w={'100%'}
                    >
                      <Flex alignItems={'center'}>
                        <Box>
                          <CategoryLogo
                            logo={
                              category.logo ||
                              `/images/categories/cat${a + 1}.png`
                            }
                          />
                          {/* <Box
                            display={'inline-block'}
                            fontSize={'1.3em'}
                            mr={'.8em'}
                          >
                            {a + 1}
                          </Box> */}
                        </Box>
                        {category.name}
                      </Flex>
                      {/* {loggedIn?.admin && (
                        <Button
                          colorScheme={
                            !currAnswer.ignore ? 'primary' : 'secondary'
                          }
                          padding={'.0em 1.5em'}
                          border={'2px solid #fff'}
                          mr={'0.5em'}
                          fontSize={'.7rem'}
                          onClick={() => saveNotApplicalbeCat(a)}
                        >
                          Não aplicável
                        </Button>
                      )} */}
                    </Flex>
                  </Flex>
                </CategoryCard>

                {selCategory === a &&
                  category.subCategories &&
                  category.subCategories.map((subCategories, b) => {
                    return (
                      <>
                        <SubCategoryCard
                          key={b}
                          color={category.color || catColor[a]}
                          logo={category.logo}
                          percentage={
                            (thisResult.answers * 100) / thisResult.questions
                          }
                          onClick={() => {
                            if (!selQuestion && selQuestion != 0) {
                              setCurrAnswer({})
                              setSelQuestion(null)
                              setSliderVal(0)
                              setSelSubCategory(selSubCategory === b ? 0 : b)
                            } else {
                              toast({
                                title:
                                  'Please save or close your previouse answer',
                                status: 'warning',
                                position: 'bottom',
                              })
                            }
                          }}
                        >
                          <Flex alignItems={'center'}>
                            <Box mr={'1em'}>
                              <BsArrowRight />
                            </Box>{' '}
                            {subCategories.name}
                          </Flex>
                        </SubCategoryCard>
                        {selSubCategory === b &&
                          subCategories.questions &&
                          subCategories.questions.map((questions, c) => {
                            return (
                              <>
                                <QuestionsCard
                                  key={c}
                                  color={
                                    category.color === 'undefined'
                                      ? catColor[a] || null
                                      : category.color || catColor[a]
                                  }
                                  someSelected={selQuestion ? true : false}
                                  isSelected={
                                    selQuestion && selQuestion === c
                                      ? true
                                      : false
                                  }
                                  completed={questions.answer ? true : false}
                                  onClick={() => {
                                    if (!selQuestion && selQuestion != 0) {
                                      setSelQuestion(c)
                                      setQuestionId(questions._id)
                                      setSliderVal(
                                        questions?.answer?.value || 0
                                      )
                                      setQuestion(questions)
                                      setCurrAnswer(questions.answer || {})
                                      props.goToQuestion(
                                        selCategory,
                                        selSubCategory,
                                        c
                                      )
                                    } else {
                                      toast({
                                        title:
                                          'Por favor salva ou fecha as pergunta anterior',
                                        status: 'warning',
                                        position: 'bottom',
                                      })
                                    }
                                  }}
                                >
                                  <Flex
                                    width={'100%'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                  >
                                    <Flex alignItems={'center'}>
                                      <Box
                                        display={'inline-block'}
                                        fontSize={'1.3em'}
                                        mr={'.8em'}
                                      >
                                        {a + 1}.{c + 1}
                                      </Box>
                                      <Box textAlign={'left'}>
                                        {questions.text}
                                      </Box>
                                    </Flex>
                                    <Flex alignItems={'center'}>
                                      <a
                                        style={{ fontSize: '1.5em' }}
                                        onClick={() => {
                                          // props.set({ ...details }, ` updating client`, 'clients')
                                          props.goToQuestion(
                                            selCategory,
                                            selSubCategory,
                                            c
                                          )
                                          // props.setOpenModal()
                                        }}
                                      >
                                        <FiArrowRightCircle
                                          color={'secondary'}
                                        />
                                      </a>{' '}
                                    </Flex>
                                  </Flex>
                                </QuestionsCard>
                                {selQuestion === c && (
                                  <Box width={'90%'} m={'0 auto'}>
                                    <Flex
                                      justifyContent={'space-between'}
                                      direction={{
                                        base: 'column',
                                        md: 'row',
                                      }}
                                    >
                                      <Box width={{ base: '100%', md: '70%' }}>
                                        {questions &&
                                          (!questions.range ||
                                            questions.range.length < 1) &&
                                          !questions.noOptions && (
                                            <SlideComponent
                                              setSliderVal={setSliderVal}
                                              sliderVal={sliderVal}
                                              currAnswer={currAnswer}
                                              setCurrAnswer={setCurrAnswer}
                                            />
                                          )}
                                        {questions.range &&
                                          questions.range.map((range, key) => {
                                            return (
                                              <BoardOptionsOverview
                                                key={key}
                                                active={
                                                  currAnswer &&
                                                  currAnswer.value === range
                                                }
                                                onClick={() =>
                                                  setCurrAnswer({
                                                    ...currAnswer,
                                                    value: range,
                                                    score:
                                                      key === 0
                                                        ? 0
                                                        : ((key + 1) * 100) /
                                                          questions.range
                                                            .length,
                                                  })
                                                }
                                              >
                                                {range}
                                              </BoardOptionsOverview>
                                            )
                                          })}
                                      </Box>
                                      <Flex
                                        width={{ base: '100%', md: '50%' }}
                                        alignItems={'center'}
                                        justifyContent={'flex-end'}
                                      >
                                        <Button
                                          colorScheme={
                                            !currAnswer.ignore
                                              ? 'primary'
                                              : 'secondary'
                                          }
                                          padding={'.0em 1.5em'}
                                          border={'2px solid #fff'}
                                          mr={'0.5em'}
                                          fontSize={'.8em'}
                                          onClick={() =>
                                            setCurrAnswer({
                                              ignore:
                                                !currAnswer.ignore || false,
                                            })
                                          }
                                        >
                                          Não aplicável
                                        </Button>
                                        <Button
                                          colorScheme="primary"
                                          padding={'.0em 1.5em'}
                                          border={'2px solid #fff'}
                                          mr={'0.5em'}
                                          fontSize={'.8em'}
                                          onClick={() => {
                                            // props.set({ ...details }, ` updating client`, 'clients')
                                            setSelQuestion()
                                          }}
                                        >
                                          Fechar
                                        </Button>
                                        <Button
                                          colorScheme="primary"
                                          padding={'.0em 1.5em'}
                                          border={'2px solid #fff'}
                                          mr={'0.5em'}
                                          fontSize={'.8em'}
                                          onClick={() => {
                                            // props.set({ ...details }, ` updating client`, 'clients')
                                            saveAnswers()
                                            setSelQuestion()
                                          }}
                                        >
                                          Salvar
                                        </Button>
                                      </Flex>
                                    </Flex>
                                    <Textarea
                                      padding={'1.5em'}
                                      width={'100%'}
                                      margin={'1em 0'}
                                      onChange={(e) =>
                                        setCurrAnswer({
                                          ...(currAnswer || {}),
                                          comment: e.target.value,
                                        })
                                      }
                                      placeholder="Por favor adiciona um comentário"
                                      value={
                                        (currAnswer && currAnswer.comment) || ''
                                      }
                                    />
                                  </Box>
                                )}
                              </>
                            )
                          })}
                      </>
                    )
                  })}
              </>
            )
          })}
        <CompleteAssessmentOutline
          onClick={() => {
            setConclude(true)
          }}
        >
          Concluir avaliação ›
          {conclude && (
            <Dialog
              title={'Quer terminar esta avalição?'}
              close={() => setConclude(false)}
              content={
                <p>
                  Se você continuar não poderá editar esta avaliação novamente.
                </p>
              }
              cb={() => props.endAssessment()}
            />
          )}
        </CompleteAssessmentOutline>
        <br />
      </div>
    </QuestionWrapper>
  )
}
