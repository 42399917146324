//axios setup
import axios from 'axios'

export const presistData = (type, data) => {
  try {
    if (typeof localStorage !== 'undefined')
      localStorage[type] = JSON.stringify({ ...data, status: '' })
  } catch (error) {
    console.log(error)
  }
  return data
}

export function jsonEquals(a, b) {
  return JSON.stringify(a) === JSON.stringify(b)
}

export const getPresistData = (type, data) => {
  try {
    if (typeof localStorage !== 'undefined' && localStorage[type]) {
      return JSON.parse(localStorage[type])[data]
    }
  } catch (error) {
    console.log(error)
  }
  return null
}

export const configAxios = () => {
  axios.defaults.baseURL = `${
    process.env.API_URL || 'https://balanceassessmentapi.cors.digital'
  }/api/`

  axios.defaults.headers.post['Content-Type'] =
    'application/x-www-form-urlencoded'
  axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest'
  axios.defaults.withCredentials = false // for session keeping
  try {
    if (
      typeof localStorage === 'undefined' &&
      !localStorage.getItem('cmoreApp')
    ) {
      throw new Error('no user data')
    }

    const userData = JSON.parse(localStorage.getItem('cmoreApp') || null)
    axios.defaults.headers.common['Authorization'] =
      'Bearer ' + userData.loggedIn.token
  } catch (error) {
    axios.defaults.headers.common['Authorization'] = ''
  }
}
