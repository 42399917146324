import React from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { useGlobalState } from '../store'

import { userLoggedIn, checkAuthRoute, checkAdminRoute } from '../remote'

import './App.css'
import { over } from '../theme'
import Page from '../components/page'
import Login from '../login/Login'
import Register from '../login/Register'
import Reset from '../login/Reset'
import QuestionsEditor from '../questionsEditor/QuestionsEditor'
import Users from '../users/Users'
import Clients from '../clients/Clients'
import Assessment from '../assessment/Assessment'
import Reports from '../reports/Reports'
import Dashboard from '../dashboard/Dashboard'
import WatchAuth from '../auth/WatchAuth'
import EditProfile from '../users/EditProfile'

export default function App() {
  //we need this to rerender the app upon login
  const [loggedIn, setLoggedIn] = useGlobalState('loggedIn') //dont remove
  // const loggedIn = getGlobalState('loggedIn')

  userLoggedIn()

  return (
    <ChakraProvider theme={over}>
      <Router>
        <WatchAuth>
          <Page>
            <Switch>
              <Route
                exact
                path="/dashboard"
                component={() => checkAuthRoute() && <Dashboard />}
              />
              <Route
                exact
                path="/reset/:token"
                component={() => (
                  <Reset
                  // setStorageState={this.setStorageState}
                  />
                )}
              />

              <Route
                exact
                path="/clients"
                component={() => checkAdminRoute() && <Clients />}
              />
              <Route
                exact
                path="/questions/:clientId?"
                component={() => checkAdminRoute() && <QuestionsEditor />}
              />
              <Route
                exact
                path="/assessment/:sectorId/:clientId?"
                component={() => checkAuthRoute() && <Assessment />}
              />
              <Route
                exact
                path="/users"
                component={() => checkAdminRoute() && <Users />}
              />
              <Route
                exact
                path="/reports/:clientId?/:dateStartURL?/:dateEndURL?"
                component={() =>
                  checkAuthRoute() && (
                    <Reports
                    // data={users}
                    // types={userTypes}
                    // setRemoteCall={setRemoteCall}
                    // setStorageState={this.setStorageState}
                    />
                  )
                }
              />
              <Route
                exact
                path="/reports/:clientId?"
                component={() =>
                  checkAdminRoute() && (
                    <Reports
                    // data={users}
                    // types={userTypes}
                    // setRemoteCall={setRemoteCall}
                    // setStorageState={this.setStorageState}
                    />
                  )
                }
              />

              <Route
                exact
                path="/"
                component={() => checkAuthRoute(true) && <Login />}
              />
              <Route exact path="/register" component={() => <Register />} />
            </Switch>
          </Page>
        </WatchAuth>
      </Router>
      <ToastContainer
        position="top-right"
        hideProgressBar={true}
        closeOnClick
      />
      <EditProfile />
    </ChakraProvider>
  )
}
