import styled, { css } from 'styled-components'
import { colors } from '../theme'

export const Board = styled.div`
  && {
    width: 100%;
    border: none;
    background: #fff;
    padding: 1em;
    margin: 0 1em 1em 0;
    border-radius: 2em;
    min-height: 2em;
    box-shadow: 0 2px 9px 0 ${colors.primary.transparent[20]};
    transition: all ease-in 0.3s;
    ${props =>
      props.active
        ? css`
            background: ${colors.secondary[500]};
            color: #fff;
            border: 0;
          `
        : ``}
  }
`
export const BoardSmallClick = styled.div`
  && {
    width: 100%;
    border: none;
    cursor: pointer;
    background: #fff;
    padding: 0.6em 1em;
    margin: 0 1em 1em 0;
    border-radius: 2em;
    min-height: 2em;
    box-shadow: 0 2px 9px 0 ${colors.primary.transparent[20]};
    transition: all ease-in 0.3s;
    ${props =>
      props.active
        ? css`
            background: ${colors.secondary[500]};
            color: #fff;
            border: 0;
          `
        : ``}
  }
`
export const BoardSmall = styled.div`
  && {
    width: 100%;
    border: none;
    background: #fff;
    padding: 0.6em 1em;
    margin: 0 1em 1em 0;
    border-radius: 2em;
    min-height: 2em;
    box-shadow: 0 2px 9px 0 ${colors.primary.transparent[20]};
    transition: all ease-in 0.3s;
    ${props =>
      props.active
        ? css`
            background: ${colors.secondary[500]};
            color: #fff;
            border: 0;
          `
        : ``}
  }
`
export const QuestionWrap = styled.div`
  && {
    margin: 3rem 0;
    @media screen and (max-width: 768px) {
      order: 2;
    }
  }
`

export const Row = styled.div`
  && {
    display: flex;
    margin: 2em 0;
  }
`
export const CategoryList = styled.div`
  && {
    width: 20vw;
    padding: 2vh 2em;
    height: 100vh;
    background: ${colors.primary[500]};
    overflow: auto;
    position: fixed;
    left: 80px;

    background-image: linear-gradient(
        to right,
        ${colors.primary.transparent[90]},
        ${colors.primary.transparent[90]}
      ),
      url('/images/questions.jpg');
    background-repeat: no-repeat;
    background-position: fixed;
    background-size: 100%, cover;
    @media screen and (max-width: 768px) {
      width: 100%;
      position: relative;
      height: auto;
      order: 0;
      left: 0;
    }
  }
`
export const SubCategoryList = styled.div`
  && {
    width: 20vw;
    height: 100%;
    padding: 10vh 2em;
    background: ${colors.secondary[500]};
    overflow: auto;
    position: fixed;
    left: calc(20vw + 80px);
    background-image: linear-gradient(
        to right,
        ${colors.secondary.transparent[90]},
        ${colors.secondary.transparent[90]}
      ),
      url('/images/questions.jpg');
    background-repeat: no-repeat;
    background-position: right;
    background-size: 100%, cover;
    @media screen and (max-width: 768px) {
      position: relative;
      width: 100%;
      height: auto;
      left: 0;
      order: 1;
      padding-bottom: 80px;
    }
  }
`
export const QuestionsWrap = styled.div`
  && {
    width: 53vw;
    margin-left: 40vw;
    min-height: 100vh;
    padding: 10vh;
    overflow: auto;
    @media screen and (max-width: 768px) {
      width: 100%;
      margin-left: 0;
      order: 2;
      padding: 2vh;
    }
  }
`
export const QuestionsEditorWrap = styled.div`
  && {
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }
`
