import React from 'react'
import { Link } from 'react-router-dom'
import { useGlobalState, setStorageState, getGlobalState } from '../../store'
import {
  ListContainer,
  StyledDrawer,
  StyledListItem,
  StyledNavigationPanel,
  StyledLogo,
  StyledListWrap,
  StyledFooter,
  StyledList,
} from './styled'

import { BsColumnsGap, BsPeople, BsBarChart } from 'react-icons/bs'

import { FaUserCircle } from 'react-icons/fa'
import {
  AiOutlineUser,
  AiOutlineComment,
  AiOutlineLogout,
} from 'react-icons/ai'

import { Icon } from '@chakra-ui/react'

const Navigation = (props) => {
  // const [showApproval, setShowApproval] = useState(false)
  const [loggedIn, setLoggedIn] = useGlobalState('loggedIn')
  const [userDetails, setUserDetails] = useGlobalState('editProfile') //dont remove
  const socket = getGlobalState('socket')
  const [pageClass] = useGlobalState('pageClass')
  const logout = async () => {
    if (socket) await socket.close()
    setStorageState('loggedIn', null)
    setLoggedIn(null)
  }
  if (!loggedIn || !loggedIn.admin) {
    return (
      <StyledNavigationPanel>
        <StyledDrawer variant="permanent" anchor="left">
          <ListContainer>
            <StyledLogo url={loggedIn.logo} />
            <StyledListWrap>
              <StyledList>
                <StyledListItem active={pageClass === 'dashboard'}>
                  <Link to={'/dashboard'}>
                    <Icon as={BsColumnsGap} />
                    <span>Dashboard</span>
                  </Link>
                </StyledListItem>
                <StyledListItem active={pageClass === 'reports'}>
                  <Link to={'/reports'}>
                    <Icon as={BsBarChart} />
                    <span>Relatórios</span>
                  </Link>
                </StyledListItem>
              </StyledList>
            </StyledListWrap>
          </ListContainer>
          <StyledFooter>
            <StyledList>
              <StyledListItem>
                <Link onClick={() => logout()} border="1px">
                  <Icon as={AiOutlineLogout} />
                </Link>
              </StyledListItem>
              <StyledListItem>
                <Link border="1px" onClick={() => setUserDetails(true)}>
                  <Icon as={FaUserCircle} />
                </Link>
              </StyledListItem>
            </StyledList>
          </StyledFooter>
        </StyledDrawer>
      </StyledNavigationPanel>
    )
  }

  return (
    <StyledNavigationPanel>
      <StyledDrawer variant="permanent" anchor="left">
        <ListContainer>
          <StyledLogo url={loggedIn.logo} />
          <StyledListWrap>
            <StyledList>
              <StyledListItem active={pageClass === 'dashboard'}>
                <Link to={'/dashboard'}>
                  <Icon as={BsColumnsGap} />
                  <span>Dashboard</span>
                </Link>
              </StyledListItem>
              <StyledListItem active={pageClass === 'clients'}>
                <Link to={'/clients'}>
                  <Icon as={BsPeople} />
                  <span>Clientes</span>
                </Link>
              </StyledListItem>
              <StyledListItem active={pageClass === 'questions'}>
                <Link to={'/questions'}>
                  <Icon as={AiOutlineComment} />
                  <span>Avaliações</span>
                </Link>
              </StyledListItem>

              <StyledListItem active={pageClass === 'reports'}>
                <Link to={'/reports'}>
                  <Icon as={BsBarChart} />
                  <span>Relatórios</span>
                </Link>
              </StyledListItem>
              <StyledListItem active={pageClass === 'users'}>
                <Link to={'/users'}>
                  <Icon as={AiOutlineUser} />
                  <span>Utilizadores</span>
                </Link>
              </StyledListItem>
            </StyledList>
          </StyledListWrap>
        </ListContainer>
        <StyledFooter>
          <StyledList>
            <StyledListItem>
              <Link onClick={() => logout()} border="1px">
                <Icon as={AiOutlineLogout} />
              </Link>
            </StyledListItem>
            <StyledListItem>
              <Link border="1px" onClick={() => setUserDetails(true)}>
                <Icon as={FaUserCircle} />
              </Link>
            </StyledListItem>
          </StyledList>
        </StyledFooter>
      </StyledDrawer>
    </StyledNavigationPanel>
  )
}

export default Navigation
