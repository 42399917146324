import React, { useState, useEffect } from 'react'
import { Button, Icon, Flex } from '@chakra-ui/react'
import { ProductCard, ClientTitleHistory } from './styled'
import { useGlobalState, getGlobalState } from '../store'
import { VscRepoPush, VscRepo } from 'react-icons/vsc'
import Purchase from './Purchase'
import { setRemoteCall } from '../remote'
import { Link } from 'react-router-dom'

export default function Sectors(props) {
  const [purchases, setPurchases] = useGlobalState('purchases')
  const [sectors, setSectors] = useGlobalState('sectors')
  const [sector, setSector] = useState(props.sector)
  const [client, setClient] = useState(props.client)
  const loggedIn = getGlobalState('loggedIn') //dont remove

  /**
   * Lets check if we have a subscription
   */
  useEffect(() => {
    setRemoteCall(
      'getActivePurchases',
      {
        client,
      },
      null,
      (cb) => {
        // console.log(cb)
      }
    )
  }, [])

  const delPurchase = (id) => {
    setRemoteCall(
      'delPurchase',
      {
        id: id,
        client,
      },
      null,
      (cb) => {
        // console.log(cb)
      }
    )
  }

  return (
    sectors &&
    sectors.map((sect, i) => {
      //check if sector is active
      const isActive = purchases.find(
        (purchase) => purchase.sector === sect._id && client === purchase.client
      )
      if (props.onlyActive && !isActive) {
        return true
      } else if (props.onlyActive && isActive) {
        //lets check if the sector is already in the assessments

        const hasStarted =
          props.assessments &&
          props.assessments.find((ass) => sect._id === ass.sector)
        if (hasStarted || !props.assessments) {
          return true
        }
      }
      //hide if hidden sect
      if (sect.hide && !loggedIn.admin) return null

      return (
        <ProductCard key={i} active={isActive}>
          <ClientTitleHistory active={isActive}>{sect.name}</ClientTitleHistory>
          <div>{sect.description}</div>
          <Flex alignItems={'center'} justifyContent={'space-between'}>
            {/* <strong>{sect.price || 0} Euros</strong> */}
            <Flex ml={'1em'}>
              {!isActive && (
                <Purchase
                  client={client}
                  sector={sect._id}
                  name={sect.name}
                  description={sect.description}
                  price={sect.price || 0}
                >
                  <Button
                    colorScheme={'primary'}
                    size="sm"
                    marginRight={'.2em'}
                    fontSize={'.7em'}
                    value="Submit!"
                    _hover={{
                      background: 'secondary.500',
                    }}
                    onClick={() => setSector(sect._id)}
                  >
                    <Icon
                      as={VscRepo}
                      color={'white'}
                      height={'1.5em'}
                      width={'1.5em'}
                      marginRight={'.2em'}
                    />{' '}
                    Comprar
                  </Button>
                </Purchase>
              )}
              {isActive && (
                <Link to={`/assessment/${sect._id}/${client}`}>
                  <Button
                    colorScheme={'secondary'}
                    size="sm"
                    marginRight={'.2em'}
                    fontSize={'.7em'}
                    value="Enviar!"
                    _hover={{
                      background: 'primary.500',
                    }}
                  >
                    <Icon
                      as={VscRepo}
                      color={'white'}
                      height={'1.5em'}
                      width={'1.5em'}
                      marginRight={'.2em'}
                    />{' '}
                    Começar
                  </Button>
                </Link>
              )}
              {isActive && loggedIn && loggedIn.admin && (
                <Button
                  colorScheme={'secondary'}
                  size="sm"
                  marginRight={'.2em'}
                  fontSize={'.7em'}
                  value="Submit!"
                  _hover={{
                    background: 'secondary.500',
                  }}
                  onClick={() => delPurchase(isActive._id)}
                >
                  Apagar
                </Button>
              )}
            </Flex>
            {/* {sector && sector === sect._id && (
              <Purchase sector={sector} client={client} />
            )} */}
          </Flex>
        </ProductCard>
      )
    })
  )
}
