import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Box, Flex, Select, Button, useToast } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { setRemoteCall } from '../remote'
import { colors } from '../theme'
import EditCategory from './EditCategory'
import EditInitiatives from './EditInitiatives'
import EditTargets from './EditTargets'
import EditSectors from './EditSectors'
import Questions from './Questions'
import Dialog from '../components/Dialog/Dialog'
import { EditIcon, DragHandleIcon } from '@chakra-ui/icons'
import { setGlobalState, useGlobalState } from '../store'
import Upload from '../components/Upload'
import { Progress } from '@chakra-ui/react'

import {
  Board,
  CategoryList,
  SubCategoryList,
  QuestionsEditorWrap,
} from './styled'
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
} from 'react-sortable-hoc'
import arrayMove from 'array-move'

/**
 * Table collumns
 */
export const tableCategoryCollumns = [
  { k: '_id', l: '_id' },
  { k: 'image', l: 'Imagem Categoria', t: 'upload' },
  { k: 'logo', l: 'Logo Categoria', t: 'upload' },
  { k: 'color', l: 'Cor Categoria', t: 'color' },
  { k: 'name', l: 'Name Categoria' },

  { k: 'status', l: 'Estado' },
  { k: 'percentage', l: 'Percentagem', t: 'number' },
]

const list = {
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      when: 'beforeChildren',
      ease: 'easeOut',
      duration: 0.5,
      staggerChildren: 0.3,
    },
  },
  hidden: {
    opacity: 0,
    x: '-20vw',
    transition: {
      when: 'afterChildren',
    },
  },
}
const list2 = {
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      ease: 'easeOut',
      duration: 0.5,
      when: 'beforeChildren',
      staggerChildren: 0.3,
    },
  },
  hidden: {
    opacity: 0,
    x: '-10vw',
    transition: {
      when: 'afterChildren',
    },
  },
}
const item = {
  visible: { opacity: 1, x: 0 },
  hidden: { opacity: 0, x: -100 },
}

export default function QuestionsEditor(props) {
  let { clientId } = useParams()
  const [sectors, setSectors] = useGlobalState('sectors')
  const [clients, setClients] = useGlobalState('clients')
  const [questions, setQuestions] = useGlobalState('questions')
  const [categories, setCategories] = useGlobalState('categories')

  const [openModal, setOpenModal] = useState(false)
  const [openInitiatives, setOpenInitiatives] = useState(false)
  const [openTargets, setOpenTargets] = useState(false)
  const [openSectors, setOpenSectors] = useState(false)
  const [categoryId, setCategoryId] = useState(undefined)
  const [subCategoryId, setSubCategoryId] = useState(undefined)
  const [sector, setSector] = useState(false)
  const [client, setClient] = useState(clientId || false)
  const [loadedFromSector, setLoadedFromSector] = useState(false)
  const [customizeAction, setCustomizeAction] = useState(false)
  const [deleteAllData, setDeleteAllData] = useState(false)
  const [importData, setImportData] = useState(false)
  const [importDataStatus, setImportDataStatus] = useState(false)

  setGlobalState('pageClass', 'questions')
  const toast = useToast()

  const loadSubcategories = (id) => {
    setQuestions([])
    setCategoryId(id)
  }
  const loadQuestions = (id) => {
    setSubCategoryId(id)
    setRemoteCall(
      'getQuestions',
      { category: id, client, sector },
      null,
      (cb) => {
        setQuestions(cb)
      }
    )
  }

  /**
   * Set Questions
   */
  const loadCategories = ({ client, sector }) => {
    setQuestions([])
    setRemoteCall(
      client ? 'getCategories' : 'getSectorCategories',
      { sector, client },
      null,
      (cb) => {
        setCategories(cb)
        const isLoadedFromSector = cb.find((cat) => cat.loadedFromSector)
        setLoadedFromSector(isLoadedFromSector ? true : false)
      }
    )
  }
  /**
   * Del Category
   */
  const delCategory = (category) => {
    setRemoteCall('delCategories', category, null, (cb) => {
      setCategories(cb)
      toast({
        title: 'Categoria Apagada',
        status: 'success',
        position: 'bottom',
      })
    })
  }
  /**
   * Import data to file
   */
  const importDataFromFile = (file) => {
    setRemoteCall('importDataTo', { file, client, sector }, null, (cb) => {
      if (cb) {
        setImportDataStatus(cb.status)
        setImportData(false)
        loadCategories({ client, sector })
        setImportDataStatus(null)
        toast({
          title: 'Perguntas Importadas',
          status: 'success',
          position: 'bottom',
        })
      } else {
        toast({
          title: 'Aconteceu um erro',
          status: 'warning',
          position: 'bottom',
        })
      }
    })
    // setRemoteCall(
    //   'importDataTo',
    //   {
    //     file: 'uploads/2021/1619034970559.xlsx',
    //     client: '60454fe569a153031bf96ba8',
    //     sector: false
    //   },
    //   null,
    //   cb => {
    //     setImportDataStatus(cb.status)
    //   }
    // )
  }
  /**
   * Del Question
   */
  const delQuestion = (question) => {
    setRemoteCall(
      'delQuestion',
      { question, categoryId: subCategoryId },
      null,
      (cb) => {
        setQuestions(cb)
        toast({
          title: 'Pergunta apagada',
          status: 'success',
          position: 'bottom',
        })
      }
    )
  }
  /**
   * Duplcate Category
   */
  const duplicatSectorToClient = () => {
    const thisClient = clients.find((c) => String(c._id) === client)
    const thisSector =
      thisClient && thisClient.sector ? thisClient.sector[0] : ''
    setRemoteCall(
      'setSectorToClient',
      { sector: thisSector, client },
      null,
      (cb) => {
        setLoadedFromSector(false)
        setCategories(cb)
        toast({
          title: 'Avaliação duplicada',
          status: 'success',
          position: 'bottom',
        })
      }
    )
  }
  /**
   * Del Template
   */
  const setDeleteTemplate = () => {
    const thisClient = clients.find((c) => String(c._id) === client)
    const thisSector =
      thisClient && thisClient.sector ? thisClient.sector[0] : ''
    setRemoteCall(
      'setDeleteClienOrSectorTemplate',
      { sector: thisSector || sector, client },
      null,
      (cb) => {
        setLoadedFromSector(true)
        setCategories(cb)
        toast({
          title: 'Avaliação de cliente apagada',
          status: 'success',
          position: 'bottom',
        })
      }
    )
  }

  /**
   * Set categories
   */
  const setRemoteCategories = (data, notice) => {
    let saveData = { ...data }
    let sectorChoice = sector

    // if (!sector) {
    //   sectorChoice = client.sector
    // }
    if (openModal === 'cat') {
      saveData = {
        ...data,
        date: new Date(),
        parent: null,
        // sector: sectorChoice,
        sector,
        client,
      }
    } else if (openModal === 'sub') {
      saveData = { ...data, parent: categoryId, sector, client }
    }

    setRemoteCall('setCategories', saveData, notice, (cb) => {
      setCategories(cb)
      // toast({
      //   title: 'Category updated',
      //   status: 'success',
      //   position: 'bottom'
      // })
    })
  }
  /**
   * Set Category Order
   */
  const setRemoteCategoryOrder = async (categories, parent) => {
    const categoriesOrder = categories.map((q, i) => {
      return { _id: q._id, order: i }
    })
    let data = {
      categories: categoriesOrder,
      category: parent ? null : categoryId,
    }
    if (client) {
      data.client = client
    } else if (sector) {
      data.sector = sector
    }
    await setRemoteCall('setCategoriesOrder', data, null, (cb) => {
      setCategories(cb)
      toast({
        title: 'Ordem atualizada',
        status: 'success',
        position: 'bottom',
      })
    })
    if (!parent) setCategories(categories)
  }

  useEffect(() => {
    if (clientId) {
      loadCategories({ client: clientId })
    }
  }, [clientId])

  /**
   * Sortable
   */
  const DragHandle = sortableHandle(() => <DragHandleIcon cursor={'move'} />)
  const SortableCategoryItem = SortableElement(
    ({ value, index, subCat, loadData }) => {
      return (
        <Board
          // onClick={() => loadData(value._id)}
          // whileHover={{ scale: 1.05 }}
          // whileTap={{ scale: 0.95 }}
          active={value._id === subCategoryId || value._id === categoryId}
        >
          <Flex
            cursor={'pointer'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box
              width={'20%'}
              fontSize={'.8em'}
              color={'primary.500'}
              onClick={() => loadData(value._id)}
            >
              {value.percentage || 0}%
            </Box>
            <Flex width={'80%'} justifyContent={'space-between'}>
              <Box
                width={'80%'}
                fontSize={'.8em'}
                onClick={() => loadData(value._id)}
              >
                {value.name}
              </Box>
              <Box
                color={'primary.500'}
                width={'10%'}
                fontSize={'.8em'}
                onClick={() => setOpenModal(value._id)}
              >
                {!loadedFromSector && <EditIcon />}
              </Box>
            </Flex>
          </Flex>
        </Board>
      )
    }
  )
  const SortableCategoryList = SortableContainer(
    ({ items, loadCategories }) => {
      return (
        <div>
          {items &&
            items.map((value, index) => (
              <SortableCategoryItem
                key={`itemC-${index}`}
                loadData={loadCategories}
                disabled={loadedFromSector}
                index={index}
                value={value}
              />
            ))}
        </div>
      )
    }
  )

  return (
    <QuestionsEditorWrap className={'questions'}>
      {categoryId !== undefined && (
        <SubCategoryList
          as={motion.div}
          initial="hidden"
          animate="visible"
          variants={list2}
        >
          <SortableCategoryList
            loadCategories={loadQuestions}
            items={
              categories && categories.filter((c) => c.parent === categoryId)
            }
            lockAxis={'y'}
            onSortEnd={({ oldIndex, newIndex }) => {
              if (oldIndex !== newIndex)
                setRemoteCategoryOrder(
                  arrayMove(
                    categories.filter((c) => c.parent === categoryId),
                    oldIndex,
                    newIndex
                  )
                )
            }}
          />
          {!loadedFromSector && (
            <Button
              as={motion.div}
              variants={item}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              colorScheme="white"
              variant={'outline'}
              size="lg"
              value="Submit!"
              width={'100%'}
              _hover={{
                background: 'secondary.500',
              }}
              onClick={() => setOpenModal('sub')}
            >
              Adicionar
            </Button>
          )}
        </SubCategoryList>
      )}
      <CategoryList
        as={motion.div}
        initial="hidden"
        animate="visible"
        variants={list}
      >
        <Flex
          height={'100%'}
          flexDirection={'column'}
          alignContent={'space-between'}
        >
          <Box>
            <Box
              as={motion.div}
              variants={item}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Select
                height="3em"
                margin="1em 0"
                background={`${colors.primary.transparent[80]} !important`}
                color={'#fff!important'}
                placeholder="Editar Template"
                value={sector}
                onChange={(event) => {
                  setSector(event.target.value)
                  setClient(false)
                  loadCategories({ sector: event.target.value })
                }}
              >
                {sectors.map(
                  (t, i) =>
                    t.status === 1 && (
                      <option value={t._id} key={i}>
                        {t.name}
                      </option>
                    )
                )}
              </Select>
            </Box>

            {categories && (
              <SortableCategoryList
                loadCategories={loadSubcategories}
                items={categories.filter(
                  (c) =>
                    !c.parent && (c.client === client || c.sector === sector)
                )}
                lockAxis={'y'}
                onSortEnd={({ oldIndex, newIndex }) => {
                  if (oldIndex !== newIndex)
                    setRemoteCategoryOrder(
                      arrayMove(
                        categories.filter(
                          (c) =>
                            !c.parent &&
                            (c.client === client || c.sector === sector)
                        ),
                        oldIndex,
                        newIndex
                      )
                    )
                }}
              />
            )}

            {(client || sector) && !loadedFromSector && (
              <Button
                as={motion.div}
                variants={item}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                colorScheme="white"
                variant="outline"
                size="lg"
                value="Submit!"
                width={'100%'}
                _hover={{
                  background: 'primary.500',
                }}
                onClick={() => setOpenModal('cat')}
              >
                Adicionar
              </Button>
            )}

            {loadedFromSector && (
              <div>
                <Button
                  as={motion.div}
                  variants={item}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  colorScheme="white"
                  variant="outline"
                  size="lg"
                  value="Submit!"
                  width={'100%'}
                  _hover={{
                    background: 'primary.500',
                  }}
                  onClick={() => setCustomizeAction(true)}
                >
                  Alterar
                </Button>
                {customizeAction && (
                  <Dialog
                    title={'Quer fazer alterações a este tempalte?'}
                    close={setCustomizeAction}
                    content={
                      'Se você continuar com esta cópia, todas as outras alterações no template de setor não serão sincronizadas com a nova cópia'
                    }
                    cb={(a) => duplicatSectorToClient()}
                  />
                )}
              </div>
            )}
            {(client || sector) && !loadedFromSector && (
              <div>
                <Button
                  as={motion.div}
                  variants={item}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  margin={'1em 0'}
                  colorScheme="white"
                  variant="link"
                  size="lg"
                  value="Submit!"
                  width={'100%'}
                  _hover={{
                    background: 'primary.500',
                  }}
                  onClick={() => setDeleteAllData(true)}
                >
                  Apagar dados
                </Button>
                {deleteAllData && (
                  <Dialog
                    title={'Quer apagar este template?'}
                    close={setDeleteAllData}
                    content={
                      'Se você continuar, todos os dados serão excluídos para este modelo '
                    }
                    cb={(a) => setDeleteTemplate()}
                  />
                )}
              </div>
            )}
            {(client || sector) && (
              <div>
                <Button
                  as={motion.div}
                  variants={item}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  margin={'1em 0'}
                  colorScheme="white"
                  variant="link"
                  size="lg"
                  value="Submit!"
                  width={'100%'}
                  _hover={{
                    background: 'primary.500',
                  }}
                  onClick={() => setImportData(true)}
                >
                  Importar dados
                </Button>
                {importData && (
                  <Dialog
                    title={'Quer importar os dados de um ficheiro excel?'}
                    close={() => {
                      setImportData(false)
                      setImportDataStatus(null)
                    }}
                    content={
                      <>
                        <p>
                          Se você continuar todos os dados serão inseridos no
                          template
                        </p>
                        <br />
                        {importDataStatus === 'processing' && (
                          <Progress size="xs" isIndeterminate />
                        )}
                        {(!importDataStatus || importDataStatus === 'NOK') && (
                          <>
                            <Upload
                              fullCallback={(data) => {
                                if (data) {
                                  setImportDataStatus('processing')
                                  importDataFromFile(data?.msg?.details?.path)
                                }
                              }}
                            />
                            <br />
                            Download de template{' '}
                            <strong>
                              <a
                                href={'/images/ImportTemplate.xlsx'}
                                target={'_blank'}
                              >
                                aqui
                              </a>
                            </strong>
                          </>
                        )}
                        {importDataStatus === 'OK' && 'Update complete'}
                      </>
                    }
                    cb={undefined}
                  />
                )}
              </div>
            )}
          </Box>
          <Flex flexDirection={'column'} alignContent={'space-between'}>
            <Button
              as={motion.div}
              variants={item}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              margin={' 0'}
              colorScheme="white"
              variant="outline"
              size="lg"
              value="Submit!"
              width={'100%'}
              onClick={() => setOpenSectors(true)}
            >
              Produtos
            </Button>
            <Button
              as={motion.div}
              variants={item}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              margin={'1em 0 0 0'}
              colorScheme="white"
              variant="outline"
              size="lg"
              value="Submit!"
              width={'100%'}
              onClick={() => setOpenTargets(true)}
            >
              SDG
            </Button>
            <Button
              as={motion.div}
              variants={item}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              margin={'1em 0'}
              colorScheme="white"
              variant="outline"
              size="lg"
              value="Submit!"
              width={'100%'}
              onClick={() => setOpenInitiatives(true)}
            >
              Iniciativas
            </Button>
            {/* <Button
              as={motion.div}
              variants={item}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              colorScheme="secondary"
              size="lg"
              value="Submit!"
              width={'100%'}
              margin={'1em 0'}
              _hover={{
                background: 'primary.500'
              }}
              onClick={() => setOpenModal('true')}
              // onClick={() => setOpenModal(true)}
            >
              Save To Sector
            </Button> */}
          </Flex>
        </Flex>
      </CategoryList>

      {categoryId !== undefined && subCategoryId !== undefined && (
        <Questions
          questions={questions}
          subCategoryId={subCategoryId}
          categoryId={categoryId}
          client={client}
          sector={sector}
          loadedFromSector={loadedFromSector}
          delQuestion={delQuestion}
        />
      )}
      {openModal && (
        <EditCategory
          data={categories.find((q) => q._id === openModal)}
          form={tableCategoryCollumns}
          set={setRemoteCategories}
          setOpenModal={setOpenModal}
          delCategory={delCategory}
        />
      )}
      {openInitiatives && <EditInitiatives setOpenModal={setOpenInitiatives} />}
      {openTargets && <EditTargets setOpenModal={setOpenTargets} />}
      {openSectors && <EditSectors setOpenModal={setOpenSectors} />}
    </QuestionsEditorWrap>
  )
}
