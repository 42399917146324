import React, { useState } from 'react'
import './Login.css'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import {
  Box,
  Button,
  Heading,
  Flex,
  Input,
  Link,
  useToast,
} from '@chakra-ui/react'
import { setGlobalState, setStorageState } from '../store'
import { colors } from '../theme'
function Login(props) {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const toast = useToast()
  setGlobalState('pageClass', 'login')

  const resetPassword = async (e) => {
    if (!username || username === '') {
      toast({
        title: 'Por favor introduza o email',
        status: 'warning',
        position: 'bottom-left',
      })
    }

    await axios.post(`/auth/forgot`, {
      email: username,
    })
    toast({
      title: 'Verifique a sua caixa de correio',
      status: 'warning',
      position: 'bottom-left',
    })
  }

  const loginUser = async (e) => {
    e.preventDefault()

    if (username === '' || password === '') {
      return toast({
        title: 'Por favor introduza o email e password',
        status: 'warning',
        position: 'bottom-left',
      })
    }

    try {
      const response = await axios.post(`/auth`, {
        email: username,
        password,
      })
      if (response.data && response.data.token) {
        await setStorageState('loggedIn', response.data)
        props.history.push('/dashboard')
      }
    } catch (e) {
      console.log(e)
      setStorageState('loggedIn', null)
      toast({
        title: 'Verifique os seus dados',
        status: 'warning',
        position: 'bottom-left',
      })
    }
  }

  return (
    <Flex
      align="center"
      alignItems="center"
      justifyContent={['start', 'start', 'center', 'center', 'center']}
      flexDirection="column"
      height="100vh"
      backgroundImage={`linear-gradient(
        to right,
        ${colors.primary.transparent[80]},
        ${colors.primary.transparent[80]}
      ),
      url(/images/home.jpg)`}
      backgroundSize={'cover'}
      backgroundPosition={'center'}
      backgroundRepeat={'no-repeat'}
    >
      <Box
        className={'loginwrap'}
        backgroundColor={colors.primary[50]}
        width={['100%', '100%', '40%', '40%', '40%']}
        minHeight={['50vh', '50vh', '100vh', '100vh', '100vh']}
        padding={'2em'}
        shadow="md"
        borderWidth="0"
        rounded="lg"
        position={'absolute'}
        bottom={['0', '0', '0', '0', '0']}
        left="0"
      >
        <div className={'login-container'}>
          <div>
            <Box
              backgroundImage={'url(/images/Logotiop.png)'}
              backgroundSize={'contain'}
              backgroundPosition={'center'}
              backgroundRepeat={'no-repeat'}
              height={['8em', '8em', '10em', '10em', '10em']}
              margin={['1em 0', '2em 0', '3em 0', '3em 0', '3em 0']}
            />
            <form className="login-form" onSubmit={loginUser} data-login-form>
              <Input
                data-login-email
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Email"
                colorScheme="primary"
                size="lg"
              />
              <Input
                type="password"
                data-login-password
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Palavar-passe"
                colorScheme="primary"
                size="lg"
              />

              <Button
                onClick={(e) => loginUser(e)}
                colorScheme="primary"
                size="lg"
                value="Submit!"
                width={'100%'}
                _hover={{
                  background: 'secondary.500',
                }}
              >
                Login
              </Button>
            </form>
            <Flex
              alignItems={'center'}
              justifyContent={'center'}
              alignContent={'center'}
            >
              <Link
                href={'/register'}
                fontSize={'.8em'}
                margin={'1em'}
                color={'primary.500'}
                textAlign={'center'}
              >
                Registrar
              </Link>
              <Link
                href={'#'}
                fontSize={'.8em'}
                margin={'1em'}
                color={'primary.500'}
                textAlign={'center'}
                onClick={() => resetPassword()}
              >
                Reset Password
              </Link>
            </Flex>
          </div>
        </div>
      </Box>

      <Box
        marginLeft={['0%', '0%', '45%', '45%', '45%']}
        marginTop={['20vh', '25vh', '0%', '0%', '0%']}
        width={['80%', '80%', '55%', '55%', '55%']}
      >
        <Heading
          as="h3"
          fontSize={['0.9rem', '1.0rem', '1.2rem', '1.5rem', '1.5rem']}
          color={'white'}
        >
          Avaliação de Evento Sustentável
        </Heading>
        <Heading
          as="h1"
          fontSize={['2.8rem', '4rem', '5rem', '6rem', '6rem']}
          color={'white'}
        >
          Planeta, Pessoas, Cultura
        </Heading>
      </Box>
    </Flex>
  )
}

export default withRouter(Login)
