import React, { useEffect, useState } from 'react'
import { withRouter, useParams } from 'react-router-dom'
import moment from 'moment'
import Header from '../components/header'
import Edit from './Edit'
import { Flex, Box, Heading, Input } from '@chakra-ui/react'
import { useGlobalState, setGlobalState } from '../store'
import { setRemoteCall } from '../remote'
import TableProvider from '../components/Table/Table'
/**
 * Table collumns
 */
export const tableCollumns = [
  { k: '_id', l: '_id' },
  { k: 'client', l: 'Cliente' },
  { k: 'category', l: 'Categoria' },
  { k: 'mainCategory', l: 'Categoria Principal' },
  { k: 'question', l: 'Pergunta' },
  { k: 'date', l: 'Data' },
  { k: 'comment', l: 'Comentário' },
  { k: 'score', l: 'Pontuação' },
  { k: 'value', l: 'Valor' },
  { k: 'assessment', l: 'Avaliação' },
]

function Reports(props) {
  let { clientId, dateStartURL, dateEndURL } = useParams()
  let startDate = moment().subtract(1, 'months').format('YYYY-MM-DD')
  if (dateStartURL) {
    startDate = moment(dateStartURL, 'DD-MM-YYYY').format('YYYY-MM-DD')
  }
  let endDate = moment().format('YYYY-MM-DD')
  if (dateEndURL) {
    endDate = moment(dateEndURL, 'DD-MM-YYYY').format('YYYY-MM-DD')
  }
  /**
   * Get Data
   */
  const [openModal, setOpenModal] = useState(false)
  const [data, setData] = useState(false)
  const [types, setTypes] = useGlobalState('userTypes')
  const [dateStart, setDateStart] = useState(startDate)
  const [dateEnd, setDateEnd] = useState(endDate)
  //set the initical class name
  setGlobalState('pageClass', 'reports')

  const collumns = tableCollumns

  /**
   * get table data
   */
  const getReports = () => {
    setRemoteCall('getReports', { dateStart, dateEnd, clientId }, '', (cb) =>
      setData(cb)
    )
  }

  useEffect(() => {
    getReports()
  }, [dateStart, dateEnd])

  return (
    <div className={'reports table-wrapper'}>
      <Header image={'/images/dash.png'} height={30}>
        <Flex
          width={'100%'}
          justifyContent={'space-between'}
          flexDirection={['column', 'column', 'row', 'row', 'row']}
        >
          <div>
            <Heading as="h1" size="3xl" color={'#fff'}>
              Relatórios
            </Heading>
            <Heading as="h3" size="md" color={'#fff'}></Heading>
          </div>
          <Flex
            width={['100%', '100%', '40%', '40%', '40%']}
            justifyContent={'space-between'}
          >
            <Input
              marginRight={'1em'}
              type="date"
              value={dateStart}
              onChange={(e) => setDateStart(e.target.value)}
            />
            <Input
              type="date"
              value={dateEnd}
              onChange={(e) => setDateEnd(e.target.value)}
            />
          </Flex>
        </Flex>
      </Header>
      <Box className={'content'} padding={'0 1em'} marginTop={'-6vh'}>
        {data && (
          <div className={'contentTableMap'}>
            <div className={'tableWrapper'}>
              <TableProvider
                data={data}
                columns={collumns.map((key) => {
                  if (key.k === 'status') {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value) => {
                          switch (Number(value)) {
                            case 0:
                              return 'Desativo'
                            case 1:
                              return 'Ativo'
                            case 2:
                              return 'Indisponível'
                          }
                        },
                      },
                    }
                  }
                  if (key.k === 'date') {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                          return moment(value).format('LLLL')
                        },
                      },
                    }
                  }
                  if (key.k === 'role') {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                          if (types && value) {
                            const thisType = types.find((t) =>
                              value.includes(t._id)
                            )
                            return thisType && thisType.name
                          }
                          return ''
                        },
                      },
                    }
                  }
                  return {
                    name: key.k,
                    label: key.l,
                    selector: key.k,
                    options: {
                      filter: true,
                      sort: true,
                      display:
                        key.k !== '_id' &&
                        key.k !== 'availability' &&
                        key.k !== 'location' &&
                        key.k !== '__v',
                    },
                  }
                })}
                height={55}
                onRowClicked={(row, b) => {
                  setOpenModal(row)
                }}
                options={{
                  selectableRows: 'none',
                  fixedSelectColumn: false,
                  filterType: 'dropdown',
                  responsive: 'stacked',
                }}
              />
            </div>
          </div>
        )}
        {openModal && (
          <Edit
            data={openModal}
            form={collumns}
            types={types}
            setOpenModal={setOpenModal}
          />
        )}
      </Box>
    </div>
  )
}

export default withRouter(Reports)
