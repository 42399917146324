import React, { useState } from 'react'
import {
  Button,
  Select,
  Box,
  InputLeftAddon,
  InputGroup,
  Input,
  Textarea,
  Text,
} from '@chakra-ui/react'

import { CUIAutoComplete } from 'chakra-ui-autocomplete'
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'
import { useGlobalState } from '../store'
import Upload from '../components/Upload'
import moment from 'moment'
import ClientHistory from './ClientHistory'
import Sectors from './Sectors'

export default function Edit(props) {
  const [details, setDetails] = useState(props.data || {})
  const [sectors, setSectors] = useGlobalState('sectors')

  const [view, setView] = useState('info')
  // const projects = getGlobalState('projects')
  // const relations = getGlobalState('relations')

  let thisProject = {}

  const [pickerItems, setPickerItems] = useState([])

  let selectSector = sectors
    .filter((s) => details.sector && details.sector.includes(s._id))
    .map((s) => {
      return { _id: s._id, value: s._name, label: s.name }
    })
  const [selectedItems, setSelectedItems] = useState(selectSector || [])
  const handleFormFieldChange = (key, { target: { value } }) => {
    setDetails({ ...details, [key]: value })
  }
  const handleCreateItem = (item) => {
    const newItem = { ...item, new: true }
    setPickerItems((curr) => [...curr, newItem])
    setSelectedItems((curr) => [...curr, newItem])
    setDetails({ ...details, sectors: [...(details.sectors || []), newItem] })
  }

  const handleSelectedItemsChange = (selectedItems) => {
    if (selectedItems && selectedItems.length > 0) {
      // const selectedItem = [selectedItems[selectedItems.length - 1 || 0]] || []
      setSelectedItems(selectedItems)
      setDetails({ ...details, sectors: selectedItems })
    } else {
      setSelectedItems([])
      setDetails({ ...details, sectors: [] })
    }
  }
  return (
    <Drawer
      isOpen={true}
      placement="right"
      isFullHeight={true}
      size={'md'}
      onClose={() => props.setOpenModal()}
      // finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">
          Editar {details ? details.firstName : ''} details{' '}
          <Button
            size={'sm'}
            mr={3}
            variant={view === 'info' ? 'solid' : 'outline'}
            onClick={() => setView('info')}
          >
            Informação
          </Button>
          <Button
            size={'sm'}
            variant={view === 'products' ? 'solid' : 'outline'}
            mr={3}
            onClick={() => {
              setView('products')
            }}
          >
            Produtos
          </Button>
          <Button
            size={'sm'}
            variant={view === 'history' ? 'solid' : 'outline'}
            mr={3}
            onClick={() => {
              setView('history')
            }}
          >
            Histórico
          </Button>
        </DrawerHeader>

        <DrawerBody overflow={'auto'}>
          {props.form &&
            view === 'info' &&
            props.form.map((k, i) => {
              if (k.k === '_id') return null
              if (k.k === 'role' && props.types)
                return (
                  <InputGroup key={i} margin={'.5em 0'} size="sm">
                    <InputLeftAddon
                      minWidth={'120px'}
                      textAlign={'center'}
                      padding={'1.5em .5em'}
                      children={k.l}
                      fontWeight={'bold'}
                    />
                    <Select
                      height="3em"
                      onChange={(event) => handleFormFieldChange(k.k, event)}
                      placeholder="Selecionar opção"
                      value={details[k.k]}
                    >
                      {props.types.map(
                        (t) =>
                          t.status === 1 && (
                            <option value={t._id}> {t.name}</option>
                          )
                      )}
                    </Select>
                  </InputGroup>
                )
              if (k.k === 'admin')
                return (
                  <InputGroup key={i} margin={'.5em 0'} size="sm">
                    <InputLeftAddon
                      minWidth={'120px'}
                      textAlign={'center'}
                      padding={'1.5em .5em'}
                      children={k.l}
                      fontWeight={'bold'}
                    />
                    <Select
                      height="3em"
                      onChange={(event) => handleFormFieldChange(k.k, event)}
                      placeholder="Selecionar opção"
                      value={details[k.k]}
                    >
                      <option value={1}> Ativo </option>
                      <option value={0}> Inativo </option>
                    </Select>
                  </InputGroup>
                )
              if (k.k === 'theme')
                return (
                  <>
                    <p>Esquema de cores</p>
                    <InputGroup key={i} margin={'.5em 0'} size="sm">
                      <Input
                        size="lg"
                        key={i}
                        onChange={(event) =>
                          handleFormFieldChange('theme', {
                            target: {
                              value: {
                                ...details[k.k],
                                primary: event.target.value,
                              },
                            },
                          })
                        }
                        type={'color'}
                        height={'2rem'}
                        marginRight={'.5em'}
                        placeholder={k.l}
                        value={details[k.k]?.primary}
                      />
                      {/* <Input
                        size="lg"
                        key={i}
                        onChange={event =>
                          handleFormFieldChange('theme', {
                            target: {
                              value: {
                                ...details[k.k],
                                secondary: event.target.value
                              }
                            }
                          })
                        }
                        type={'color'}
                        height={'2rem'}
                        placeholder={k.l}
                        value={details[k.k]?.secondary}
                      /> */}
                    </InputGroup>
                  </>
                )
              if (k.k === 'status')
                return (
                  <InputGroup key={i} margin={'.5em 0'} size="sm">
                    <Select
                      height="3em"
                      onChange={(event) => handleFormFieldChange(k.k, event)}
                      placeholder="Selecionar estado"
                      value={details[k.k]}
                    >
                      <option value={0}> Inativo </option>
                      <option value={1}> Ativo </option>
                    </Select>
                  </InputGroup>
                )
              if (k.t === 'textarea') {
                return (
                  <InputGroup key={i} margin={'.5em 0'} size="sm">
                    <Textarea
                      padding={'1.5em .5em'}
                      onChange={(event) => handleFormFieldChange(k.k, event)}
                      placeholder={k.l}
                      type={k.t}
                      value={details[k.k]}
                    />
                  </InputGroup>
                )
              }
              if (k.t === 'dateArray') {
                return (
                  <Text key={i} fontSize={'.8em'} fontWeight={'bold'}>
                    Indisponibilidade
                  </Text>
                )
              }
              if (k.t === 'sectors') {
                return null
                //   return (
                //     <CUIAutoComplete
                //       label="Choose Client Products"
                //       placeholder="Type a Product"
                //       onCreateItem={handleCreateItem}
                //       items={sectors.map(s => {
                //         return {
                //           label: s.name || '',
                //           value: s.name || '',
                //           _id: s._id
                //         }
                //       })}
                //       tagStyleProps={{
                //         rounded: 'full',
                //         pt: 1,
                //         pb: 2,
                //         px: 2,
                //         fontSize: '1rem'
                //       }}
                //       selectedItems={selectedItems}
                //       onSelectedItemsChange={changes =>
                //         handleSelectedItemsChange(changes.selectedItems)
                //       }
                //     />
                //   )
              }
              if (k.t === 'upload') {
                return (
                  <Box margin={'2em 0'}>
                    {details[k.k] && (
                      <img
                        style={{
                          width: '100%',
                          height: '20vh',
                          objectFit: 'contain',
                        }}
                        src={details[k.k]}
                      />
                    )}
                    <Upload
                      key={i}
                      callback={(files) =>
                        handleFormFieldChange(k.k, { target: { value: files } })
                      }
                    />
                  </Box>
                )
              }
              if (k.k === 'date') {
                return (
                  <Input
                    size="lg"
                    key={i}
                    onChange={(event) => handleFormFieldChange(k.k, event)}
                    type={k.t || 'text'}
                    placeholder={k.l}
                    value={moment(details[k.k]).format('YYYY-MM-DD')}
                  />
                )
              }

              return (
                <Input
                  size="lg"
                  key={i}
                  onChange={(event) => handleFormFieldChange(k.k, event)}
                  type={k.t || 'text'}
                  placeholder={k.l}
                  value={details[k.k]}
                />
              )
            })}

          {view === 'products' && <Sectors client={details._id} />}
          {view === 'history' && (
            <div>
              <ClientHistory
                assessments={props.assessments}
                updateAssessments={props.updateAssessments}
                client={details._id}
              />
            </div>
          )}
        </DrawerBody>

        <DrawerFooter borderTopWidth="1px">
          <Button
            variant="outline"
            colorScheme="primary"
            mr={3}
            onClick={() => props.setOpenModal()}
          >
            Cancelar
          </Button>
          <Button
            colorScheme="primary"
            onClick={() => {
              props.set({ ...details }, ` atualizar cliente`, 'clients')
              props.setOpenModal()
            }}
          >
            Salvar
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
