import React, { useEffect, useState } from 'react'
import {
  HistoryCard,
  ClientMetrics,
  Line,
  ClientMetricsHistory,
  ClientTitleHistory,
  AssessmentDetails,
  ClientMetricsOutline,
  ClientInitiativesOutline,
} from './styled'
import { BsCardChecklist, BsUnlock } from 'react-icons/bs'
import { VscRepoPush, VscRepo } from 'react-icons/vsc'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { setRemoteCall } from '../remote'
import { useGlobalState } from '../store'
import Dialog from '../components/Dialog/Dialog'

import { Button, Box, Flex, Icon } from '@chakra-ui/react'
import moment from 'moment'
import { Link } from 'react-router-dom'
const AssessmentHistory = ({
  assessments,
  client,
  updateAssessments,
  onClick,
  selectedAss,
  limitWith,
  noDelete,
}) => {
  const [selected, setSelected] = useState(selectedAss || false)
  const [categories, setCategories] = useState([])
  const [deleteAction, setDeleteAction] = useState(false)
  const [deleted, setDeleted] = useState([])
  const [initiatives, setInitiatives] = useGlobalState('initiatives')
  const [sectors, setSectors] = useGlobalState('sectors')

  /**
   * Use effects
   */
  useEffect(() => {
    setRemoteCall('getCategories', { client }, null, (ass) => {
      setCategories(ass)
    })
  }, [])

  const setOpenAssessment = (id) => {
    setRemoteCall(
      'setOpenAssessment',
      { id },
      'Assessment is now Activated',
      (ass) => {
        console.log(ass)
        if (window.location) window.location.reload() // updateAssessments &&
        //   updateAssessments(
        //     assessments.forEach((assessment) => {
        //       if (assessment._id === id) {
        //         assessment.status = 1
        //       }
        //     })
        //   )
        // setDeleted([...deleted, id])
      }
    )
  }

  const deleteAssessment = (id) => {
    setRemoteCall('delAssessment', { id }, null, (ass) => {
      updateAssessments && updateAssessments(ass)
      // setDeleted([...deleted, id])
    })
  }
  /**
   * Calculate total assessmentScore
   *
   */
  const getTotalScore = (assessment) => {
    let totalScore = 0
    if (!assessment) return null

    assessment &&
      assessment.length > 0 &&
      assessment[0].result &&
      Object.keys(assessment[0].result).map((r, i) => {
        totalScore += assessment[0].result[r].assessmentScore
      })

    return (totalScore || 0).toFixed(1)
  }

  return (
    <Flex
      width={'100%'}
      flexDirection={['column', 'column', 'column', 'row', 'row']}
      flexWrap={'wrap'}
    >
      {assessments &&
        assessments
          // .filter(a => !deleted.includes(a._id))
          .map((assessment, a) => {
            const thisSector = sectors.find((s) => s._id === assessment.sector)
            return (
              <>
                <HistoryCard
                  key={a}
                  limitWith={limitWith}
                  active={selectedAss === assessment._id}
                  onClick={() => {
                    if (onClick) {
                      onClick(assessment._id)
                    } else {
                      setSelected(selected === a ? false : a)
                    }
                  }}
                >
                  <Flex
                    width={'100%'}
                    alignItems={'center'}
                    flexWrap={'wrap'}
                    justifyContent={'space-between'}
                  >
                    <Box width={'30%'} textAlign={'center'}>
                      <ClientMetricsHistory>
                        {getTotalScore([assessment])}
                        <Box
                          fontSize={'.6em'}
                          fontWeight={'bold!important'}
                          display={'inline-block'}
                        >
                          %
                        </Box>
                      </ClientMetricsHistory>
                    </Box>
                    <Box width={'70%'}>
                      <ClientTitleHistory>
                        <strong>
                          {assessment.name
                            ? assessment.name
                            : thisSector && thisSector.name}
                        </strong>
                      </ClientTitleHistory>
                      {/* <Line>
                        <span>Status:</span>{' '}
                        {assessment.status ? 'Active' : 'Finished'}
                      </Line> */}
                      {/* <Line>
                        <span>Start End:</span>{' '}
                        {assessment.dateEnd
                          ? moment(assessment.dateEnd).format(
                              'DD MM YYYY hh:mm:ss'
                            )
                          : 'ongoing'}
                      </Line> */}
                      <Line>
                        <span>Início:</span>{' '}
                        {moment(assessment.dateStart).format(
                          'DD MM YYYY hh:mm:ss'
                        )}
                      </Line>
                      <Flex flexWrap={'wrap'} mt={'.5em'}>
                        {!noDelete && (
                          <>
                            {' '}
                            <Button
                              colorScheme={'secondary'}
                              size="sm"
                              fontSize={'.7em'}
                              marginRight={'.2em'}
                              onClick={() => setDeleteAction(assessment._id)}
                              value="Submit!"
                              _hover={{
                                background: 'primary.500',
                              }}
                            >
                              <RiDeleteBin6Line />
                            </Button>
                            {deleteAction &&
                              assessment._id === deleteAction && (
                                <Dialog
                                  title={'Quer apagar esta avalição?'}
                                  close={setDeleteAction}
                                  content={
                                    'Se proceder com esta ação todas as respostas serão removidas'
                                  }
                                  cb={(a) => deleteAssessment(assessment._id)}
                                />
                              )}
                          </>
                        )}
                        {assessment.status !== 1 && (
                          <Button
                            colorScheme={'primary'}
                            size="sm"
                            fontSize={'.7em'}
                            marginRight={'.2em'}
                            value="Submit!"
                            _hover={{
                              background: 'primary.500',
                            }}
                            onClick={() => setOpenAssessment(assessment._id)}
                          >
                            <BsUnlock />
                          </Button>
                        )}
                        {assessment.status ? (
                          <Link
                            to={`/assessment/${assessment.sector}/${assessment.client}`}
                          >
                            <Button
                              colorScheme={
                                assessment.status ? 'secondary' : 'primary'
                              }
                              size="sm"
                              marginRight={'.2em'}
                              fontSize={'.7em'}
                              value="Submit!"
                              _hover={{
                                background: 'secondary.500',
                              }}
                            >
                              <>
                                <Icon
                                  as={VscRepoPush}
                                  color={'white'}
                                  height={'1.5em'}
                                  width={'1.5em'}
                                  marginRight={'.2em'}
                                />
                                Concluir
                              </>
                              {/* // <>
                            //   <Icon
                            //     as={VscRepo}
                            //     color={'white'}
                            //     height={'1.5em'}
                            //     width={'1.5em'}
                            //     marginRight={'.2em'}
                            //   />{' '}
                            //   Start
                            // </> */}
                            </Button>
                          </Link>
                        ) : (
                          ''
                        )}{' '}
                        <Link
                          to={`/reports/${assessment.client}/${moment(
                            assessment.dateStart
                          ).format('DD-MM-YYYY')}/${
                            assessment.dateEnd
                              ? moment(assessment.dateEnd).format('DD-MM-YYYY')
                              : ''
                          }`}
                        >
                          <Button
                            colorScheme={'primary'}
                            size="sm"
                            fontSize={'.7em'}
                            marginRight={'.2em'}
                            value="Submit!"
                            _hover={{
                              background: 'primary.500',
                            }}
                          >
                            <BsCardChecklist />
                          </Button>
                        </Link>
                      </Flex>
                    </Box>
                  </Flex>
                </HistoryCard>
                {selected === a && (
                  <AssessmentDetails>
                    {assessment.result &&
                      Object.keys(assessment.result).map((r, i) => {
                        const thisResult = assessment.result[r]
                        const thisCat = categories.find((cat) => cat._id === r)
                        return (
                          <>
                            <ClientMetrics key={i}>
                              <Flex
                                justifyContent={'space-between'}
                                fontSize={'1.2em'}
                                padding={'.5em 0'}
                              >
                                <div>
                                  {(thisResult.assessmentScore || 0).toFixed(2)}
                                  %{' - '} {thisCat && thisCat.name}
                                </div>
                                <div>
                                  Perguntas: {thisResult.questions} Respostas:{' '}
                                  {thisResult.answers}
                                </div>
                              </Flex>
                            </ClientMetrics>
                            {thisResult.subCats &&
                              Object.keys(thisResult.subCats).map((subCat) => {
                                const thisSubCat = thisResult.subCats[subCat]
                                const thisSubCatDetails = categories.find(
                                  (cat) => cat._id === subCat
                                )
                                const subCatinitiatives = []
                                if (!thisSubCat) return null
                                return (
                                  <>
                                    <ClientMetricsOutline>
                                      <Flex
                                        justifyContent={'space-between'}
                                        fontSize={'1em'}
                                        padding={'.2em 0'}
                                      >
                                        <div>
                                          {(
                                            thisSubCat.assessmentScore || 0
                                          ).toFixed(2)}
                                          %{' - '}
                                          {thisSubCatDetails &&
                                            thisSubCatDetails.name}
                                        </div>
                                        <div>
                                          Perguntas: {thisSubCat.questions}{' '}
                                          Respostas: {thisSubCat.answers}
                                        </div>
                                      </Flex>
                                    </ClientMetricsOutline>

                                    {/* {!thisSubCat.initiatives ||
                                      (thisSubCat.initiatives &&
                                        thisSubCat.initiatives.length < 1 && (
                                          <ClientInitiativesOutline>
                                            No Initiatives yet{' '}
                                          </ClientInitiativesOutline>
                                        ))} */}
                                    {thisSubCat.initiatives &&
                                      thisSubCat.initiatives.length > 0 &&
                                      thisSubCat.initiatives.map((init) => {
                                        const foundInit = initiatives.find(
                                          (i) => i._id === init
                                        )

                                        //lets not display duplicates
                                        if (
                                          subCatinitiatives.includes(foundInit)
                                        )
                                          return null
                                        subCatinitiatives.push(foundInit)
                                        if (foundInit)
                                          return (
                                            <ClientInitiativesOutline>
                                              {foundInit.name}
                                            </ClientInitiativesOutline>
                                          )
                                      })}
                                  </>
                                )
                              })}
                          </>
                        )
                      })}
                  </AssessmentDetails>
                )}
              </>
            )
          })}
    </Flex>
  )
}

export default AssessmentHistory
