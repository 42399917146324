import React, { useState } from 'react'
import {
  Button,
  Select,
  Box,
  InputLeftAddon,
  InputGroup,
  Input,
  Textarea,
  Text,
  InputRightElement,
  InputLeftElement,
} from '@chakra-ui/react'
import { CUIAutoComplete } from 'chakra-ui-autocomplete'
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
} from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { DragHandleIcon } from '@chakra-ui/icons'
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'
import { useGlobalState } from '../store'
import Upload from '../components/Upload'

const DragHandle = sortableHandle(() => (
  <DragHandleIcon marginTop={'.7rem'} marginLeft={'.5rem'} cursor={'move'} />
))

const SortableItem = SortableElement(({ range, value, i, setRange }) => {
  return (
    <InputGroup size="lg" zIndex={'9999!important'}>
      <InputLeftElement width="3rem">
        <DragHandle />
      </InputLeftElement>
      <Input
        onChange={(e) =>
          setRange(
            range.map((rElement, ri) => {
              return ri === i ? e.target.value : rElement
            })
          )
        }
        value={value}
      />
      <InputRightElement width="5rem">
        <Button
          size="sm"
          colorScheme="primary"
          variant="outline"
          marginTop={'.7rem'}
          onClick={() => {
            setRange(range.filter((rRemove, x) => i !== x))
          }}
        >
          -
        </Button>
      </InputRightElement>
    </InputGroup>
  )
})

const SortableList = SortableContainer(({ items, setRange }) => {
  return (
    <div>
      {items &&
        items.map((value, index) => (
          <SortableItem
            key={`item-${value}-${index}`}
            index={index}
            i={index}
            value={value}
            range={items}
            setRange={setRange}
          />
        ))}
    </div>
  )
})

export default function EditCategory(props) {
  const [details, setDetails] = useState(props.data || { order: props.order })
  const [newRangeText, setNewRangeText] = useState('')
  const [range, setRange] = useState(props.data ? props.data.range : [])
  const [initiatives, setInitiatives] = useGlobalState('initiatives')
  const [targets, setTargets] = useGlobalState('targets')

  const [view, setView] = useState('info')
  // const projects = getGlobalState('projects')
  // const relations = getGlobalState('relations')

  const [pickerItems, setPickerItems] = useState([])
  const [pickerTargetsItems, setPickerTargetsItems] = useState([])

  let selectInitiatives = initiatives
    .filter((s) => details.initiatives && details.initiatives.includes(s._id))
    .map((s) => {
      return { _id: s._id, value: s._name, label: s.name }
    })
  let selectTargets = targets
    .filter((s) => details.targets && details.targets.includes(s._id))
    .map((s) => {
      return { _id: s._id, value: s._name, label: s.name }
    })
  const [selectedItems, setSelectedItems] = useState(selectInitiatives || [])
  const [selectedTargetsItems, setSelectedTargetsItems] = useState(
    selectTargets || []
  )
  const handleFormFieldChange = (key, { target: { value } }) => {
    setDetails({ ...details, [key]: value })
  }
  const handleFormFieldSourceChange = (key, { target: { value } }) => {
    setDetails({
      ...details,
      source: { ...(details.source || {}), [key]: value },
    })
  }
  const handleCreateItem = (item) => {
    const newItem = { ...item, new: true }
    setPickerItems((curr) => [...curr, newItem])
    setSelectedItems((curr) => [...curr, newItem])
    setDetails({
      ...details,
      initiatives: [...(details.initiatives || []), newItem],
    })
  }

  const handleSelectedItemsChange = (selectedItems) => {
    if (selectedItems && selectedItems.length > 0) {
      const selectedItem = selectedItems || []
      setSelectedItems(selectedItem)
      setDetails({ ...details, initiatives: selectedItem.map((i) => i._id) })
    } else {
      setSelectedItems([])
      setDetails({ ...details, initiatives: [] })
    }
  }

  const handleCreateTargetsItem = (item) => {
    const newItem = { ...item, new: true }
    setPickerTargetsItems((curr) => [...curr, newItem])
    setSelectedTargetsItems((curr) => [...curr, newItem])
    setDetails({
      ...details,
      targets: [...(details.targets || []), newItem],
    })
  }

  const handleSelectedTargetsItemsChange = (selectedItems) => {
    if (selectedItems && selectedItems.length > 0) {
      const selectedItem = selectedItems || []
      setSelectedTargetsItems(selectedItem)
      setDetails({ ...details, targets: selectedItem.map((i) => i._id) })
    } else {
      setSelectedTargetsItems([])
      setDetails({ ...details, targets: [] })
    }
  }

  return (
    <Drawer
      isOpen={true}
      placement="right"
      isFullHeight={true}
      size={'md'}
      onClose={() => props.setOpenModal()}
      // finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">
          Editar Detalhes de Pergunta{' '}
        </DrawerHeader>

        <DrawerBody overflow={'auto'}>
          {props.form &&
            view === 'info' &&
            props.form.map((k, i) => {
              if (k.k === '_id') return null
              if (k.k === 'role' && props.types)
                return (
                  <InputGroup key={i} margin={'.5em 0'} size="sm">
                    <InputLeftAddon
                      minWidth={'120px'}
                      textAlign={'center'}
                      padding={'1.5em .5em'}
                      children={k.l}
                      fontWeight={'bold'}
                    />
                    <Select
                      height="3em"
                      onChange={(event) => handleFormFieldChange(k.k, event)}
                      placeholder="Selecionar opção"
                      value={details[k.k]}
                    >
                      {props.types.map(
                        (t) =>
                          t.status === 1 && (
                            <option value={t._id}> {t.name}</option>
                          )
                      )}
                    </Select>
                  </InputGroup>
                )

              if (k.k === 'range')
                return (
                  <div key={i}>
                    <Box
                      margin={'1.5em 1em 0'}
                      fontWeight={'bold'}
                      fontSize={'.9em'}
                      color={'primary.500'}
                      size="sm"
                    >
                      Alcance (valor mais baixo tem maior pontuação)
                    </Box>

                    <InputGroup size="lg">
                      <Input
                        type={'text'}
                        value={newRangeText}
                        onChange={(e) => setNewRangeText(e.target.value)}
                        placeholder="Nova opção"
                      />
                      <InputRightElement width="4.5rem">
                        <Button
                          size="sm"
                          colorScheme="primary"
                          marginTop={'.7rem'}
                          onClick={() => {
                            setRange([...(range || []), newRangeText])
                            setNewRangeText('')
                          }}
                        >
                          Adicionar +
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    <Box position={'relative'} minHeight={'15vh'}>
                      <SortableList
                        items={range}
                        setRange={setRange}
                        lockAxis={'y'}
                        onSortEnd={({ oldIndex, newIndex }) => {
                          if (oldIndex !== newIndex)
                            setRange(arrayMove(range, oldIndex, newIndex))
                        }}
                      />
                    </Box>
                  </div>
                )
              if (k.k === 'status')
                return (
                  <InputGroup key={i} margin={'.5em 0'} size="sm">
                    <Select
                      height="3em"
                      onChange={(event) => handleFormFieldChange(k.k, event)}
                      placeholder="Select State"
                      value={details[k.k]}
                    >
                      <option value={0}> Inativo </option>
                      <option value={1}> Ativo </option>
                    </Select>
                  </InputGroup>
                )
              if (k.t === 'source') {
                return (
                  <div key={i}>
                    <Box
                      margin={'1.5em 1em 0'}
                      fontWeight={'bold'}
                      fontSize={'.9em'}
                      color={'primary.500'}
                      size="sm"
                    >
                      Fonte
                    </Box>
                    <InputGroup key={i} margin={'.5em 0'} size="sm">
                      <Input
                        size="lg"
                        key={i}
                        width={'46%'}
                        onChange={(event) =>
                          handleFormFieldSourceChange('name', event)
                        }
                        type={'text'}
                        placeholder={'Nome'}
                        value={details['source'] ? details['source'].name : ''}
                      />
                      <Input
                        size="lg"
                        key={i}
                        width={'47%'}
                        marginLeft={'5%'}
                        onChange={(event) =>
                          handleFormFieldSourceChange('id', event)
                        }
                        type={'text'}
                        placeholder={'ID'}
                        value={details['source'] ? details['source'].id : ''}
                      />
                    </InputGroup>
                    <Box
                      margin={'1.5em 1em 0'}
                      fontWeight={'bold'}
                      fontSize={'.9em'}
                      color={'primary.500'}
                      size="sm"
                    >
                      Level and target
                    </Box>
                    <InputGroup key={i} margin={'.5em 0'} size="sm">
                      <Input
                        size="lg"
                        key={i}
                        width={'46%'}
                        onChange={(event) =>
                          handleFormFieldChange('level', event)
                        }
                        type={'number'}
                        placeholder={'Nível'}
                        value={details['level']}
                      />
                      <Input
                        size="lg"
                        key={i}
                        width={'47%'}
                        marginLeft={'5%'}
                        onChange={(event) =>
                          handleFormFieldChange('target', event)
                        }
                        type={'text'}
                        placeholder={'Target, CEO, CFO...'}
                        value={details['target']}
                      />
                    </InputGroup>
                    <Checkbox
                      isChecked={details['noOptions']}
                      onChange={(event) =>
                        handleFormFieldChange('noOptions', {
                          target: { value: event.target.checked },
                        })
                      }
                    >
                      Apenas possibilitar comentário
                    </Checkbox>
                  </div>
                )
              }
              if (k.t === 'textarea') {
                return (
                  <>
                    <Box
                      margin={'1.5em 1em 0 0'}
                      fontWeight={'bold'}
                      fontSize={'.9em'}
                      color={'primary.500'}
                      size="sm"
                    >
                      {k.l}
                    </Box>

                    <InputGroup key={i} margin={'.5em 0'} size="sm">
                      <Textarea
                        className={'questions'}
                        width={'100%'}
                        padding={'0em'}
                        margin={'1.5em 0'}
                        minHeight={'15vh'}
                        resize={'vertical'}
                        borderRadius={'0em!important'}
                        background={'transparent!important'}
                        boxShadow={'none'}
                        onChange={(event) => handleFormFieldChange(k.k, event)}
                        placeholder={k.l}
                        fontSize={'1.4em'}
                        type={k.t}
                        value={details[k.k]}
                      />
                    </InputGroup>
                  </>
                )
              }
              if (k.t === 'dateArray') {
                return (
                  <Text key={i} fontSize={'.8em'} fontWeight={'bold'}>
                    Indisponibilidade
                  </Text>
                )
              }
              if (k.t === 'targets') {
                return (
                  <CUIAutoComplete
                    label="Escolher uma SDG"
                    placeholder="Tipo de SDG"
                    onCreateItem={handleCreateTargetsItem}
                    items={targets.map((s) => {
                      return {
                        label: s.name || '',
                        value: s.name || '',
                        _id: s._id,
                      }
                    })}
                    inputStyleProps={{
                      height: '3em',
                      margin: 0,
                    }}
                    tagStyleProps={{
                      rounded: 'full',
                      pt: 1,
                      pb: 2,
                      px: 2,
                      fontSize: '.8rem',
                      marginTop: '.5em',
                      color: 'white',
                      bg: 'primary.500',
                    }}
                    selectedItems={selectedTargetsItems}
                    onSelectedItemsChange={(changes) =>
                      handleSelectedTargetsItemsChange(changes.selectedItems)
                    }
                  />
                )
              }
              if (k.t === 'initiatives') {
                return (
                  <CUIAutoComplete
                    label="Escolher uma iniciativa"
                    placeholder="Tipo de iniciativa"
                    onCreateItem={handleCreateItem}
                    items={initiatives.map((s) => {
                      return {
                        label: s.name || '',
                        value: s.name || '',
                        _id: s._id,
                      }
                    })}
                    inputStyleProps={{
                      height: '3em',
                      margin: 0,
                    }}
                    tagStyleProps={{
                      rounded: 'full',
                      pt: 1,
                      pb: 2,
                      px: 2,
                      fontSize: '.8rem',
                      marginTop: '.5em',
                      color: 'white',
                      bg: 'primary.500',
                    }}
                    selectedItems={selectedItems}
                    onSelectedItemsChange={(changes) =>
                      handleSelectedItemsChange(changes.selectedItems)
                    }
                  />
                )
              }
              if (k.t === 'upload') {
                return (
                  <Box margin={'2em 0'}>
                    {details[k.k] && (
                      <img
                        style={{
                          width: '100%',
                          height: '20vh',
                          objectFit: 'cover',
                        }}
                        src={details[k.k]}
                      />
                    )}
                    <Upload
                      key={i}
                      callback={(files) =>
                        handleFormFieldChange(k.k, { target: { value: files } })
                      }
                    />
                  </Box>
                )
              }

              return (
                <Input
                  size="lg"
                  key={i}
                  onChange={(event) => handleFormFieldChange(k.k, event)}
                  type={k.t || 'text'}
                  placeholder={k.l}
                  value={details[k.k]}
                />
              )
            })}
        </DrawerBody>

        <DrawerFooter borderTopWidth="1px">
          <Button
            variant="text"
            colorScheme="primary"
            mr={3}
            onClick={() => props.delQuestion(details._id)}
          >
            Apagar
          </Button>
          <Button
            variant="outline"
            colorScheme="primary"
            mr={3}
            onClick={() => props.setOpenModal()}
          >
            Cancelar
          </Button>
          <Button
            colorScheme="primary"
            onClick={() => {
              props.set(
                { ...details, range },
                ` Atualizar pergunta`,
                'questions'
              )
              props.setOpenModal()
            }}
          >
            Salvar
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
