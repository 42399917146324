import React, { useEffect, useState } from 'react'
import {
  Button,
  Select,
  InputLeftAddon,
  InputGroup,
  Input,
  Textarea,
  Text,
} from '@chakra-ui/react'
import { setRemoteCall } from '../remote'

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'
import { useGlobalState } from '../store'

/**
 * Table collumns
 */
export const tableCollumns = [
  { k: '_id', l: '_id' },
  { k: 'firstName', l: 'Primeiro nome' },
  { k: 'lastName', l: 'Último nome' },
  { k: 'email', l: 'Email' },
  { k: 'password', l: 'Palavra-passe', t: 'password' },
  // { k: 'location', l: 'Localização', t: 'map' }
  // { k: 'attr', l: 'Atributos' },
]

export default function Edit(props) {
  const [loggedIn, setLoggedIn] = useGlobalState('loggedIn') //dont remove
  const [userDetails, setUserDetails] = useGlobalState('editProfile') //dont remove
  const [details, setDetails] = useState({})

  const [view, setView] = useState('info')
  // const projects = getGlobalState('projects')
  // const relations = getGlobalState('relations')

  const handleFormFieldChange = (key, { target: { value } }) => {
    setDetails({ ...details, [key]: value })
  }

  /**
   * Use effects
   */
  const setUser = () => {
    setRemoteCall('setUser', details, null, (data) => {
      setDetails(data)
    })
  }

  /**
   * Use effects
   */
  useEffect(() => {
    if (loggedIn && userDetails) {
      setRemoteCall('getUser', {}, null, (data) => {
        setDetails(data)
      })
    }
  }, [userDetails])

  if (!details) return null

  return (
    <Drawer
      isOpen={userDetails}
      placement="right"
      isFullHeight={true}
      size={'md'}
      onClose={() => setUserDetails(false)}
      // finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">
          Editar {details ? details.firstName : ''} detalhes{' '}
        </DrawerHeader>

        <DrawerBody overflow={'auto'}>
          {tableCollumns &&
            view === 'info' &&
            tableCollumns.map((k, i) => {
              if (k.k === '_id') return null
              if (k.k === 'role' && props.types)
                return (
                  <InputGroup key={i} margin={'1em 0'} size="sm">
                    <InputLeftAddon
                      minWidth={'120px'}
                      textAlign={'center'}
                      padding={'1.5em .5em'}
                      children={k.l}
                      fontWeight={'bold'}
                    />
                    <Select
                      height="3em"
                      onChange={(event) => handleFormFieldChange(k.k, event)}
                      placeholder="Escolher opção"
                      value={details[k.k]}
                    >
                      {props.types.map(
                        (t) =>
                          t.status === 1 && (
                            <option value={t._id}> {t.name}</option>
                          )
                      )}
                    </Select>
                  </InputGroup>
                )
              if (k.k === 'admin')
                return (
                  <InputGroup key={i} margin={'1em 0'} size="sm">
                    <Select
                      height="3em"
                      onChange={(event) => handleFormFieldChange(k.k, event)}
                      placeholder="Admin"
                      value={details[k.k]}
                    >
                      <option value={1}> Ativo </option>
                      <option value={0}> Inativo </option>
                    </Select>
                  </InputGroup>
                )
              if (k.k === 'status')
                return (
                  <InputGroup key={i} margin={'1em 0'} size="sm">
                    <Select
                      onChange={(event) => handleFormFieldChange(k.k, event)}
                      placeholder="Estado"
                      value={details[k.k]}
                    >
                      <option value={0}> Inativo </option>
                      <option value={1}> Ativo </option>
                    </Select>
                  </InputGroup>
                )

              if (k.t === 'textarea') {
                return (
                  <InputGroup key={i} margin={'1em 0'} size="sm">
                    <Textarea
                      padding={'1.5em .5em'}
                      onChange={(event) => handleFormFieldChange(k.k, event)}
                      placeholder={k.l}
                      type={k.t}
                      value={details[k.k]}
                    />
                  </InputGroup>
                )
              }
              if (k.t === 'dateArray') {
                return (
                  <>
                    <Text fontSize={'.8em'} fontWeight={'bold'}>
                      Indisponibilidade
                    </Text>
                  </>
                )
              }

              return (
                <Input
                  size="lg"
                  onChange={(event) => handleFormFieldChange(k.k, event)}
                  type={k.t || 'text'}
                  placeholder={k.l}
                  value={details[k.k]}
                />
              )
            })}
        </DrawerBody>

        <DrawerFooter borderTopWidth="1px">
          <Button
            variant="outline"
            colorScheme="primary"
            mr={3}
            onClick={() => setUserDetails(false)}
          >
            Cancelar
          </Button>
          <Button
            colorScheme="primary"
            onClick={() => {
              setUser({ ...details }, ` atualizar utilizador`, 'users')
              setUserDetails(false)
            }}
          >
            Salvar
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
