import React, { useState } from 'react'
import {
  Button,
  Select,
  Box,
  InputLeftAddon,
  InputGroup,
  Input,
  Textarea,
  Text,
} from '@chakra-ui/react'
import { CUIAutoComplete } from 'chakra-ui-autocomplete'

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'
import { useGlobalState } from '../store'
import Upload from '../components/Upload'

export default function EditInitiatives(props) {
  const [details, setDetails] = useState(props.data || {})
  const [sectors, setSectors] = useGlobalState('sectors')

  const [view, setView] = useState('info')
  // const projects = getGlobalState('projects')
  // const relations = getGlobalState('relations')

  let thisProject = {}

  const [pickerItems, setPickerItems] = useState([])

  let selectSector = sectors
    .filter((s) => details.sector && details.sector.includes(s._id))
    .map((s) => {
      return { _id: s._id, value: s._name, label: s.name }
    })

  const [selectedItems, setSelectedItems] = useState(selectSector || [])
  const handleFormFieldChange = (key, { target: { value } }) => {
    setDetails({ ...details, [key]: value })
  }
  const handleCreateItem = (item) => {
    const newItem = { ...item, new: true }
    setPickerItems((curr) => [...curr, newItem])
    setSelectedItems((curr) => [...curr, newItem])
    setDetails({ ...details, sectors: [...(details.sectors || []), newItem] })
  }

  const handleSelectedItemsChange = (selectedItems) => {
    if (selectedItems && selectedItems.length > 0) {
      const selectedItem = [selectedItems[selectedItems.length - 1 || 0]] || []
      console.log(selectedItem)
      setSelectedItems(selectedItem)
      setDetails({ ...details, sectors: selectedItem })
    } else {
      setSelectedItems([])
      setDetails({ ...details, sectors: [] })
    }
  }
  return (
    <Drawer
      isOpen={true}
      placement="right"
      isFullHeight={true}
      size={'md'}
      onClose={() => props.setOpenModal()}
      // finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">
          Editar {details ? details.firstName : ''} detalhes{' '}
          <Button
            size={'sm'}
            mr={3}
            variant={view === 'info' ? 'solid' : 'outline'}
            onClick={() => setView('info')}
          >
            Informação
          </Button>
          <Button
            size={'sm'}
            variant={view === 'history' ? 'solid' : 'outline'}
            mr={3}
            onClick={() => {
              setView('history')
            }}
          >
            Histórico
          </Button>
        </DrawerHeader>

        <DrawerBody overflow={'auto'}>
          {props.form &&
            view === 'info' &&
            props.form.map((k, i) => {
              if (k.k === '_id') return null
              if (k.k === 'role' && props.types)
                return (
                  <InputGroup key={i} margin={'.5em 0'} size="sm">
                    <InputLeftAddon
                      minWidth={'120px'}
                      textAlign={'center'}
                      padding={'1.5em .5em'}
                      children={k.l}
                      fontWeight={'bold'}
                    />
                    <Select
                      height="3em"
                      onChange={(event) => handleFormFieldChange(k.k, event)}
                      placeholder="Selecionar opção"
                      value={details[k.k]}
                    >
                      {props.types.map(
                        (t) =>
                          t.status === 1 && (
                            <option key={t._id} value={t._id}>
                              {t.name}
                            </option>
                          )
                      )}
                    </Select>
                  </InputGroup>
                )
              if (k.k === 'admin')
                return (
                  <InputGroup key={i} margin={'.5em 0'} size="sm">
                    <InputLeftAddon
                      minWidth={'120px'}
                      textAlign={'center'}
                      padding={'1.5em .5em'}
                      children={k.l}
                      fontWeight={'bold'}
                    />
                    <Select
                      height="3em"
                      onChange={(event) => handleFormFieldChange(k.k, event)}
                      placeholder="Selecionar opção"
                      value={details[k.k]}
                    >
                      <option value={1}> Ativo </option>
                      <option value={0}> Inativo </option>
                    </Select>
                  </InputGroup>
                )
              if (k.k === 'status')
                return (
                  <InputGroup key={i} margin={'.5em 0'} size="sm">
                    <Select
                      height="3em"
                      onChange={(event) => handleFormFieldChange(k.k, event)}
                      placeholder="Select State"
                      value={details[k.k]}
                    >
                      <option value={0}> Inactivo </option>
                      <option value={1}> Ativo </option>
                    </Select>
                  </InputGroup>
                )
              if (k.t === 'textarea') {
                return (
                  <InputGroup key={i} margin={'.5em 0'} size="sm">
                    <Textarea
                      padding={'1.5em .5em'}
                      onChange={(event) => handleFormFieldChange(k.k, event)}
                      placeholder={k.l}
                      type={k.t}
                      value={details[k.k]}
                    />
                  </InputGroup>
                )
              }
              if (k.t === 'dateArray') {
                return (
                  <Text key={i} fontSize={'.8em'} fontWeight={'bold'}>
                    Indisponibilidade
                  </Text>
                )
              }
              if (k.t === 'sectors') {
                return (
                  <CUIAutoComplete
                    key={i}
                    label="Selecione um sector para"
                    placeholder="tipo de sector"
                    onCreateItem={handleCreateItem}
                    items={sectors.map((s) => {
                      return {
                        label: s.name || '',
                        value: s.name || '',
                        _id: s._id,
                      }
                    })}
                    tagStyleProps={{
                      rounded: 'full',
                      pt: 1,
                      pb: 2,
                      px: 2,
                      fontSize: '1rem',
                    }}
                    selectedItems={selectedItems}
                    onSelectedItemsChange={(changes) =>
                      handleSelectedItemsChange(changes.selectedItems)
                    }
                  />
                )
              }
              if (k.t === 'upload') {
                return (
                  <Box key={i} margin={'2em 0'}>
                    <h2>{k.k}</h2>
                    {details[k.k] && (
                      <img
                        style={{
                          width: '100%',
                          height: '20vh',
                          objectFit: 'cover',
                        }}
                        src={details[k.k]}
                      />
                    )}
                    <Upload
                      key={i}
                      callback={(files) =>
                        handleFormFieldChange(k.k, { target: { value: files } })
                      }
                    />
                  </Box>
                )
              }

              return (
                <Input
                  size="lg"
                  key={i}
                  onChange={(event) => handleFormFieldChange(k.k, event)}
                  type={k.t || 'text'}
                  placeholder={k.l}
                  value={details[k.k]}
                />
              )
            })}
        </DrawerBody>

        <DrawerFooter borderTopWidth="1px">
          <Button
            variant="text"
            colorScheme="primary"
            mr={3}
            onClick={() => props.delCategory(details._id)}
          >
            Apagar
          </Button>
          <Button
            variant="outline"
            colorScheme="primary"
            mr={3}
            onClick={() => props.setOpenModal()}
          >
            Cancelar
          </Button>
          <Button
            colorScheme="primary"
            onClick={() => {
              props.set({ ...details }, ` atualizar categoria`, 'category')
              props.setOpenModal()
            }}
          >
            Salvar
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
