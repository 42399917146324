import React, { useEffect, useState, useCallback } from 'react'
import Header from '../components/header'
import { Link } from 'react-router-dom'
import Pdf from 'react-to-pdf'
import { exportComponentAsJPEG } from 'react-component-export-image'
import { ClientTitleHistory, Line } from '../clients/styled'
import Dialog from '../components/Dialog/Dialog'

import {
  Heading,
  Box,
  Flex,
  Text,
  Button,
  Input,
  Select,
  ButtonGroup,
  Progress,
  Icon,
} from '@chakra-ui/react'
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  Bar,
  XAxis,
  YAxis,
} from 'recharts'
import moment from 'moment'
import { setRemoteCall } from '../remote'
import { colors } from '../theme'
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts'
import { setGlobalState, useGlobalState, getGlobalState } from '../store'
import {
  Board,
  ReportOutputBoard,
  Row,
  RowDashPage,
  DashFilter,
  Wrap,
  HistoryCard,
  NewAssessment,
} from './styled'
import AssessmentHistory from './AssessmentHistory'
import ClientHistory from '../clients/ClientHistory'
import { BiDownArrowCircle } from 'react-icons/bi'
import { VscRepo } from 'react-icons/vsc'
import Purchase from '../clients/Purchase'
import Sectors from '../clients/Sectors'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { SiWheniwork } from 'react-icons/si'

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  name,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)
  if (percent === 0) return null
  return (
    <text
      x={x}
      y={y}
      fill="white"
      font-size="14px"
      font-weight="bold"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {/* {name.substring(0, 10)} */}
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}
const catColor = [
  '#003B5B',
  '#1E78A8',
  '#EC1C2D',
  '#61B4E5',
  '#A9403B',
  '#AF96C7',
  '#8DC63F',
  '#F9B432',
  '#009CA7',
  '#60A944',
  '#FECE08',
  '#C49A6C',
  '#F26F22',
  '#324C8E',
]
const MAINCOLORS = [
  colors.primary[500],
  colors.primary[400],
  colors.primary[300],
  colors.primary[200],
  colors.primary[100],
]
const SUBCOLORS = [
  colors.secondary.pie[0],
  colors.secondary.pie[4],
  colors.secondary.pie[8],
  colors.secondary.pie[12],
  colors.secondary.pie[16],
  colors.secondary.pie[20],
]

export default function Dashboard(props) {
  setGlobalState('pageClass', 'dashboard')
  const [open, setOpen] = useState(false)
  const [loggedIn, setLoggedIn] = useGlobalState('loggedIn') //dont remove
  let userClient = null
  if (loggedIn && !loggedIn.admin) {
    userClient = loggedIn.role
  }
  const clients = getGlobalState('clients')
  const sectors = getGlobalState('sectors')
  const purchases = getGlobalState('purchases')
  const [startDate, setStartDate] = useState(
    moment().startOf('year').format('YYYY-MM-DD')
  )
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
  const [client, setClient] = useState(userClient)
  const [sector, setSector] = useState(null)
  const [currCat, setCurrCat] = useState(0)
  const [data, setData] = useState([])
  const [mainCats, setMainCats] = useState([])
  const [dataPie, setDataPie] = useState(null)
  const [dataPieSub, setDataPieSub] = useState(null)
  const [radarData, setRadarData] = useState(null)
  const [barData, setBarData] = useState(null)
  const [dataInitiatives, setDataInitiatives] = useState(null)
  const [currentAssessment, setCurrentAssessment] = useState(null)
  const [dataTargets, setDataTargets] = useState(null)
  const [totalsData, setTotalsData] = useState(null)

  const [assessmentsOverview, setAssessmentsOverview] = useState(null)
  const [documentSize, setDocumentSize] = useState({
    height: 595.28,
    width: 841.89,
  })
  const [printing, setPrinting] = useState(false)
  // const getDocumentSize = toPdf => {
  //   if (document) {
  //     const fullHeight = Math.max(
  //       document.body.scrollHeight,
  //       document.documentElement.scrollHeight,
  //       document.body.offsetHeight,
  //       document.documentElement.offsetHeight,
  //       document.body.clientHeight,
  //       document.documentElement.clientHeight
  //     )

  //     const fullWidth = Math.max(
  //       document.body.scrollWidth,
  //       document.documentElement.scrollWidth,
  //       document.body.offsetWidth,
  //       document.documentElement.offsetWidth,
  //       document.body.clientWidth,
  //       document.documentElement.clientWidth
  //     )
  //     setPrinting(true)
  //     setDocumentSize({
  //       height: fullHeight,
  //       width: fullWidth
  //     })
  //   }
  //   setTimeout(() => {
  //     toPdf()
  //     setPrinting(false)
  //   }, 0)
  // }

  /**
   * get table data
   */
  const getReports = () => {
    setRemoteCall(
      'getAssessmentsDashboard',
      { startDate, endDate, client, sector },
      '',
      (cb) => {
        setData(cb)
        mainCatData(cb)
        initativesData(cb)
        targetsData(cb)
        subCatData(cb)
        workRadarData(cb)
        // workBarData(cb)
        workTotals(cb)
        if (client) {
          setAssessmentsOverview(
            cb && cb.assessments && cb.assessments[0] && cb.assessments[0]._id
          )
          setCurrentAssessment(cb && cb.assessments && cb.assessments[0])
        } else {
          setCurrentAssessment(null)
        }
      }
    )
  }
  //every time we change data lets fetch
  useEffect(() => {
    getReports()
  }, [startDate, endDate, client, sector])

  useEffect(() => {
    getReports()
  }, [])

  const mainCatData = (data) => {
    if (data && data.mainCat) {
      let dataPieTemp = []
      let dataMainCat = []
      Object.keys(data.mainCat).map((cat, i) => {
        const thisCat = data.mainCat[cat]
        dataMainCat.push(cat)
        dataPieTemp.push({
          name: `${cat}`,
          color: `${
            thisCat.color && thisCat.color !== 'undifined'
              ? thisCat.color
              : catColor[i]
          }`,
          // name: `${cat} (${(thisCat.score || 0).toFixed(2)}%)`,
          value: Number((thisCat.assessmentScore || 0).toFixed(2)),
          totalScore: thisCat.score,
        })
      })

      setDataPie(dataPieTemp)
      setMainCats(dataMainCat)
    } else if (data && data.result) {
      let dataPieTemp = []
      let dataMainCat = []

      Object.keys(data.result).map((cat, i) => {
        const thisCat = data.result[cat]
        dataMainCat.push(thisCat.name)
        dataPieTemp.push({
          name: `${thisCat.name}`,
          color: `${
            thisCat.color && thisCat.color !== 'undifined'
              ? thisCat.color
              : catColor[i]
          }`,
          value: Number((thisCat.assessmentScore || 0).toFixed(2)),
          totalScore: thisCat.score,
        })
      })
      setDataPie(dataPieTemp)
      setMainCats(dataMainCat)
    }
  }
  const subCatData = (data) => {
    if (data && data.subCat) {
      let dataPieTemp = []

      Object.keys(data.subCat).map((cat) => {
        const thisCat = data.subCat[cat]
        dataPieTemp.push({
          name: `${cat} (${(thisCat.score || 0).toFixed(2)}%)`,
          value: Number((thisCat.assessmentScore || 0).toFixed(2)),
          totalScore: thisCat.score,
        })
      })
      setDataPieSub(dataPieTemp.sort((a, b) => b.value - a.value).slice(0, 5))
    } else if (data && data.result) {
      let dataPieTemp = []

      Object.keys(data.result).map((cat) => {
        Object.keys(data.result[cat].subCats).map((subCat) => {
          const thisCat = data.result[cat].subCats[subCat]
          dataPieTemp.push({
            name: `${thisCat.name} (${(thisCat.score || 0).toFixed(2)}%)`,
            value: Number((thisCat.assessmentScore || 0).toFixed(2)),
            totalScore: thisCat.score,
          })
        })
      })
      setDataPieSub(dataPieTemp.sort((a, b) => b.value - a.value).slice(0, 5))
    }
  }
  const initativesData = (data) => {
    if (data && data.initiatives) {
      let dataInit = []

      Object.keys(data.initiatives).map((init) => {
        const thisInit = data.initiatives[init]
        dataInit.push({ name: init, value: Number(thisInit) })
      })
      setDataInitiatives(dataInit.sort((a, b) => b.value - a.value).slice(0, 5))
    }
  }
  const targetsData = (data) => {
    if (data && data.targets) {
      let dataTarget = []
      Object.keys(data.targets).map((target) => {
        const thisTarget = data.targets[target]
        dataTarget.push({ name: target, value: Number(thisTarget) })
      })
      setDataTargets(dataTarget.sort((a, b) => b.value - a.value).slice(0, 5))
    }
  }
  const workTotals = (data) => {
    if (data && data.totals) {
      let dataInit = [
        { name: 'pontuação', value: Number(data.totals) },
        { name: 'falta', value: Number(100 - data.totals) },
      ]

      setTotalsData(dataInit)
    }
  }
  const workRadarData = (data, i) => {
    if (data && data.subCat) {
      let dataInit = []
      const parent = Object.keys(data.mainCat)[i || 0]
      Object.keys(data.subCat).map((sub) => {
        const thisInit = data.subCat[sub]
        if (parent && parent !== thisInit.parent) return null
        dataInit.push({
          subject: sub,
          // A: Math.floor(Math.random() * 100) + 1,
          A: Number(thisInit.assessmentScore || 0).toFixed(2),
          fullMark: (thisInit.score || 0).toFixed(2),
          totalScore: (thisInit.score || 0).toFixed(2),
        })
      })
      setRadarData(dataInit)
    }
  }
  const RenderResults = useCallback(() => {
    let newAssessments = []
    return (
      <div>
        <ClientHistory
          limitWith={true}
          assessments={data.assessments}
          client={client}
          selectedAss={currentAssessment && currentAssessment._id}
          noDelete={true}
          onClick={(a) => {
            const thisAssessment = data.assessments.find((ass) => ass._id === a)
            if (currentAssessment && a == currentAssessment._id) {
              setCurrentAssessment(null)
              mainCatData(data)
              subCatData(data)
            } else {
              setCurrentAssessment(thisAssessment)
              mainCatData(thisAssessment)
              subCatData(thisAssessment)
            }
          }}
        />
        {newAssessments}
        <Flex>
          <Sectors
            client={client}
            assessments={data.assessments}
            onlyActive={true}
          />
        </Flex>
        <Box onClick={() => setOpen(true)}>
          <NewAssessment>
            <AiOutlinePlusCircle size={30} /> Adicionar Avaliação
          </NewAssessment>
        </Box>
        {open && (
          <Dialog
            title={''}
            close={setOpen}
            content={<Sectors client={client} />}
            // cb={a => console.log(a)}
          />
        )}
      </div>
    )
  }, [data, open, client, currentAssessment])
  const RenderIncomplete = useCallback(() => {
    return (
      <>
        {data.activeAssessments &&
          data.activeAssessments.slice(0, 4).map((ass, i) => {
            const thisClient =
              clients && clients.find((c) => c._id === ass.client)
            let thisSector = {}
            if (!thisClient) return null
            if (thisClient) {
              thisSector =
                (sectors && sectors.find((c) => thisClient.sector === c._id)) ||
                {}
            } else {
              return null
            }
            if (!thisSector) return null
            const currDate = moment()
            const dateToTest = moment(ass.dateStart)
            return (
              <Board key={i}>
                <Flex justifyContent={'space-between'} alignItems={'center'}>
                  <Box fontSize={'1em'}>
                    <strong>{currDate.diff(dateToTest, 'days')} dias</strong>
                    {' | '} {ass.name}
                  </Box>
                  <Box>
                    <Button
                      colorScheme={'primary'}
                      size="sm"
                      onClick={() => {}}
                    >
                      <Link to={`/assessment/${ass.sector}/${thisClient._id}`}>
                        Completar
                      </Link>
                    </Button>
                  </Box>
                </Flex>
              </Board>
            )
          })}
      </>
    )
  }, [data])
  const ref = React.useRef(null)
  return (
    <Wrap className={`dashboard ${printing ? 'printing' : ''}`} ref={ref}>
      <Header image={'/images/home.jpg'} height={100}>
        <Box marginTop={'10vh'}>
          <Heading as="h1" size="xl" color={'#fff'}>
            Avaliação de Evento Sustentável{' '}
            <span style={{ display: 'inline-block' }}>
              <button
                onClick={() => {
                  const thisRef = ref
                  setPrinting(true)
                  setTimeout(() => {
                    ref && exportComponentAsJPEG(thisRef)
                    setPrinting(false)
                  }, 1000)
                }}
              >
                <BiDownArrowCircle color={'#fff'} size={'.6em'} />
              </button>
            </span>
          </Heading>
          {!userClient && (
            <DashFilter>
              <Flex
                alignContent={'space-between'}
                flexDirection={['column', 'row', 'row', 'row', 'row']}
                width={'100%'}
              >
                <Input
                  type="date"
                  background={'transparent!important'}
                  color={'white!important'}
                  margin={'0 .3em'}
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
                <Input
                  type="date"
                  background={'transparent!important'}
                  color={'white!important'}
                  margin={'0 .3em'}
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />

                <Select
                  onChange={(event) => {
                    setSector('')
                    setClient(event.target.value)
                  }}
                  placeholder="Cliente"
                  background={'transparent!important'}
                  color={'white!important'}
                  height={'2.5rem'}
                  margin={'0 .3em'}
                  value={client}
                >
                  {clients &&
                    clients.map(
                      (t) =>
                        t.status === 1 && (
                          <option key={t._id} value={t._id}>
                            {' '}
                            {t.name}
                          </option>
                        )
                    )}
                </Select>
                <Select
                  onChange={(event) => {
                    setClient('')
                    setSector(event.target.value)
                  }}
                  placeholder="Produto"
                  background={'transparent!important'}
                  color={'white!important'}
                  height={'2.5rem'}
                  margin={'0 .3em'}
                  value={sector}
                >
                  {sectors &&
                    sectors.map(
                      (t) =>
                        t.status === 1 && (
                          <option key={t._id} value={t._id}>
                            {' '}
                            {t.name}
                          </option>
                        )
                    )}
                </Select>
              </Flex>
            </DashFilter>
          )}
        </Box>
        <Flex
          width={'100%'}
          flexDirection={['column', 'column', 'column', 'row', 'row']}
          justifyContent={'space-between'}
          marginBottom={'1em'}
        >
          <Box
            width={!client ? ['100%', '100%', '100%', '70%', '70%'] : '100%'}
            margin={['3vh 0', '3vh 0', 0, 0, 0]}
          >
            <Box fontSize={'.85em'}>
              {currentAssessment && (
                <AssessmentHistory
                  assessment={currentAssessment}
                  noTitle={true}
                  cats={dataPie}
                  client={client}
                />
              )}
            </Box>
            {!currentAssessment && client && (
              <Flex
                padding={'3vh'}
                alignItems={'center'}
                height={['auto', 'auto', 'auto', '35vh', '35vh']}
              >
                <Heading
                  color={'#fff'}
                  fontSize={'4em'}
                  as={'h1'}
                  opacity={'.6'}
                >
                  Por favor seleciona uma avaliação
                </Heading>
              </Flex>
            )}
            {currentAssessment && !currentAssessment.result && client && (
              <Flex
                padding={'3vh'}
                alignItems={'center'}
                height={['auto', 'auto', 'auto', '35vh', '35vh']}
              >
                <Heading
                  color={'#fff'}
                  fontSize={'4em'}
                  as={'h1'}
                  opacity={'.6'}
                >
                  Sem resultados para apresentar
                </Heading>
              </Flex>
            )}

            {!currentAssessment && !client && dataPie && dataPie.length > 0 && (
              <Box
                height={['auto', 'auto', 'auto', '65vh', '65vh']}
                padding={'5vh 0 0 0'}
                width={'100%'}
              >
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Tooltip
                      contentStyle={{
                        fontSize: '.8em',
                        border: '0',
                        color: colors.primary[500],
                        borderRadius: '.8em',
                      }}
                      wrapperStyle={{
                        boxShadow: `0 2px 9px 0 ${colors.primary.transparent[20]}`,
                      }}
                    />
                    <Pie
                      data={dataPie}
                      isAnimationActive={false}
                      cx="50%"
                      cy="50%"
                      innerRadius={'60%'}
                      outerRadius={'100%'}
                      labelLine={false}
                      paddingAngle={5}
                      label={renderCustomizedLabel}
                      dataKey="value"
                      strokeWidth={2}
                    >
                      {dataPie.map((entry, index) => {
                        if (entry && entry.value === 0) return 0
                        return (
                          <Cell
                            key={`cell-${index}`}
                            // fill={MAINCOLORS[index % MAINCOLORS.length]}
                            fill={
                              entry.color ||
                              `#${Math.floor(Math.random() * 16777215).toString(
                                16
                              )}`
                            }
                          />
                        )
                      })}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Box>
            )}
          </Box>
          {!client && (
            <Box
              // height={['auto', 'auto', '55vh', '55vh', '55vh']}
              width={['100%', '100%', '100%', '25%', '25%']}
              overflow={['visible', 'auto', 'auto', 'auto']}
            >
              <Box margin={'1em 0'} fontSize={'.8em'}>
                <Text margin={'0 0 1em 0'} fontSize={'1em'} color={'white'}>
                  <strong>Avaliações por concluir</strong>
                </Text>
              </Box>
              <Box margin={'1em 0'} fontSize={'.8em'}>
                <RenderIncomplete />
              </Box>
            </Box>
          )}
        </Flex>
        <Box width={'100%'}>
          {client && (
            <Flex
              flexDirection={['column', 'column', 'row', 'row', 'row']}
              marginTop={'1em'}
              width={'99%'}
            >
              <Box width={['100%', '100%', '15%', '15%', '15%']}>
                <Text
                  textAlign={'center'}
                  margin={'0 0 1em 0'}
                  fontSize={'1em'}
                  color={'white'}
                >
                  Pontuação:{' '}
                  <strong>{data.totals && data.totals.toFixed(2)}%</strong>
                </Text>
              </Box>
              <Progress
                width={['100%', '100%', '85%', '85%', '85%']}
                borderRadius={'1em'}
                colorScheme="green"
                height="20px"
                value={data.totals}
              />
            </Flex>
          )}
        </Box>
        <Box width={'100%'} margin={['0', '0', '2vh 0', '2vh 0', '2vh 0']}>
          {client && <RenderResults />}
        </Box>
      </Header>
    </Wrap>
  )
}
