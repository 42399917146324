import styled, { css } from 'styled-components'
import { colors } from '../theme'
import tinycolor from 'tinycolor2'

export const Wrap = styled.div``
export const NewAssessment = styled.div`
  && {
    border-radius: 20px;
    border: 2px dotted #fff;
    padding: 1.5em;
    background-color: #fff4;
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    font-weight: bold;
    align-items: center;
    cursor: pointer;
  }
`
export const Board = styled.div`
  && {
    position: relative;
    width: 100%;
    border: none;
    background: #fff;
    padding: 1em;
    margin: 0 1em 1em 0;
    border-radius: 2em;
    box-shadow: 0 2px 9px 0 ${colors.primary.transparent[20]};
    background-color: #fff;
    ${(props) => props.height && `height:${props.height};`}
    ${(props) => props.width && `width:${props.width};`}
    @media screen and (max-width: 1200px) {
    }
    ${(props) =>
      props.percentage && !props.colorCat
        ? css`
            background-image: linear-gradient(
              120deg,
              ${colors.secondary.transparent[40]} 0%,
              ${colors.secondary.transparent[40]} ${props.percentage}%,
              rgba(0, 21, 25, 0) ${props.percentage}%
            );
          `
        : css``};
    ${(props) =>
      props.colorCat && props.percentage
        ? css`
            color: ${props.colorCat};
            background-image: linear-gradient(
              120deg,
              ${tinycolor(props.colorCat).lighten(40).toRgbString()} 0%,
              ${tinycolor(props.colorCat).lighten(40).toRgbString()}
                ${props.percentage}%,
              rgba(0, 21, 25, 0) ${props.percentage}%
            );
          `
        : css``};
  }
`
export const ReportOutputBoard = styled.div`
  && {
    width: 100%;
    border: none;
    background: #fff;
    padding: 0.8em;
    margin: 0 0 0.5em 0;
    font-size: 0.8em;
    border-radius: 2em;
    box-shadow: 0 2px 9px 0 ${colors.primary.transparent[20]};
    background-color: #fff;
    ${(props) => props.height && `height:${props.height};`}
    @media screen and (max-width: 1200px) {
    }
  }
`

export const Row = styled.div`
  && {
    display: flex;
    width: 100%;
    margin: 2em 0;
  }
`
export const RowDashPage = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    margin: -8vh 0 0 0;

    @media screen and (max-width: 768px) {
      margin: 0;
      flex-direction: column;
    }
  }
`
export const DashFilter = styled.div`
  && {
    font-size: 0.75em;
    input[type='date']::-webkit-calendar-picker-indicator {
      cursor: pointer;
      margin: 0;
      border-radius: 4px;
      margin-right: 2px;
      filter: invert(1);
    }
  }
`

/**
 * Assessment styles
 */

export const Line = styled.div`
  && {
    color: ${colors.secondary[500]};
    font-size: 0.8em;
    span {
      color: ${colors.primary[500]};
    }
  }
`
export const AssessmentDetails = styled.div`
  && {
    margin: 1em 0;
  }
`

export const ClientMetricsHistory = styled.div`
  && {
    display: block;
    color: ${colors.primary[500]};
    font-weight: bold;
    font-size: 1.6em;
    span {
      color: ${colors.primary[200]};
      font-weight: lighter;
    }
  }
`

export const ClientMetricsOutline = styled.div`
  && {
    display: block;
    border: 1px solid ${colors.primary[500]};
    background: transparent;
    padding: 0.3em 0.8em;
    margin: 0.2em;
    border-radius: 2em;
    color: ${colors.primary[500]};
    font-weight: bold;
    font-size: 0.8em;
    span {
      color: ${colors.primary[200]};
      font-weight: lighter;
    }
  }
`

export const ClientTargetOutline = styled.div`
  && {
    display: flex;
    align-items: center;
    border: 1px solid #569535;
    background: transparent;
    padding: 0.3em 0.8em;
    margin: 0.2em;
    border-radius: 2em;
    color: #569535;
    font-weight: bold;
    font-size: 0.8em;
    span {
      color: ${colors.primary[200]};
      font-weight: lighter;
    }
  }
`
export const ClientInitiativesOutline = styled.div`
  && {
    display: flex;
    align-items: center;
    border: 1px solid ${colors.secondary[500]};
    background: transparent;
    padding: 0.3em 0.8em;
    margin: 0.2em;
    border-radius: 2em;
    color: ${colors.secondary[500]};
    font-weight: bold;
    font-size: 0.8em;
    span {
      color: ${colors.primary[200]};
      font-weight: lighter;
    }
  }
`
export const ClientMetrics = styled.div`
  && {
    display: block;
    background: ${colors.primary[500]};
    padding: 0.3em 0.8em;
    margin: 0.2em;
    border-radius: 2em;
    color: ${colors.primary[100]};
    font-weight: bold;
    font-size: 0.8em;
    span {
      color: ${colors.primary[100]};
      font-weight: lighter;
    }
  }
`
export const HistoryCard = styled.div`
  && {
    cursor: pointer;
    width: 100%;
    border: none;
    text-align: left;
    background: #fff;
    padding: 1em;
    margin: 0 1% 1% 0;
    border-radius: 2em;
    /* min-height: 8em; */
    /* border: 1px solid ${colors.primary[200]}; */
    box-shadow: 0 2px 9px 0 ${colors.primary.transparent[20]};
  }
`
