import React, { useState, useEffect } from 'react'
// import DropIn from 'braintree-web-drop-in-react'
import { setRemoteCall } from '../remote'
import { Button, Icon, Flex, useToast } from '@chakra-ui/react'
import { ProductCard, ClientTitleHistory } from './styled'
import { getGlobalState, useGlobalState } from '../store'
import { VscRepoPush, VscRepo } from 'react-icons/vsc'
import Dialog from '../components/Dialog/Dialog'
import StripeCheckout from 'react-stripe-checkout'

let instance

export default function Purchase(props) {
  const [open, setOpen] = useState(true)
  const [sectors, setSectors] = useGlobalState('sectors')
  const [purchases, setPurchases] = useGlobalState('purchases')
  const loggedIn = getGlobalState('loggedIn') //dont remove
  const chosenSector = sectors.find((sect) => sect._id === props.sector)
  const [sector, setSector] = useState(chosenSector || 0)
  const [client, setClient] = useState(props.client || 0)
  // const [instance, setInstance] = useState(null)
  const paymentToken = getGlobalState('paymentToken')
  const toast = useToast()

  /**
   * Lets check if we have a subscription
   */
  // useEffect(() => {
  //   setRemoteCall(
  //     'getActivePurchases',
  //     {
  //       client: client,
  //     },
  //     null,
  //     (cb) => {
  //       if (cb) {
  //         const activePurchase = cb.filter(
  //           (purchase) =>
  //             purchase.sector === props.sector && purchase.status === 1
  //         )
  //         if (activePurchase && activePurchase.length > 0) {
  //           toast({
  //             title: 'Já tem uma subscrição ativa',
  //             status: 'warning',
  //             position: 'bottom',
  //           })
  //           setOpen(false)
  //         }
  //       }
  //     }
  //   )
  // }, [])

  const buy = async (data) => {
    try {
      // setRemoteCall(
      //   'setPurchase',
      //   {
      //     sector,
      //     client: client,
      //     date: new Date(),
      //     amount: chosenSector.price || 0,
      //     status: 0
      //   },
      //   null,
      //   ass => {}
      // )
      // const { nonce } = await instance.requestPaymentMethod()
      const nonce = data.id

      if (nonce) {
        setRemoteCall(
          'setPurchase',
          {
            sector: props.sector,
            client: props.client,
            date: new Date(),
            nonce,
            amount: props.price,
            status: 1,
          },
          null,
          (ass) => {}
        )
        setOpen(false)
        toast({
          title: 'Pagamento realizado com sucesso',
          status: 'success',
          position: 'bottom',
        })
      } else if (loggedIn && loggedIn.admin) {
        setRemoteCall(
          'setPurchase',
          {
            sector: props.sector,
            client: props.client,
            date: new Date(),
            nonce: 'admin',
            amount: 0,
            status: 1,
          },
          null,
          (ass) => {}
        )
        toast({
          title: 'Produto adicionado com sucesso',
          status: 'success',
          position: 'bottom',
        })
        setOpen(false)
      }
    } catch (error) {
      toast({
        title: error
          ? error.message || 'Aconteceu um erro'
          : 'Aconteceu um erro',
        status: 'error',
        position: 'bottom',
      })
      setRemoteCall(
        'setPurchase',
        {
          sector,
          client: client,
          date: new Date(),
          attr: { error: error.message || 'Aconteceu um erro' },
          amount: props.price,
          status: 2,
        },
        null,
        (ass) => {}
      )
      console.log(error)
    }

    // await fetch(`server.test/purchase/${nonce}`)
  }

  const PaymentOption = () => {
    if (loggedIn && loggedIn.admin) {
      return (
        <div>
          <Button
            colorScheme={'primary'}
            size="lg"
            display={'block'}
            width={'100%'}
            fontSize={'1em'}
            value="Submit!"
            _hover={{
              background: 'secondary.500',
            }}
            onClick={() => buy({})}
          >
            {props.children}
          </Button>
        </div>
      )
    } else {
      return (
        <div>
          <StripeCheckout
            name={props.name}
            description={props.description}
            amount={props.price} // cents
            billingAddress={true}
            token={(e) => buy(e)}
            currency="EUR"
            stripeKey="pk_live_BUrBzwbZXL69nVCr3uDs7bDO"
          >
            {/* <Button
              colorScheme={'primary'}
              size="lg"
              display={'block'}
              width={'100%'}
              fontSize={'1em'}
              value="Submit!"
              _hover={{
                background: 'secondary.500',
              }}
              // onClick={() => buy()}
            >
              Proceder, {chosenSector.price} Euros
            </Button> */}
            {props.children}
          </StripeCheckout>
        </div>
      )
    }
  }

  return <PaymentOption>{props.children} </PaymentOption>
  // return open ? (
  //   <Dialog
  //     title={''}
  //     close={setOpen}
  //     content={<PaymentOption />}
  //     // cb={a => console.log(a)}
  //   />
  // ) : null
}
