import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { ListItem } from '@material-ui/core'
import { colors } from '../../theme'
export const ListContainer = styled.ul`
  && {
    min-width: 80px;
    border: none;
  }
`

export const ItemNumb = styled.span`
  && {
    background: #fff;
    right: 10px;
    font-size: 0.5em !important;
    padding: 0.5em;
    border-radius: 50%;
    position: absolute;
    color: #2d2e3c !important;
    display: block !important;
    min-width: 1.3em;
  }
`

export const StyledFooter = styled.div`
  && {
    height: 15vh !important;
    li {
      a {
        color: ${colors.primary[500]};
      }
    }
  }
`

export const StyledDrawer = styled.div`
  && {
    min-width: 80px;
    left: 0;
    top: 0;
    border: none;
    background: #ffffff;
    position: fixed;
    z-index: 300;
    height: 100%;
    /* box-shadow: 4px 0 9px 0 rgba(0, 0, 0, 0.06); */
    box-shadow: 0 1px 10px 0 rgba(108, 108, 108, 0.71);
    & > div {
      border: none;
      width: 80px;
      height: 100%;
    }
    /* & > div > div {
      display: none;
    }
    &:hover > div > div {
      display: block;
    } */

    @media screen and (max-width: 768px) {
      width: 100%;
      height: 60px;
      bottom: 0;
      top: auto;
    }

    @media print {
      display: none;
    }
  }
`
export const StyledListItem = styled.li`
  && {
    text-align: center;
    display: block;
    position: relative;
    transition: all 0.2s ease-in;
    padding: 2vh 0;
    font-size: 1.4rem;
    text-decoration: none;
    color: ${colors.primary[500]};
    border-bottom: 1px solid ${colors.primary[100]};
    opacity: 0.5;
    &:last-child {
      border-bottom: none;
    }
    span {
      color: ${colors.primary[500]};
      font-size: 0.8rem;
      display: block;
      opacity: 0;
    }

    &:hover {
      text-align: center;
      display: block;
      position: relative;
      text-decoration: none;
      color: ${colors.primary[500]};
      background: rgba(255, 255, 255, 0.1);
      opacity: 1;
      i {
      }
      span {
        color: ${colors.primary[500]};
        opacity: 1;
        display: block;
      }
    }

    ${props =>
      props.active &&
      css`
        text-align: center;
        display: block;
        position: relative;
        text-decoration: none;
        color: ${colors.primary[500]};
        background: rgba(255, 255, 255, 0.1);
        opacity: 1;
        i {
        }
        span {
          color: ${colors.primary[500]};
          opacity: 1;
          display: block;
        }
      `}

    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
      padding: 0;
      margin: 0 0.4em;
      border-bottom: none;
      height: 60px;
      span {
        font-size: 0.6rem;
      }
      &:focus {
        outline-offset: none;
      }
    }
  }
`

export const StyledActiveListItem = styled(Link)`
  && {
    text-align: center;
    display: block;
    position: relative;
    border-left: 6px solid #fff;
    text-decoration: none;
    padding: 0.8em 0;
    background: rgba(255, 255, 255, 0.1);
    i {
      color: #fff;
    }
    span {
      color: #fff;
      font-size: 0.7em;
    }
  }
`

export const MenuIcon = styled.i`
  && {
    width: 100%;
    display: block;
    font-size: 1.3em;
    text-align: center;
    padding: 0.4em 0;
    color: rgba(255, 255, 255, 1);
  }
`

export const StyledLogo = styled(ListItem)`
  && {
    margin-bottom: 1em;
    padding: 1em;
    text-align: center;
    cursor: pointer;
    height: 15vh;
    width: 80%;
    margin: 0 10%;
    background: url('../../images/Logotiop.png');
    background-position: center;
    background-size: contain;
    /* border-bottom: 1px solid ${colors.primary[100]}; */
    background-repeat: no-repeat;
    display: block;
    img {
      /* width: 45%; */
      max-height: 55px;
    }
    ${props => props.url && `background-image: url(${props.url});`}
    @media screen and (max-width: 768px) {
      display:none
    }
  }
`

export const NumberToApprove = styled.span`
  && {
    background: #fff;
    border-radius: 50%;
    padding: 0.5em;
    font-size: 0.7em;
    position: absolute;
    top: 4px;
    right: -10px;
    font-weight: bold;
  }
`

export const StyledListWrap = styled.div`
  && {
    display: block;
    height: 70vh;
    width: 100%;
    overflow: auto;
    padding: 0;
    margin: 0;
    -webkit-overflow-scrolling: touch;
    @media screen and (max-width: 768px) {
      height: 80px;
      width: 100%;
    }
  }
`

export const StyledList = styled.div`
  && {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    &:last-child {
      border-bottom: none;
    }
    @media screen and (max-width: 768px) {
      flex-direction: row;
      height: 80px;
      width: 100%;
    }
  }
`

export const StyledNavigationPanel = styled.div`
  && {
    border: none;
    z-index: 300;
    > div {
      background: #fff;
    }
    div {
      background: ${props => props.background};
    }
  }
`
