import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react'

const Dialog = ({ close, title, size, content, cb }) => {
  return (
    <Modal isOpen={true} size={size || 'md'} onClose={() => close()}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{content}</ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={() => close()}>
            Fechar
          </Button>
          {cb && (
            <Button
              variant="ghost"
              onClick={() => {
                cb()
                close()
              }}
            >
              Prosseguir
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default Dialog
