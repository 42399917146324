import styled, { css } from 'styled-components'
import { colors } from '../theme'

export const ClientDetails = styled.div`
  && {
    span {
      color: ${colors.primary[500]};
    }
  }
`
export const AssessmentDetails = styled.div`
  && {
    margin: 1em 0;
  }
`

export const Score = styled.div`
  && {
    span {
      border: 3px solid ${colors.primary[500]};
    }
  }
`
export const Line = styled.div`
  && {
    color: ${colors.secondary[500]};
    font-size: 0.7em;
    span {
      color: ${colors.primary[500]};
    }
  }
`
export const ProductCard = styled.div`
  && {
    /* cursor: pointer; */
    // max-width: 24vw;
    border: none;
    text-align: left;
    background: #fff;
    padding: 1em 1.5em;
    margin: 0 1% 2% 0;
    border-radius: 2em;
    /* min-height: 8em; */
    /* border: 1px solid ${colors.primary[200]}; */
    box-shadow: 0 2px 9px 0 ${colors.primary.transparent[20]};
    ${(props) =>
      props.active
        ? ` 
    background: ${colors.primary[500]};
    color:#fff!important; 
    `
        : ``}
  }
`
export const HistoryCard = styled.div`
  && {
    cursor: pointer;
    width: auto;
    border: none;
    text-align: left;
    background: #fff;
    padding: 1em;
    margin: 0 1% 2% 0;
    border-radius: 2em;
    /* min-height: 8em; */
    /* border: 1px solid ${colors.primary[200]}; */
    box-shadow: 0 2px 9px 0 ${colors.primary.transparent[20]};
    ${(props) =>
      props.limitWith
        ? css`
            min-width: 22%;
          `
        : css`
            min-width: 100%;
          `}
    ${(props) =>
      props.active
        ? css`
            background: ${colors.primary[200]};
          `
        : css``}
  }
`

export const ClientMetricsHistory = styled.div`
  && {
    display: block;
    color: ${colors.primary[500]};
    font-weight: bold;
    font-size: 1.8em;
    span {
      color: ${colors.primary[200]};
      font-weight: lighter;
    }
  }
`
export const ClientTitleHistory = styled.div`
  && {
    display: block;
    color: ${colors.primary[500]};
    font-weight: bold;
    font-size: 1.2em;
    span {
      color: ${colors.primary[200]};
      font-weight: lighter;
    }
    ${(props) =>
      props.active
        ? ` 
    color:#fff; 
    `
        : ``}
  }
`

export const ClientMetricsOutline = styled.div`
  && {
    display: block;
    border: 1px solid ${colors.primary[500]};
    background: transparent;
    padding: 0.3em 0.8em;
    margin: 0.2em;
    border-radius: 2em;
    color: ${colors.primary[500]};
    font-weight: bold;
    font-size: 0.8em;
    span {
      color: ${colors.primary[200]};
      font-weight: lighter;
    }
  }
`

export const ClientInitiativesOutline = styled.div`
  && {
    display: block;
    border: 1px solid ${colors.secondary[500]};
    background: transparent;
    padding: 0.3em 0.8em;
    margin: 0.2em;
    border-radius: 2em;
    color: ${colors.secondary[500]};
    font-weight: bold;
    font-size: 0.8em;
    span {
      color: ${colors.primary[200]};
      font-weight: lighter;
    }
  }
`
export const ClientMetrics = styled.div`
  && {
    display: block;
    background: ${colors.primary[500]};
    padding: 0.3em 0.8em;
    margin: 0.2em;
    border-radius: 2em;
    color: ${colors.primary[100]};
    font-weight: bold;
    font-size: 0.8em;
    span {
      color: ${colors.primary[100]};
      font-weight: lighter;
    }
  }
`
export const AssessmentHistory = styled.div`
  && {
    margin: 0.5em 0;
    span {
      color: ${colors.primary[500]};
    }
  }
`
export const Board = styled.div`
  && {
    width: 32%;
    border: none;
    text-align: center;
    background: #fff;
    padding: 1em;
    margin: 0 1% 1% 0;
    border-radius: 2em;
    /* min-height: 8em; */
    box-shadow: 0 2px 9px 0 ${colors.primary.transparent[20]};
    img {
      object-fit: contain;
      width: 100%;
      max-height: 15vh;
    }
    @media screen and (max-width: 1200px) {
      width: 48%;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 0.5em 0;
    }
  }
`
export const Row = styled.div`
  && {
    display: flex;
    margin: 2em 0;
  }
`
export const RowDashPage = styled.div`
  && {
    display: flex;
    flex-wrap: wrap;
    margin: -20vh 0 0 0;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
`
