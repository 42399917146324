import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { m, motion } from 'framer-motion'
import Header from '../components/header'
import { Heading, Box, Flex, Button, Icon, Progress } from '@chakra-ui/react'
import { setRemoteCall } from '../remote'
import { colors } from '../theme'

import { setGlobalState, useGlobalState } from '../store'
import {
  Board,
  RowDashPage,
  ClientDetails,
  AssessmentHistory,
  ClientMetrics,
} from './styled'
import Edit from './Edit'
import { MdGroupWork } from 'react-icons/md'
import { FiEdit3 } from 'react-icons/fi'
import { GoChecklist } from 'react-icons/go'
import { VscRepoPush, VscRepo } from 'react-icons/vsc'

/**
 * Table collumns
 */
export const tableCollumns = [
  { k: '_id', l: '_id' },
  { k: 'logo', l: 'Logo', t: 'upload' },
  { k: 'name', l: 'Company Name' },
  { k: 'date', l: 'Start Data', t: 'date' },
  { k: 'level', l: 'Level' },
  { k: 'status', l: 'Status' },
  { k: 'theme', l: 'Theme' },
  { k: 'sector', l: 'Sector', t: 'sectors' },
  // { k: 'location', l: 'Localização', t: 'map' }
  // { k: 'attr', l: 'Atributos' },
]

export default function Clients(props) {
  const [clients, setClients] = useGlobalState('clients')
  const [sectors, setSectors] = useGlobalState('sectors')
  const [purchases, setPurchases] = useGlobalState('purchases')

  const [search, setSearch] = useState('')
  const [assessments, setAssessments] = useState([])
  const [openModal, setOpenModal] = useState(false)
  setGlobalState('pageClass', 'clients')
  const options = {
    filterType: 'dropdown',
  }

  const list = {
    visible: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.05,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: 'afterChildren',
      },
    },
  }

  const item = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 50 },
  }

  // const clients = ['cliente1', 'client2', 'client3', 'client4']

  /**
   * Set table data
   */
  const set = (data, notice) => {
    setRemoteCall('setClients', data, notice)
  }

  /**
   * Use effects
   */
  useEffect(() => {
    setRemoteCall('getAllAssessmentsOverview', {}, null, (ass) => {
      setAssessments(ass)
    })
  }, [])

  const updateAssessments = () => {
    setRemoteCall('getAllAssessmentsOverview', {}, null, (ass) => {
      setAssessments(ass)
    })
  }

  /**
   * Calculate total assessmentScore
   *
   */
  const getTotalScore = (assessment) => {
    let totalScore = 0
    if (!assessment) return null

    assessment &&
      assessment.length > 0 &&
      assessment[0].result &&
      Object.keys(assessment[0].result).map((r, i) => {
        totalScore += assessment[0].result[r].assessmentScore
      })

    return (totalScore || 0).toFixed(2)
  }

  return (
    <div className={'clients'}>
      <Header image={'/images/clients.jpg'} height={55}>
        <Flex alignContent={'space-between'} width={'100%'}>
          <Box width={'85%'}>
            <Heading as={'h1'} size="3xl" color={'#fff'}>
              Clientes
            </Heading>
            <Heading as={'h3'} size="md" color={'#fff'}>
              Lista de Clientes{' '}
            </Heading>
          </Box>
          <Box>
            <Button
              colorScheme="primary"
              size="lg"
              value="Submit!"
              width={'100%'}
              _hover={{
                background: 'secondary.500',
              }}
              onClick={() => setOpenModal(true)}
            >
              Adicionar cliente
            </Button>
          </Box>
        </Flex>
      </Header>
      <div className={'wrapper'}>
        <RowDashPage
          as={motion.div}
          initial="hidden"
          animate="visible"
          variants={list}
        >
          {clients &&
            clients.map((client, i) => {
              let thisPercentage = 0
              const thisAssessment = assessments.filter(
                (ass) => ass.client === client._id
              )
              const activeAssessment = thisAssessment.find(
                (ass) => ass.status === 1
              )
              if (activeAssessment && activeAssessment.result) {
                let totalQuestions = 0
                let totalAnswers = 0
                Object.keys(activeAssessment.result).map((cat) => {
                  totalQuestions += activeAssessment.result[cat].questions
                  totalAnswers += activeAssessment.result[cat].answers
                })
                thisPercentage = (totalAnswers * 100) / totalQuestions
              }

              const clientPurchases = purchases.filter(
                (purchase) => client._id === purchase.client
              )

              return (
                <Board
                  as={motion.div}
                  variants={item}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setOpenModal(client)}
                  // active={i === categoryId}
                  cursor={'pointer'}
                >
                  <Flex
                    width={'100%'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Box width={'20%'}>
                      {client.logo ? (
                        <img src={client.logo} />
                      ) : (
                        <Icon
                          as={MdGroupWork}
                          color={colors.primary[300]}
                          width={['5em', '5em', '4em', '5em', '5em']}
                          height={['5em', '5em', '4em', '5em', '5em']}
                        />
                      )}
                    </Box>
                    <Box width={'70%'} textAlign={'left'}>
                      <ClientDetails>
                        <Flex justifyContent={'space-between'}>
                          <strong>{client.name} </strong>{' '}
                        </Flex>
                        <Box fontSize={'.8em'}>
                          <span>Nível:</span> {client.level || 0}{' '}
                          {/* <span>Product:</span> {thisSectors || 'NA'}{' '} */}
                          <span>Pontuação:</span> {client.level || 0}
                        </Box>
                      </ClientDetails>
                      <AssessmentHistory>
                        {activeAssessment && (
                          <Progress
                            value={thisPercentage}
                            size="sm"
                            variant={colors.primary}
                          />
                        )}
                      </AssessmentHistory>
                      <Flex flexWrap={'wrap'}>
                        {/* <ClientMetrics key={i}>
                          <Icon as={GoChecklist} color={colors.primary[300]} />{' '}
                          {getTotalScore(thisAssessment)}%
                        </ClientMetrics> */}
                        {/* <Link to={`/questions/${client._id}`}>
                          <Button
                            colorScheme={'primary'}
                            size="sm"
                            fontSize={'.7em'}
                            marginRight={'.2em'}
                            value="Submit!"
                            _hover={{
                              background: 'primary.500'
                            }}
                          >
                            <FiEdit3 />
                          </Button>
                        </Link> */}

                        {clientPurchases.map((product, i) => {
                          const thisSectors = sectors.find(
                            (s) => s._id === product.sector
                          )
                          if (!thisSectors) return null
                          return (
                            <Link
                              key={i}
                              to={`/assessment/${product.sector}/${client._id}`}
                            >
                              <Button
                                colorScheme={
                                  activeAssessment ? 'secondary' : 'primary'
                                }
                                size="sm"
                                marginRight={'.2em'}
                                mb={'.6em'}
                                fontSize={'.6em'}
                                value="Submit!"
                                _hover={{
                                  background: 'secondary.500',
                                }}
                              >
                                {activeAssessment ? (
                                  <Icon
                                    as={VscRepoPush}
                                    color={'white'}
                                    height={'1.5em'}
                                    width={'1.5em'}
                                    marginRight={'.2em'}
                                  />
                                ) : (
                                  <Icon
                                    as={VscRepo}
                                    color={'white'}
                                    height={'1.5em'}
                                    width={'1.5em'}
                                    marginRight={'.2em'}
                                  />
                                )}{' '}
                                {thisSectors.name}
                              </Button>
                            </Link>
                          )
                        })}

                        {/* {thisSectors.map((product, i) => {
                          return (
                            <Link
                              key={i}
                              to={`/assessment/${product._id}/${client._id}`}
                            >
                              <Button
                                colorScheme={
                                  activeAssessment ? 'secondary' : 'primary'
                                }
                                size="sm"
                                marginRight={'.2em'}
                                fontSize={'.7em'}
                                value="Submit!"
                                _hover={{
                                  background: 'secondary.500'
                                }}
                              >
                                {activeAssessment ? (
                                  <Icon
                                    as={VscRepoPush}
                                    color={'white'}
                                    height={'1.5em'}
                                    width={'1.5em'}
                                    marginRight={'.2em'}
                                  />
                                ) : (
                                  <Icon
                                    as={VscRepo}
                                    color={'white'}
                                    height={'1.5em'}
                                    width={'1.5em'}
                                    marginRight={'.2em'}
                                  />
                                )}{' '}
                                {product.name}
                              </Button>
                            </Link>
                          )
                        })} */}
                      </Flex>
                    </Box>
                  </Flex>
                </Board>
              )
            })}
        </RowDashPage>
      </div>
      {openModal && (
        <Edit
          data={openModal}
          form={tableCollumns}
          updateAssessments={updateAssessments}
          getTotalScore={getTotalScore}
          assessments={
            assessments &&
            assessments.filter((ass) => openModal._id === ass.client)
          }
          set={set}
          setOpenModal={setOpenModal}
        />
      )}
    </div>
  )
}
