import React, { useState } from 'react'
import { motion } from 'framer-motion'
import Header from '../components/header'
import { Heading, Box, Flex, Button, Icon } from '@chakra-ui/react'
import { setRemoteCall } from '../remote'
import { colors } from '../theme'
import { setGlobalState, useGlobalState } from '../store'
import { Board, RowDashPage } from './styled'
import Edit from './Edit'
import { FaUserCircle } from 'react-icons/fa'

/**
 * Table collumns
 */
export const tableCollumns = [
  { k: '_id', l: '_id' },
  { k: 'firstName', l: 'Primeiro nome' },
  { k: 'lastName', l: 'Último nome' },
  { k: 'email', l: 'Email' },
  { k: 'password', l: 'Palavra-passe', t: 'password' },
  { k: 'admin', l: 'admin' },
  { k: 'status', l: 'Estado' },
  { k: 'role', l: 'Permissão' },
  // { k: 'location', l: 'Localização', t: 'map' }
  // { k: 'attr', l: 'Atributos' },
]

export default function Users(props) {
  const [openModal, setOpenModal] = useState(false)
  const [users] = useGlobalState('users')
  const [clients] = useGlobalState('clients')
  setGlobalState('pageClass', 'users')

  const list = {
    visible: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.05,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: 'afterChildren',
      },
    },
  }

  const item = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 50 },
  }

  /**
   * Set table data
   */
  const set = (data, notice) => {
    setRemoteCall('setUsers', data, notice)
  }
  /**
   * Del table data
   */
  const del = (data, notice) => {
    setRemoteCall('delUsers', data, notice)
  }
  return (
    <div className={'users dashboard'}>
      <Header image={'/images/dash.png'} height={60}>
        <Flex alignContent={'space-between'} width={'100%'}>
          <Box width={'85%'}>
            <Heading as={'h1'} size="3xl" color={'#fff'}>
              Utilizadores
            </Heading>
            <Heading as={'h3'} size="md" color={'#fff'}>
              lista de utilizadores
            </Heading>
          </Box>
          <Box>
            <Button
              colorScheme="primary"
              size="lg"
              value="Submit!"
              width={'100%'}
              _hover={{
                background: 'secondary.500',
              }}
              onClick={() => setOpenModal(true)}
            >
              Adicionar utilizador
            </Button>
          </Box>
        </Flex>
      </Header>
      <div className={'wrapper'}>
        <RowDashPage
          as={motion.div}
          initial="hidden"
          animate="visible"
          variants={list}
        >
          {users &&
            users.map((user, i) => {
              let thisClient
              if (user.role) {
                thisClient =
                  clients.find((c) => c._id === user.role) || 'global'
              }
              return (
                <Board
                  as={motion.div}
                  variants={item}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setOpenModal(user)}
                  // active={i === categoryId}
                >
                  <Flex alignContent={'center'} alignItems={'center'}>
                    <Box marginRight={'1em'}>
                      <Icon
                        as={FaUserCircle}
                        color={colors.primary[500]}
                        height={'3em'}
                        width={'3em'}
                      />
                    </Box>
                    <div>
                      <p style={{ fontWeight: 'bold!important ' }}>
                        {user.firstName} {user.lastName}
                      </p>
                      <p style={{ color: colors.primary[500] }}>
                        {user.status ? 'Active -' : 'Inactive -'}{' '}
                        {thisClient ? thisClient.name : 'global'}
                      </p>
                    </div>
                  </Flex>
                </Board>
              )
            })}
        </RowDashPage>
      </div>

      {openModal && (
        <Edit
          data={openModal}
          form={tableCollumns}
          set={set}
          del={del}
          setOpenModal={setOpenModal}
        />
      )}
    </div>
  )
}
