import { extendTheme, theme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'
// import Color from 'color'
import tinycolor from 'tinycolor2'
import { getPresistData } from './util'

// let load custom theme
const logData = getPresistData('cmoreApp', 'loggedIn')
let primary = '#3D817F'
let secondary = '#985d2d'
if (logData && logData.theme) {
  primary = logData.theme.primary
  secondary = logData.theme.secondary
}

// 2. declare your configuration, these are the defaults
const config = {
  initialColorMode: 'light',
  useSystemColorMode: false
}

const colors = {
  white: {
    600: '#fff',
    500: '#fff'
  },
  primary: {
    500: tinycolor(primary).toRgbString(),
    400: tinycolor(primary)
      .lighten(10)
      .toRgbString(),
    300: tinycolor(primary)
      .lighten(20)
      .toRgbString(),
    200: tinycolor(primary)
      .lighten(30)
      .toRgbString(),
    100: tinycolor(primary)
      .lighten(40)
      .toRgbString(),
    50: tinycolor(primary)
      .lighten(70)
      .toRgbString(),
    transparent: {
      90: tinycolor(primary)
        .setAlpha(0.9)
        .toRgbString(),
      80: tinycolor(primary)
        .setAlpha(0.8)
        .toRgbString(),
      40: tinycolor(primary)
        .setAlpha(0.4)
        .toRgbString(),
      30: tinycolor(primary)
        .setAlpha(0.3)
        .toRgbString(),
      20: tinycolor(primary)
        .setAlpha(0.2)
        .toRgbString(),
      10: tinycolor(primary)
        .setAlpha(0.1)
        .toRgbString()
    }
  },
  secondary: {
    500: tinycolor(secondary).toRgbString(),
    400: tinycolor(secondary)
      .lighten(10)
      .toRgbString(),
    300: tinycolor(secondary)
      .lighten(20)
      .toRgbString(),
    200: tinycolor(secondary)
      .lighten(30)
      .toRgbString(),
    100: tinycolor(secondary)
      .lighten(40)
      .toRgbString(),
    50: tinycolor(secondary)
      .lighten(50)
      .toRgbString(),
    pie: {
      0: tinycolor(secondary).toRgbString(),
      2: tinycolor(secondary)
        .lighten(2)
        .toRgbString(),
      4: tinycolor(secondary)
        .lighten(4)
        .toRgbString(),
      6: tinycolor(secondary)
        .lighten(6)
        .toRgbString(),
      8: tinycolor(secondary)
        .lighten(8)
        .toRgbString(),
      10: tinycolor(secondary)
        .lighten(10)
        .toRgbString(),
      12: tinycolor(secondary)
        .lighten(12)
        .toRgbString(),
      14: tinycolor(secondary)
        .lighten(14)
        .toRgbString(),
      16: tinycolor(secondary)
        .lighten(16)
        .toRgbString(),
      18: tinycolor(secondary)
        .lighten(18)
        .toRgbString(),
      20: tinycolor(secondary)
        .lighten(20)
        .toRgbString()
    },
    transparent: {
      90: tinycolor(secondary)
        .setAlpha(0.9)
        .toRgbString(),
      80: tinycolor(secondary)
        .setAlpha(0.8)
        .toRgbString(),
      40: tinycolor(secondary)
        .setAlpha(0.4)
        .toRgbString(),
      30: tinycolor(secondary)
        .setAlpha(0.3)
        .toRgbString(),
      20: tinycolor(secondary)
        .setAlpha(0.2)
        .toRgbString(),
      10: tinycolor(secondary)
        .setAlpha(0.1)
        .toRgbString()
    }
  }
}
const breakpoints = createBreakpoints({
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em'
})

const overrides = {
  ...theme,
  config,
  breakpoints,
  colors: {
    ...theme.colors,
    ...colors,
    transparent: 'transparent',
    black: '#000'
  },
  components: {
    Button: {
      baseStyle: {
        cursor: 'pointer',
        boxShadow: ' 0 2px 9px 0 rgba(120,47,10,0.10)',
        _hover: {}
      },
      sizes: {
        lg: {
          fontSize: '.9em'
        }
      },
      variants: {}
    },
    Select: {
      colorScheme: {
        primary: {}
      }
    },
    ProgressBar: {
      baseStyle: {
        color: colors.primary[500],
        border: 0,
        boxShadow: ' 0 2px 9px 0 rgba(120,47,10,0.10)'
      },
      sizes: {
        sm: {
          background: colors.primary[500],
          border: 0,
          boxShadow: ' 0 2px 9px 0 rgba(120,47,10,0.10)'
        }
      }
    },

    Input: {
      baseStyle: {
        color: colors.primary,
        background: 'white',
        border: 0,
        boxShadow: ' 0 2px 9px 0 rgba(120,47,10,0.10)'
      },
      sizes: {
        md: {
          border: 0,
          boxShadow: ' 0 2px 9px 0 rgba(120,47,10,0.10)'
        }
      }
    }
  },
  radii: {
    none: '2em',
    md: '2em',
    base: '2em'
  },
  fonts: {
    heading: '"Montserrat Alternates", sans-serif',
    body: '"Montserrat Alternates", sans-serif'
  },
  styles: {
    global: props => ({
      'html, body': {
        background: colors.primary[50],
        color: colors.secondary[500]
      },
      a: {},
      'h1,h2,h3,h4,h5,h6': {
        fontWeight: 'bold !important'
      },
      input: {
        boxShadow: ' 0 2px 9px 0 rgba(120,47,10,0.10)',
        margin: '.5em 0',
        border: '0!important',
        background: 'white!important',
        color: colors.secondary[500],
        fontSize: '.9em!important'
      },
      option:{
        color:"black"
      },
      select: {
        boxShadow: ' 0 2px 9px 0 rgba(120,47,10,0.10)',
        margin: ' 0',
        border: '0!important',
        background: 'white!important',
        borderRadius: '2em!important',
        height: '3.5em!important',
        // color: colors.secondary[500],
        fontSize: '.9em!important'
      },
      textarea: {
        boxShadow: ' 0 2px 9px 0 rgba(120,47,10,0.10)',
        margin: ' 0',
        border: '0!important',
        background: 'white!important',
        borderRadius: '2em!important',
        // color: colors.secondary[500],
        fontSize: '.9em'
      }
    })
  }
}
const over = extendTheme(overrides)

export { colors, over }
