import React, { useState, useEffect } from 'react'
import './Login.css'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import {
  Box,
  Button,
  Heading,
  Flex,
  Input,
  Link,
  useToast,
  CheckboxGroup,
  Checkbox,
} from '@chakra-ui/react'
import { setGlobalState, setStorageState } from '../store'
import { colors } from '../theme'
function Login(props) {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [email, setEmail] = useState('')
  const [sectors, setSectors] = useState([])
  const [freeSector, setFreeSector] = useState([])
  const [selectedSectors, setSelectedSectors] = useState([])
  const toast = useToast()
  setGlobalState('pageClass', 'login')

  useEffect(async () => {
    const response = await axios.get(`/sectors`)
    if (response && response.data) {
      setSectors(response.data || [])
      const demo = response.data.find((sector) => sector.price === 0)
      setFreeSector(demo._id)
    }
  }, [])

  const registerUser = async (e) => {
    e.preventDefault()
    if (
      lastName === '' ||
      firstName === '' ||
      email === '' ||
      companyName === '' ||
      password === ''
    ) {
      return toast({
        title: 'Por favor introduza os dados',
        status: 'warning',
        position: 'bottom-left',
      })
    }

    try {
      const response = await axios.post(`/auth/register`, {
        firstName,
        lastName,
        email,
        password,
        companyName,
        sectors: freeSector,
      })
      if (response.data) {
        if (response.data && response.data.status === 'OK') {
          props.history.push('/')
        } else {
          toast({
            title: response.data.msg
              ? response.data.msg.message
              : 'Verifique os seus dados',
            status: 'warning',
            position: 'bottom-left',
          })
        }
      }
    } catch (e) {
      console.log(e)
      toast({
        title: e || 'Verifique os seus dados',
        status: 'warning',
        position: 'bottom-left',
      })
    }
  }

  return (
    <Flex
      align="center"
      alignItems="center"
      justifyContent={['start', 'start', 'center', 'center', 'center']}
      flexDirection="column"
      height="100vh"
      backgroundImage={`linear-gradient(
        to right,
        ${colors.primary.transparent[80]},
        ${colors.primary.transparent[80]}
      ),
      url(/images/home.jpg)`}
      backgroundSize={'cover'}
      backgroundPosition={'center'}
      backgroundRepeat={'no-repeat'}
    >
      <Box
        className={'loginwrap'}
        backgroundColor={colors.primary[50]}
        width={['100%', '100%', '40%', '40%', '40%']}
        minHeight={['50vh', '50vh', '100vh', '100vh', '100vh']}
        padding={'2em'}
        shadow="md"
        borderWidth="0"
        rounded="lg"
        position={'absolute'}
        bottom={['0', '0', '0', '0', '0']}
        left="0"
      >
        <div className={'login-container'}>
          <div>
            <Box
              backgroundImage={'url(/images/Logotiop.png)'}
              backgroundSize={'contain'}
              backgroundPosition={'center'}
              backgroundRepeat={'no-repeat'}
              height={['8em', '8em', '10em', '10em', '10em']}
              margin={['1em 0', '2em 0', '3em 0', '3em 0', '3em 0']}
            />
            <form
              className="registration-form"
              onSubmit={registerUser}
              data-login-form
            >
              <Flex width={'100%'} justifyContent={'space-between'}>
                <Input
                  width={'47%'}
                  data-login-email
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="Primeiro Nome"
                  colorScheme="primary"
                  size="lg"
                />
                <Input
                  width={'47%'}
                  data-login-email
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Último nome"
                  colorScheme="primary"
                  size="lg"
                />
              </Flex>
              <Input
                data-login-email
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                colorScheme="primary"
                size="lg"
              />
              <Input
                type="password"
                data-login-password
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Palavra-passe"
                colorScheme="primary"
                size="lg"
              />

              <Input
                type="CompanyName"
                data-login-CompanyName
                onChange={(e) => setCompanyName(e.target.value)}
                placeholder="Empresa"
                colorScheme="primary"
                size="lg"
              />

              {/* <CheckboxGroup
                onChange={(e) => setSelectedSectors(e)}
                colorScheme="primary"
                defaultValue={selectedSectors}
              >
                <Flex
                  width={'100%'}
                  margin={'1em 0'}
                  justifyContent={'space-between'}
                >
                  {sectors.map((sector) => {
                    return <Checkbox value={sector._id}>{sector.name}</Checkbox>
                  })}
                </Flex>
              </CheckboxGroup> */}

              <Button
                onClick={(e) => registerUser(e)}
                colorScheme="primary"
                size="lg"
                value="Submit!"
                width={'100%'}
                _hover={{
                  background: 'secondary.500',
                }}
              >
                Registrar
              </Button>
            </form>
            <Link
              href={'/'}
              fontSize={'.8em'}
              margin={'1em'}
              display={'block'}
              color={'primary.500'}
              textAlign={'center'}
            >
              Login
            </Link>
          </div>
        </div>
      </Box>

      <Box
        marginLeft={['0%', '0%', '45%', '45%', '45%']}
        marginTop={['20vh', '25vh', '0%', '0%', '0%']}
        width={['80%', '80%', '55%', '55%', '55%']}
      >
        <Heading
          as="h3"
          fontSize={['0.9rem', '1.0rem', '1.2rem', '1.5rem', '1.5rem']}
          color={'white'}
        >
          Assessment application
        </Heading>
        <Heading
          as="h1"
          fontSize={['2.8rem', '4rem', '5rem', '7rem', '7rem']}
          color={'white'}
        >
          Planet, People, Culture
        </Heading>
      </Box>
    </Flex>
  )
}

export default withRouter(Login)
