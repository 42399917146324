import React from 'react'
import axios from 'axios'
import Dropzone from 'react-dropzone'
import { DropContainer } from './styled'

export default function Upload({ callback, fullCallback }) {
  const uploadFiles = async (files) => {
    try {
      const file = new Blob([files[0]]) // kind of works and choses stream as content type of file (not request)
      console.log(files)
      const data = new FormData()
      data.append(
        'file',
        file,
        files[0].name || files[0].type.replace('/', '.')
      )
      const result = await axios.post(`/files`, data)
      //now lets get the image url
      callback && callback(result.data.link)
      fullCallback && fullCallback(result.data)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Dropzone
      maxFiles={2}
      onDrop={(acceptedFiles) => uploadFiles(acceptedFiles)}
    >
      {({ getRootProps, getInputProps }) => (
        <DropContainer {...getRootProps()}>
          <input {...getInputProps()} />
          <p>Arrasta um ficheiro, ou carraga para escolher</p>
        </DropContainer>
      )}
    </Dropzone>
  )
}
