import React, { useState } from 'react'
import {
  Button,
  Select,
  InputLeftAddon,
  InputGroup,
  Input,
  Textarea,
  Text,
} from '@chakra-ui/react'

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'
import { useGlobalState } from '../store'

export default function Edit(props) {
  const [clients, setClients] = useGlobalState('clients')
  const [details, setDetails] = useState(props.data || {})

  const [view, setView] = useState('info')
  // const projects = getGlobalState('projects')
  // const relations = getGlobalState('relations')

  let thisProject = {}

  const handleFormFieldChange = (key, { target: { value } }) => {
    setDetails({ ...details, [key]: value })
  }

  return (
    <Drawer
      isOpen={true}
      placement="right"
      isFullHeight={true}
      size={'md'}
      onClose={() => props.setOpenModal()}
      // finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">
          Editar {details ? details.firstName : ''} detalhes{' '}
          {/* <Button
            size={'sm'}
            mr={3}
            variant={view === 'info' ? 'solid' : 'outline'}
            onClick={() => setView('info')}
          >
            Info
          </Button> */}
          {/* <Button
            size={'sm'}
            variant={view === 'history' ? 'solid' : 'outline'}
            mr={3}
            onClick={() => {
              setView('history')
            }}
          >
            Histórico
          </Button> */}
        </DrawerHeader>

        <DrawerBody overflow={'auto'}>
          {props.form &&
            view === 'info' &&
            props.form.map((k, i) => {
              if (k.k === '_id') return null
              if (k.k === 'role' && props.types)
                return (
                  <InputGroup key={i} margin={'1em 0'} size="sm">
                    <InputLeftAddon
                      minWidth={'120px'}
                      textAlign={'center'}
                      padding={'1.5em .5em'}
                      children={k.l}
                      fontWeight={'bold'}
                    />
                    <Select
                      height="3em"
                      onChange={(event) => handleFormFieldChange(k.k, event)}
                      placeholder="Selecionar opção"
                      value={details[k.k]}
                    >
                      {props.types.map(
                        (t) =>
                          t.status === 1 && (
                            <option value={t._id}> {t.name}</option>
                          )
                      )}
                    </Select>
                  </InputGroup>
                )
              if (k.k === 'admin')
                return (
                  <InputGroup key={i} margin={'1em 0'} size="sm">
                    <Select
                      height="3em"
                      onChange={(event) => handleFormFieldChange(k.k, event)}
                      placeholder="Admin"
                      value={details[k.k]}
                    >
                      <option value={1}> Ativo </option>
                      <option value={0}> Inativo </option>
                    </Select>
                  </InputGroup>
                )
              if (k.k === 'status')
                return (
                  <InputGroup key={i} margin={'1em 0'} size="sm">
                    <Select
                      onChange={(event) => handleFormFieldChange(k.k, event)}
                      placeholder="Estado"
                      value={details[k.k]}
                    >
                      <option value={0}> Inativo </option>
                      <option value={1}> Ativo </option>
                    </Select>
                  </InputGroup>
                )
              if (k.k === 'role')
                return (
                  <InputGroup key={i} margin={'1em 0'} size="sm">
                    <Select
                      onChange={(event) => handleFormFieldChange(k.k, event)}
                      placeholder="Cliente"
                      value={details[k.k]}
                    >
                      <option value={'global'}>Global</option>
                      {clients.map((c) => (
                        <option value={c._id}> {c.name} </option>
                      ))}
                    </Select>
                  </InputGroup>
                )
              if (k.t === 'textarea') {
                return (
                  <InputGroup key={i} margin={'1em 0'} size="sm">
                    <Textarea
                      padding={'1.5em .5em'}
                      onChange={(event) => handleFormFieldChange(k.k, event)}
                      placeholder={k.l}
                      type={k.t}
                      value={details[k.k]}
                    />
                  </InputGroup>
                )
              }
              if (k.t === 'dateArray') {
                return (
                  <>
                    <Text fontSize={'.8em'} fontWeight={'bold'}>
                      Indisponibilidade
                    </Text>
                  </>
                )
              }
              return (
                <Input
                  size="lg"
                  onChange={(event) => handleFormFieldChange(k.k, event)}
                  type={k.t || 'text'}
                  placeholder={k.l}
                  value={details[k.k]}
                />
              )
            })}
        </DrawerBody>

        <DrawerFooter borderTopWidth="1px">
          <Button
            variant="outline"
            colorScheme="primary"
            mr={3}
            onClick={() => props.del([details._id])}
          >
            Apagar
          </Button>
          <Button
            variant="outline"
            colorScheme="primary"
            mr={3}
            onClick={() => props.setOpenModal()}
          >
            Cancelar
          </Button>
          <Button
            colorScheme="primary"
            onClick={() => {
              props.set({ ...details }, ` atualizar utilizador`, 'users')
              props.setOpenModal()
            }}
          >
            Salvar
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
