import React, { useEffect, useCallback, useState } from 'react'
import {
  Button,
  Flex,
  InputGroup,
  Input,
  InputRightElement,
} from '@chakra-ui/react'

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'
import { setRemoteCall } from '../remote'
import { useGlobalState } from '../store'
import { BoardSmall } from './styled'

export default function EditInitiatives(props) {
  const [targets, setTargets] = useGlobalState('targets')
  // const [sectors, setSectors] = useGlobalState('sectors')
  const [targetText, setTargetText] = useState('')

  const [view, setView] = useState('info')

  /**
   * Del Initiative
   */
  const delTarget = (target) => {
    setRemoteCall('delTarget', target, null)
  }
  /**
   * Set Initiative
   */
  const setInitiative = (target) => {
    setRemoteCall(
      'setTarget',
      {
        name: target,
        date: new Date(),
        attr: {},
        status: 1,
      },
      null
    )
  }

  return (
    <Drawer
      isOpen={true}
      placement="right"
      isFullHeight={true}
      size={'md'}
      onClose={() => props.setOpenModal()}
      // finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">Editar SDG</DrawerHeader>

        <DrawerBody overflow={'auto'}>
          <InputGroup size="lg">
            <Input
              type={'text'}
              value={targetText}
              onChange={(e) => setTargetText(e.target.value)}
              placeholder="Nova Iniciativa"
            />
            <InputRightElement width="4.5rem">
              <Button
                size="sm"
                colorScheme="primary"
                marginTop={'.7rem'}
                onClick={() => {
                  setInitiative(targetText)
                  setTargetText('')
                }}
              >
                Adicionar +
              </Button>
            </InputRightElement>
          </InputGroup>

          {targets &&
            targets.map((init) => {
              return (
                <BoardSmall>
                  <Flex alignItems={'center'} justifyContent={'space-between'}>
                    {init.name}
                    <Button
                      size="sm"
                      colorScheme="primary"
                      variant="outline"
                      onClick={() => delTarget(init._id)}
                    >
                      -
                    </Button>
                  </Flex>
                </BoardSmall>
              )
            })}
        </DrawerBody>

        <DrawerFooter borderTopWidth="1px">
          <Button
            variant="outline"
            colorScheme="primary"
            mr={3}
            onClick={() => props.setOpenModal()}
          >
            Fechar
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
