import React, { useEffect, useState } from 'react'
import { withRouter, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import Header from '../components/header'
import { Heading, Box, Flex, Progress } from '@chakra-ui/react'
import { setRemoteCall } from '../remote'
import Options from './Options'
import Question from './Question'
import Map from './Map'

import { setGlobalState, useGlobalState, getGlobalState } from '../store'
import {
  RowDashPage,
  BoardNavigation,
  BoardNavigationNext,
  AssessmentNavigation,
  CompleteAssessment,
  CompleteAssessmentOutline,
} from './styled'
import { colors } from '../theme'

function Assessment(props) {
  let { clientId, sectorId } = useParams()
  const [assessment, setAssessment] = useGlobalState('assessment')
  const [sectors, setSectors] = useGlobalState('sectors')

  const [assessmentResult, setAssessmentResult] = useState({})
  const [client, setClient] = useState(clientId || false)
  const [sector, setSector] = useState(sectorId || false)
  const [isBusy, setBusy] = useState(true)
  const [catTheme, setCatTheme] = useState(true)
  const [loadedAnim, setLoadedAnim] = useState(false)
  const [percentDone, setPercentDone] = useState(0)
  const [categoryImage, setCategoryImage] = useState(0)
  const [totalQuestions, setTotalQuestions] = useState(0)

  const [questionNumber, setQuestionNumber] = useState(0)
  const [questionNumberGlobal, setQuestionNumberGlobal] = useState(0)
  const [category, setCategory] = useState(0)
  const [percentage, setPercentage] = useState(0)
  const [totalAnswers, setTotalAnswers] = useState(0)
  const [percentageQuestions, setPercentageQuestions] = useState(0)
  const [subCategory, setSubCategory] = useState(0)
  const [question, setQuestion] = useState(false)
  const [endFrame, setEndFrame] = useState(false)
  const [inactiveStatus, setInactiveStatus] = useState(false)
  const [newCat, setNewCat] = useState(false)
  const [unanswered, setUnanswered] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const [currAnswer, setCurrAnswer] = useState({})
  //quiz
  setGlobalState('pageClass', 'assessment')
  const catColor = [
    '#003B5B',
    '#1E78A8',
    '#EC1C2D',
    '#61B4E5',
    '#A9403B',
    '#AF96C7',
    '#8DC63F',
    '#F9B432',
    '#009CA7',
    '#60A944',
    '#FECE08',
    '#C49A6C',
    '#F26F22',
    '#324C8E',
  ]
  /**
   * animations
   */

  const list = {
    visible: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.2,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: 'afterChildren',
      },
    },
  }

  const item = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 50 },
  }

  /**
   * Helper function to set the assessment at last answared question
   * @param {*} structure
   */

  const resumeAssessment = (assessmentData) => {
    const mainCatNotCompleted = []
    assessmentData.result &&
      Object.keys(assessmentData.result).map((cat) => {
        if (assessmentData.result[cat].answers < 1) {
          mainCatNotCompleted.push(cat)
        }
      })
    let thisCatID = 0
    assessmentData.structure.find((cat, i) => {
      if (mainCatNotCompleted.includes(cat._id)) {
        thisCatID = i
        return true
      }
    })
    goToQuestion(thisCatID, 0, 0)
  }
  /**
   * We need to check how much each question is to be able to check results
   */
  const getAssessmentResults = (structure) => {
    let totalsObject = {}
    let totalAssQuestions = 0
    structure.map((cat) => {
      totalsObject[cat._id] = {
        subCats: {},
        name: cat.name,
        questions: 0,
        score: 0,
        assessmentScore: 0,
        ignored: 0,
        answers: 0,
        initiatives: [],
        targets: [],
      }
      if (cat.subCategories) {
        cat.subCategories.map((subCat) => {
          //let cerate all vars for bookeeping
          let subCatScore = 0
          let subCatInitiatives = []
          let subCatTargets = []
          // let subTotalQuestions = subCat.questions.length || 0
          let subTotalQuestions =
            subCat.questions.filter((q) => !q.ignore).length || 0
          let totalSubCatTotalAnswers = 0
          let totalSubCatTotalAnswerCorrect = 0
          let totalSubCatTotalAnswersWrong = 0
          let totalSubCatTotalNotAnswered = 0

          let subCatTotalPercentage = subCat.percentage || 0
          if (
            cat.percentage &&
            (!subCat.percentage || subCat.percentage === 0)
          ) {
            subCatTotalPercentage = cat.percentage / cat.subCategories.length
          }
          let subCatTotalQuestionPercentage =
            subCatTotalPercentage / subTotalQuestions

          //check if we have questions
          if (subCat.questions) {
            subCat.questions.map((question) => {
              //check if we have answer to process
              if (question.answer) {
                const thisScore =
                  subCatTotalQuestionPercentage * (question.answer.score / 100)
                if (
                  question.answer.ignore ||
                  !question.answer.hasOwnProperty('score')
                ) {
                  totalSubCatTotalNotAnswered = totalSubCatTotalNotAnswered + 1
                }
                if (
                  (question.answer.score < 20 || !question.answer.score) &&
                  question.initiatives &&
                  question.initiatives.length > 0
                ) {
                  totalSubCatTotalAnswersWrong++
                  subCatInitiatives = [
                    ...subCatInitiatives,
                    ...question.initiatives,
                  ]
                } else if (
                  question.answer.score &&
                  question.answer.score > 70 &&
                  question.targets &&
                  question.targets.length > 0
                ) {
                  subCatTargets = [...subCatTargets, ...question.targets]
                }

                if (question.answer.score && question.answer.score > 20) {
                  totalSubCatTotalAnswerCorrect++
                }

                if (question.answer.score || question.answer.score === 0) {
                  //set totals
                  subCatScore += thisScore
                  totalSubCatTotalAnswers++
                }
              }
            })
          }
          //lets set main object props
          totalAssQuestions += subTotalQuestions
          totalsObject[cat._id].questions += subTotalQuestions
          totalsObject[cat._id].score += subCatScore
          totalsObject[cat._id].ignored += totalSubCatTotalNotAnswered
          totalsObject[cat._id].answers += totalSubCatTotalAnswers
          totalsObject[cat._id].targets = [
            ...totalsObject[cat._id].targets,
            ...subCatTargets,
          ]
          totalsObject[cat._id].initiatives = [
            ...totalsObject[cat._id].initiatives,
            ...subCatInitiatives,
          ]
          //create output object
          totalsObject[cat._id].subCats[subCat._id] = {
            questions: subTotalQuestions,
            name: subCat.name,
            color: subCat.color,
            logo: subCat.logo,
            image: subCat.image,
            assessmentScore: subCatScore,
            totalScore: subCatTotalPercentage,
            answers: totalSubCatTotalAnswers,
            totalSubCatTotalAnswersWrong,
            totalSubCatTotalAnswerCorrect,
            totalSubCatTotalNotAnswered,
            assessmentQuestionScore: subCatTotalQuestionPercentage,
            targets: subCatTargets,
            initiatives: subCatInitiatives,
          }
        })
      }

      totalsObject[cat._id] = {
        ...totalsObject[cat._id],
        color: cat.color,
        logo: cat.logo,
        image: cat.image,
        assessmentScore: totalsObject[cat._id].score,
        score: cat.percentage,
        // assessmentScore: (totalsObject[cat._id].score / 100) * cat.percentage
      }
    })
    setAssessmentResult(totalsObject)
    setTotalQuestions(totalAssQuestions)
    setPercentageDone(totalsObject)
  }
  const saveProgress = () => {
    setRemoteCall(
      'setAssessmentProgress',
      { _id: assessment._id, result: assessmentResult },
      '',
      (d) => {}
    )
  }

  /**
   * Set Percentage Done
   */
  const setPercentageDone = (totals) => {
    // setPercentage
    if (totals) {
      let totalQuestions = 0
      let totalAnswers = 0
      Object.keys(totals).map((cat) => {
        totalQuestions += totals[cat].questions
        totalAnswers += totals[cat].answers + totals[cat].ignored
      })
      setPercentage((totalAnswers * 100) / totalQuestions)
      setTotalAnswers(totalAnswers)
    }
  }

  /**
   * Set awswear
   */
  const saveAnswers = (data, notice) => {
    const newQuestionNumber = questionNumber + 1
    if (
      !currAnswer.ignore &&
      !currAnswer.value &&
      !currAnswer.score &&
      !currAnswer.comment
    ) {
      //no data lets not save
      setCurrAnswer({})
      setCurrentQuestion(newQuestionNumber)
      setQuestionNumberGlobal(questionNumberGlobal + 1)
      setPercentDone('')
    } else {
      const finalAnswer = {
        _id: currAnswer._id,
        question: question._id,
        value: currAnswer.value,
        date: new Date(),
        score: currAnswer.score,
        comment: currAnswer.comment,
        images: currAnswer.images,
        assessment: assessment._id,
        ignore: currAnswer.ignore,
        client,
        isName: question.text.toLowerCase() === 'nome do evento',
      }

      //set save answers
      setRemoteCall('setAnswer', finalAnswer, notice, (d) => {
        //lets update the accessment
        assessment.structure[category].subCategories[subCategory].questions[
          questionNumber
        ].answer = d
        setAssessment(assessment)

        //lets calculate
        getAssessmentResults(assessment.structure)
        //let update progress on db
        saveProgress()

        //update state
        setCurrAnswer({})
        setCurrentQuestion(newQuestionNumber)
        setQuestionNumberGlobal(questionNumberGlobal + 1)

        setPercentDone('')
      })
    }
  }

  /**
   * update Progress
   */
  const filterAssessment = () => {
    if (unanswered) {
      const structure = assessment.structure.map((cat) => {
        const subCategories = cat.subCategories.map((subCat) => {
          const questions = subCat.questions.filter((q) => !q.answer)
          return { ...subCat, questions }
        })
        return { ...cat, subCategories }
      })
      return { ...assessment, structure }
    }
    return assessment
  }

  const goToQuestion = (selCategory, selSubCategory, question) => {
    setCurrentQuestion(question, selCategory, selSubCategory)
    setCategory(selCategory)
    setSubCategory(selSubCategory)
  }
  /**
   * Load Questions
   * go throgh the object to get current cat and subcat question to loop
   */
  const setCurrentQuestion = (number, selCategory, selSubCategory) => {
    const thisCategory =
      selCategory === 0 ? selCategory : selCategory || category
    const thisSubCategory =
      selSubCategory === 0 ? selSubCategory : selSubCategory || subCategory
    if (assessment && assessment.structure) {
      const filteredAssessment = filterAssessment()
      try {
        if (number === 0) {
          if (filteredAssessment.structure[thisCategory]) {
            setQuestionNumber(0)

            const thisQuestion =
              filteredAssessment.structure[thisCategory].subCategories[
                thisSubCategory
              ].questions[0]
            if (!thisQuestion) throw new Error('no question')
            setQuestion(thisQuestion)
            setCatTheme({
              color: filteredAssessment.structure[thisCategory].color,
              logo: filteredAssessment.structure[thisCategory].logo,
              image: filteredAssessment.structure[thisCategory].image,
            })
            setCurrAnswer(thisQuestion.answer || {})
          }
        } else if (number > 0) {
          //GOING FORWARD
          const thisSubCatQuestions =
            filteredAssessment.structure[thisCategory].subCategories[
              thisSubCategory
            ].questions
          if (number > thisSubCatQuestions.length - 1) {
            //GOING TO NEXT SUB CAT
            if (
              filteredAssessment.structure[thisCategory].subCategories[
                subCategory + 1
              ]
            ) {
              setSubCategory(subCategory + 1)
              setNewCat(
                filteredAssessment.structure[thisCategory].subCategories[
                  subCategory + 1
                ].name
              )
              setQuestionNumber(0)

              const thisQuestion =
                filteredAssessment.structure[thisCategory].subCategories[
                  subCategory + 1
                ].questions[0]
              if (thisQuestion) {
                setQuestion(thisQuestion)
                setCatTheme({
                  color: filteredAssessment.structure[thisCategory].color,
                  logo: filteredAssessment.structure[thisCategory].logo,
                  image: filteredAssessment.structure[thisCategory].image,
                })
                setCurrAnswer(thisQuestion.answer || {})
              } else {
                setEndFrame(true)
              }
            } else if (
              filteredAssessment.structure[thisCategory + 1].subCategories[0]
            ) {
              //MOVE TO NEXT MAIN CAT
              setCategory(category + 1)
              setNewCat(filteredAssessment.structure[thisCategory + 1].name)
              setSubCategory(0)
              setQuestionNumber(0)

              const thisQuestion =
                filteredAssessment.structure[thisCategory + 1].subCategories[0]
                  .questions[0]
              if (!thisQuestion) throw new Error('no question')
              setQuestion(thisQuestion)
              setCatTheme({
                color: filteredAssessment.structure[thisCategory + 1].color,
                logo: filteredAssessment.structure[thisCategory + 1].logo,
                image: filteredAssessment.structure[thisCategory + 1].image,
              })
              setCurrAnswer(thisQuestion.answer || {})
            } else {
              // no questions lets fast forward
              throw new Error('no question')
            }
          } else {
            //JUST MOVE CURSER INSIDE SUBCAT

            setQuestionNumber(number)

            const thisQuestion =
              filteredAssessment.structure[thisCategory].subCategories[
                thisSubCategory
              ].questions[number]
            if (!thisQuestion) throw new Error('no question')
            setQuestion(thisQuestion)
            setCatTheme({
              color: filteredAssessment.structure[thisCategory].color,
              logo: filteredAssessment.structure[thisCategory].logo,
              image: filteredAssessment.structure[thisCategory].image,
            })
            setCurrAnswer(thisQuestion.answer || {})
          }
        } else if (number < 0) {
          //GOING BACK
          //lets check if we have a prev cat
          if (subCategory >= 1) {
            //1MOVE inside main CAT
            const newSubCategory = subCategory - 1
            setSubCategory(newSubCategory)
            //set question to total number of questions
            const newNumber =
              filteredAssessment.structure[thisCategory].subCategories[
                newSubCategory
              ].questions.length - 1
            setQuestionNumber(newNumber)
            const thisQuestion =
              filteredAssessment.structure[thisCategory].subCategories[
                newSubCategory
              ].questions[newNumber]

            if (!thisQuestion) throw new Error('no question')
            setQuestion(thisQuestion)
            setCatTheme({
              color: filteredAssessment.structure[thisCategory].color,
              logo: filteredAssessment.structure[thisCategory].logo,
              image: filteredAssessment.structure[thisCategory].image,
            })
            setCurrAnswer(thisQuestion.answer || {})
          } else if (subCategory === 0) {
            //2MOVE to the prev CAT
            //we need to change main cat
            if (category >= 1) {
              const newCategory = category - 1
              const newSubCat =
                filteredAssessment.structure[newCategory].subCategories.length -
                1
              //lets set back cat and sub cat and question
              setCategory(newCategory)
              setSubCategory(newSubCat)
              const newNumber =
                filteredAssessment.structure[newCategory].subCategories[
                  newSubCat
                ].questions.length - 1
              setQuestionNumber(newNumber)

              const thisQuestion =
                filteredAssessment.structure[newCategory].subCategories[
                  newSubCat
                ].questions[newNumber]
              if (!thisQuestion) throw new Error('no question')

              setQuestion(thisQuestion)
              setCatTheme({
                color: filteredAssessment.structure[newCategory].color,
                logo: filteredAssessment.structure[newCategory].logo,
                image: filteredAssessment.structure[newCategory].image,
              })
              setCurrAnswer(thisQuestion.answer || {})
            } else if (category === 0) {
              // move the cursor to the last question of the last sub cat
              const newSubCat =
                filteredAssessment.structure[0].subCategories.length - 1
              setCategory(0)
              setSubCategory(newSubCat)
              const newNumber =
                filteredAssessment.structure[0].subCategories[newSubCat]
                  .questions.length - 1
              setQuestionNumber(newNumber)

              const thisQuestion =
                filteredAssessment.structure[0].subCategories[newSubCat]
                  .questions[newNumber]
              if (!thisQuestion) throw new Error('no question')
              setCatTheme({
                color: filteredAssessment.structure[0].color,
                logo: filteredAssessment.structure[0].logo,
                image: filteredAssessment.structure[0].image,
              })
              setQuestion(thisQuestion)
              setCurrAnswer(thisQuestion.answer || {})
            }
          }
        }
      } catch (error) {
        const structure = assessment.structure
        const catNumber = structure.length
        const lastCategory = structure[catNumber - 1]
        const lastSubCatNumber = lastCategory.subCategories.length
        const lastSubCatQuestions =
          lastCategory.subCategories[lastSubCatNumber - 1].totalQuestions

        if (
          (selCategory || category) >= catNumber - 1 &&
          (selSubCategory || subCategory) >= lastSubCatNumber - 1 &&
          number >= lastSubCatQuestions
        ) {
          setEndFrame(true)
        } else if (percentageQuestions > 99) {
          setEndFrame(true)
        } else {
          setCurrentQuestion(number + 1)
        }
      }
    }
  }

  /**
   * Use effects
   */
  const endAssessment = () => {
    setRemoteCall(
      'setAssessmentEnd',
      { ...assessment },
      'Avaliação finalizada com sucesso',
      (d) => {
        props.history.push('/clients')
      }
    )
  }
  /**
   * Use effects
   */
  useEffect(() => {
    //lets check if we payed
    if (clientId && sectorId) {
      setRemoteCall('getActiveAssessment', { client, sector }, null, (ass) => {
        if (!ass) return null
        if (ass.status === 'NOK') {
          setInactiveStatus(true)
        } else {
          resumeAssessment(ass)
          setAssessment(ass)
          getAssessmentResults(ass.structure)
        }

        if (!isBusy) {
          setBusy(true)
        }
      })
    }
  }, [])

  useEffect(() => {
    if (assessment && assessment.length > 0 && isBusy) {
      setBusy(false)
    } else if (!question) {
      //new map goes into loop
      // setCurrentQuestion(0)
    }
  }, [question, assessment, isBusy])

  let showPrev = true
  // if (category < 1) {
  //   if (questionNumber < 1) {
  //     showPrev = false
  //   }
  // }
  // useEffect(() => {
  //   setLoadedAnim(true)
  // }, [])
  /**
   * If no data yet
   */

  useEffect(() => {
    let currentProgress = 0
    let totalQuestions = 0
    if (assessment && assessment.structure) {
      assessment.structure.map((cat, catIndex) => {
        cat.subCategories.map((subCat, subCatIndex) => {
          subCat.questions.map((question, questionIndex) => {
            if (category > catIndex) {
              currentProgress++
            } else if (category >= catIndex && subCategory > subCatIndex) {
              currentProgress++
            } else if (
              category >= catIndex &&
              subCategory >= subCatIndex &&
              questionNumber > questionIndex
            ) {
              currentProgress++
            }

            totalQuestions++
          })
        })
      })
    }
    if (category > 0 || subCategory > 0 || questionNumber > 0) {
      setPercentageQuestions(((currentProgress + 1) * 100) / totalQuestions)
    }
  }, [questionNumberGlobal, category, subCategory, questionNumber, assessment])
  if (inactiveStatus) {
    return (
      <div className={'clients'}>
        <Header image={'/images/assessmentStart.jpg'} height={100}>
          <Flex alignContent={'space-between'}>
            <Box width={'85%'}>
              <Heading as={'h1'} fontSize={'4.5em'} color={'#fff'}>
                Avaliação inativa
              </Heading>
              <Heading as={'h3'} size="md" color={'#fff'}>
                Cliente
              </Heading>
            </Box>
            <Box width={'15%'}></Box>
          </Flex>
        </Header>
      </div>
    )
  }

  if (!assessment || !assessment.structure || assessment.structure.length < 1) {
    return (
      <div className={'clients'}>
        <Header image={'/images/assessmentStart.jpg'} height={100}>
          <Flex alignContent={'space-between'}>
            <Box width={'85%'}>
              <Heading as={'h1'} fontSize={'4.5em'} color={'#fff'}>
                A carregar as questões...
              </Heading>
              <Heading as={'h3'} size="md" color={'#fff'}>
                Cliente
              </Heading>
            </Box>
            <Box width={'15%'}></Box>
          </Flex>
        </Header>
      </div>
    )
  }

  // /**
  //  * If New Cat
  //  */
  // if (newCat) {
  //   return (
  //     <div className={'clients'}>
  //       <Header image={'/images/assessment.jpg'} height={100}>
  //         <Flex alignContent={'space-between'}>
  //           <Box width={'100%'}>
  //             <Heading as={'h3'} fontSize={'2em'} color={'#fff'}>
  //               New Category
  //             </Heading>
  //             <Heading as={'h1'} fontSize={'6em'} color={'#fff'}>
  //               {newCat}
  //             </Heading>
  //             <Heading as={'h3'} size="md" color={'#fff'}>
  //               <CompleteAssessmentOutline
  //                 color={'#fff'}
  //                 margin={'1em'}
  //                 onClick={() => {
  //                   setNewCat(false)
  //                   setCurrentQuestion(questionNumber - 1)
  //                 }}
  //               >
  //                 ‹ Go back
  //               </CompleteAssessmentOutline>
  //               <CompleteAssessment
  //                 color={'#000'}
  //                 onClick={() => {
  //                   setNewCat(false)
  //                 }}
  //               >
  //                 Continue Assessment ›
  //               </CompleteAssessment>
  //             </Heading>
  //           </Box>
  //         </Flex>
  //       </Header>
  //     </div>
  //   )
  // }
  /**
   * If last frame
   */
  if (endFrame) {
    return (
      <div className={'clients'}>
        <Header image={'/images/assessmentFinish.jpg'} height={100}>
          <Flex alignContent={'space-between'}>
            <Box width={'85%'}>
              <Heading as={'h1'} fontSize={'6em'} color={'#fff'}>
                Parabéns, esta foi a última questão.
              </Heading>
              <Heading as={'h3'} size="md" color={'#fff'}>
                <CompleteAssessmentOutline
                  color={'#fff'}
                  margin={'1em'}
                  onClick={() => {
                    setEndFrame(false)
                    setCurrentQuestion(questionNumber)
                  }}
                >
                  ‹ voltar
                </CompleteAssessmentOutline>
                <CompleteAssessment
                  onClick={() => {
                    endAssessment()
                  }}
                >
                  Concluir avaliação ›
                </CompleteAssessment>
              </Heading>
            </Box>
          </Flex>
        </Header>
      </div>
    )
  }

  //lets get the asssement details
  let assessmentDetails =
    sectors && assessment && sectors.find((s) => s._id === assessment.sector)

  /**
   * Lets render the question
   */
  return (
    <div className={'assessment'}>
      <Box
        width={{ base: '100%', md: 'calc(100% - 80px)' }}
        position={'absolute'}
        top={0}
        zIndex={10}
      >
        <Progress
          value={percentage || 0}
          isAnimated={true}
          height="32px"
          colorScheme={'orange'}
          hasStripe={true}
        />
        <Flex
          alignItems={'center'}
          position={'absolute'}
          w={'95%'}
          justifyContent={'space-between'}
          top={'5px'}
          left={'3%'}
        >
          <Box
            display={{ base: 'none', sm: 'block' }}
            color={percentage > 30 ? 'white' : 'black'}
            fontWeight={'bold'}
          >
            {assessment && assessment.name}
          </Box>
          <Box
            color={percentage > 80 ? 'white' : 'black'}
            fontSize={'.8em'}
            fontWeight={'bold'}
          >
            {percentage ? percentage.toFixed(2) : '0'}% Completo, Perguntas{' '}
            {assessment && assessment.totalQuestions}, Respostas {totalAnswers}
          </Box>
        </Flex>

        {/* <Progress
          value={percentageQuestions || 0}
          isAnimated={true}
          height="12px"
          colorScheme={'green'}
          hasStripe={true}
        /> */}
      </Box>
      <Header
        image={
          catTheme.image ||
          `/images/categories/catImage${categoryImage}.jpg` ||
          '/images/assessment.jpg'
        }
        color={catTheme.color || catColor[categoryImage - 1]}
        height={100}
      ></Header>

      <Map
        setOpenModal={setOpenModal}
        setAssessment={setAssessment}
        setCategoryImage={setCategoryImage}
        assessment={assessment}
        getAssessmentResults={getAssessmentResults}
        assessmentResult={assessmentResult}
        client={client}
        goToQuestion={goToQuestion}
        saveProgress={saveProgress}
        category={category}
        subCategory={subCategory}
        questionNumber={questionNumber}
        setCurrAnswer={setCurrAnswer}
        endAssessment={endAssessment}
        assessmentDetails={assessmentDetails}
      />
    </div>
  )
}

export default withRouter(Assessment)
